import { defineMessages } from 'react-intl';

import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';

import { ScanDataFields } from './const';

export const studyScanInfoMessages = defineMessages<ScanDataFields>({
  date: {
    id: 'studyInfo.scanInfo.date',
    defaultMessage: 'Date of study',
  },
  manufacturer: {
    id: 'studyInfo.scanInfo.manufacturer',
    defaultMessage: 'Manufacturer',
  },
  model: {
    id: 'studyInfo.scanInfo.model',
    defaultMessage: 'Model',
  },
  exposure: {
    id: 'studyInfo.scanInfo.exposure',
    defaultMessage: 'Exposure',
  },
  fov: {
    id: 'studyInfo.scanInfo.fov',
    defaultMessage: 'Field of view',
  },
  voxel: {
    id: 'studyInfo.scanInfo.voxel',
    defaultMessage: 'Voxel',
  },
});

export const studyTypes = defineMessages<StudyType>({
  [StudyType.StudyType_IntraoralXRay]: {
    id: 'studyType.intraoralXRay',
    defaultMessage: 'IOXRay',
  },
  [StudyType.StudyType_CBCT]: {
    id: 'studyType.CBCT',
    defaultMessage: 'CBCT',
  },
  [StudyType.StudyType_DentalPhoto]: {
    id: 'studyType.dentalPhoto',
    defaultMessage: 'Dental photo',
  },
  [StudyType.StudyType_PanoramicXRay]: {
    id: 'studyType.panoramicXRay',
    defaultMessage: 'Pano',
  },
  [StudyType.StudyType_IOS_Meshes]: {
    id: 'studyType.IOSMeshes',
    defaultMessage: 'IOS STL',
  },
  [StudyType.UNRECOGNIZED]: {
    id: 'studyType.unrecognized',
    defaultMessage: 'Unrecognized',
  },
  [StudyType.StudyType_InvalidValue]: {
    id: 'studyType.invalid',
    defaultMessage: 'Invalid study type',
  },
});
