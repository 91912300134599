import axios from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { replace } from 'ramda';
import { generatePath } from 'react-router';

import {
  BASE_URI,
  FORWARD_SLASH_REGEX,
  PATHS,
  WHITE_SPACE_REGEX,
} from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { i18n, reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';

// TODO: [4|m] Think how to move reportID to the downloadPDF arguments to avoid arguments for the hook.
export const useDownloadReportPDF = (
  reportID: string,
): {
  downloadReportPDF: () => Promise<void>;
  downloading: boolean;
} => {
  const [downloading, setDownloading] = useState<boolean>(false);

  const { formatMessage, formatDate } = useIntl();
  const currentReport = useAppSelector(
    reportsModel.selectors.selectByID(reportID),
  );
  const currentPatient = useAppSelector(
    patientModel.selectors.selectPatientByID(
      currentReport?.PatientID as string,
    ),
  );

  const patientFullName = `${currentPatient?.PersonalData?.LastName}_${currentPatient?.PersonalData?.FirstName}`;

  const reportType = replace(
    WHITE_SPACE_REGEX,
    '_',
    formatMessage(i18n[currentReport?.Type ?? ReportType.ReportType_CBCT_GP]),
  );

  const reportCreationDate = replace(
    FORWARD_SLASH_REGEX,
    '',
    formatDate(currentReport?.Created?.At ?? 0, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }),
  );

  const pathname = generatePath(PATHS.previewReport, {
    patientID: currentPatient?.ID,
    reportID,
  });

  const fileName = `${patientFullName}_${reportType}_${reportCreationDate}.pdf`;

  const downloadReportPDF = async () => {
    setDownloading(true);

    try {
      const encode = encodeURIComponent(BASE_URI + pathname);

      const url = `${BASE_URI}/typewriter?url=${encode}`;

      const { data } = await axios.get(url, {
        withCredentials: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      });

      const blob = new Blob([data], { type: 'application/pdf' });

      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } finally {
      setDownloading(false);
    }
  };

  return {
    downloadReportPDF,
    downloading,
  };
};
