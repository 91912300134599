import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useIntl } from 'react-intl/lib';

import { Icon, Switch, Tooltip } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { useCheckReportSignature, useReportSettings } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';

import styles from './Report2DSettingsBar.module.scss';

type Report2DSettingsBarProps = {
  className?: string;
};

export const Report2DSettingsBar = (props: Report2DSettingsBarProps) => {
  const { className } = props;

  const { formatMessage } = useIntl();

  const { isLoading, settings, updateSettings } = useReportSettings();

  const hideProbability = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
  );

  const { checkReportSignature } = useCheckReportSignature();

  const lowProbabilityModeToggle = () => {
    checkReportSignature({
      onSignatureChecked: async () =>
        updateSettings({ LowProbabilityMode: !settings.LowProbabilityMode }),
    });
  };

  const toggleConditionDetails = () => {
    checkReportSignature({
      onSignatureChecked: async () =>
        updateSettings({
          ShowConditionDetails: !settings.ShowConditionDetails,
        }),
    });
  };

  return (
    <div className={cn(styles.container, className)}>
      {!hideProbability && (
        <div className={styles.lowProbability}>
          <Switch
            labelClassName="p3"
            checked={settings.LowProbabilityMode}
            disabled={isLoading}
            onChange={lowProbabilityModeToggle}
          >
            <FormattedMessage
              id="reportSettings.showLowProbability"
              defaultMessage="Suspicious teeth"
            />
          </Switch>

          <Tooltip.Primary
            content={formatMessage({
              id: 'lowProbabilityInfo',
              defaultMessage:
                'Switch on to see caries signs and periradicular pathologies, with probability rate 30-50%',
            })}
          >
            <div className={styles.lowProbabilityInfoIcon}>
              <Icon name="info" size={24} />
            </div>
          </Tooltip.Primary>
        </div>
      )}

      <div className={styles.diagnosisDetails}>
        <Switch
          className={styles.toggle}
          labelClassName="p3"
          checked={settings.ShowConditionDetails}
          disabled={isLoading}
          onChange={toggleConditionDetails}
        >
          <FormattedMessage
            id="reportSettings.showConditionDetails"
            defaultMessage="Conditions details"
          />
        </Switch>

        <Tooltip.Primary
          content={formatMessage({
            id: 'showConditionDetailsInfo',
            defaultMessage:
              'Turn on to view all detected conditions with details',
          })}
        >
          <div className={styles.lowProbabilityInfoIcon}>
            <Icon name="info" size={24} />
          </div>
        </Tooltip.Primary>
      </div>
    </div>
  );
};
