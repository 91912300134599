import { createSelector } from '@reduxjs/toolkit';
import { equals } from 'ramda';

import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import {
  Report,
  ReportStatus,
  ReportType,
} from '@/shared/api/protocol_gen/model/dto_report';

import { reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';

import { ReportMetaInfo, StudyMetaInfo } from '../config/types';

const isActiveReport = (report: Report) => !report.Deleted?.Deleted;
const isRelevantReport =
  (reportType: ReportType, sourceStudyIDs: string[]) => (report: Report) =>
    isActiveReport(report) &&
    report.Type === reportType &&
    equals(report.SourceStudyIDs, sourceStudyIDs);

export const selectReportInfo = (reportID: string) =>
  createSelector(
    reportsModel.selectors.selectAll,
    studyModel.selectors.selectEntities,
    (reports, studyEntities) => {
      const currentReport = reports.find((report) => report.ID === reportID);
      const studyIDs = currentReport?.SourceStudyIDs || [];
      const relevantReports = reports.filter(
        isRelevantReport(currentReport?.Type as ReportType, studyIDs),
      );
      const studies: Study[] = [];

      studyIDs.forEach((studyID) => {
        if (studyID in studyEntities) {
          studies.push(studyEntities[studyID] as Study);
        }
      });

      const studiesMetaInfo = studies.map(
        (study): StudyMetaInfo => ({
          id: study.ID,
          studyType: study.Type,
          date: study.Created?.At as Date,
        }),
      );

      const reportsInfo = relevantReports.map(
        (report): ReportMetaInfo => ({
          id: report.ID,
          reportType: report.Type,
          patientID: report.PatientID,
          date: report.Created?.At as Date,
          status: report.Status as ReportStatus,
        }),
      );

      return {
        currentReport,
        reportsInfo,
        studiesMetaInfo,
      };
    },
  );
