import cn from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

import { Modal, Button } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';

import { ModalID, modalModel } from '@/entities/modal';

import { useNewReport } from '@/features/addNewReport';

import { selectors } from '../../model';
import { StudyInfo } from '../StudyInfo/StudyInfo';
import { ReportsVersions } from '../ReportsVersions/ReportVersions';
import { ReportInfoRow } from '../ReportInfoRow/ReportInfoRow';
import { findStudyMetaInfoByType } from '../../lib';

import styles from './ReportInfoModal.module.scss';

export const ReportInfoModal = () => {
  const dispatch = useAppDispatch();
  const {
    visible,
    data: { reportID },
  } = useAppSelector(modalModel.selectors.selectReportInfoModal);
  const { currentReport, studiesMetaInfo, reportsInfo } = useAppSelector(
    selectors.selectReportInfo(reportID),
  );
  const { formatMessage } = useIntl();
  const {
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
  } = useNewReport();

  // TODO: [8|m] Make reusable hook for reordering reports
  const reorderReport = () => {
    const CBCTStudyID = studiesMetaInfo.find(
      findStudyMetaInfoByType(StudyType.StudyType_CBCT),
    )?.id;

    switch (currentReport?.Type) {
      case ReportType.ReportType_CBCT_GP: {
        if (CBCTStudyID) {
          requestCBCTGPReport(CBCTStudyID);
        }
        break;
      }

      case ReportType.ReportType_Pano_GP: {
        const studyId = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_PanoramicXRay),
        )?.id;

        if (studyId) {
          requestPanoGPReport(studyId);
        }
        break;
      }
      case ReportType.ReportType_IOXRay_GP: {
        const studyId = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_IntraoralXRay),
        )?.id;

        if (studyId) {
          requestIOXRayGPReport(studyId);
        }
        break;
      }
      case ReportType.ReportType_IOS_Segmentation: {
        if (CBCTStudyID) {
          requestCBCTSegmentationReport(CBCTStudyID);
        }
        break;
      }
      case ReportType.ReportType_StudioOrtho: {
        const IOSMeshesStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_IOS_Meshes),
        )?.id;
        const DentalPhotoStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_DentalPhoto),
        )?.id;
        if (CBCTStudyID) {
          requestStudioOrthoReport({
            CBCTStudyID,
            IOSMeshesStudyID: IOSMeshesStudyID as string,
            DentalPhotoStudyID: DentalPhotoStudyID as string,
          });
        }
        break;
      }
      case ReportType.ReportType_StudioImplant: {
        const IOSMeshesStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_IOS_Meshes),
        )?.id;
        const DentalPhotoStudyID = studiesMetaInfo.find(
          findStudyMetaInfoByType(StudyType.StudyType_DentalPhoto),
        )?.id;
        if (CBCTStudyID) {
          requestStudioImplantReport({
            CBCTStudyID,
            IOSMeshesStudyID: IOSMeshesStudyID as string,
            DentalPhotoStudyID: DentalPhotoStudyID as string,
          });
        }
        break;
      }
    }
  };

  const closeModal = () => {
    dispatch(modalModel.actions.closeModal(ModalID.ReportInfo));
  };

  return (
    <Modal
      headerClassName={styles.header}
      bodyClassName={styles.body}
      isOpen={visible}
      onCancel={closeModal}
      title={formatMessage({
        id: 'reportInformationModal.title',
        defaultMessage: 'Report Information',
      })}
      hideFooter={currentReport?.Type === ReportType.ReportType_Pano_Bitewings}
      footer={
        <footer className={styles.footer}>
          <Button size="tiny" variant="secondary" onClick={reorderReport}>
            <FormattedMessage id="global.reorder" defaultMessage="Reorder" />
          </Button>
        </footer>
      }
    >
      <main className={cn(styles.content)}>
        {studiesMetaInfo.map((studyMetaInfo) => (
          <StudyInfo {...studyMetaInfo} />
        ))}
        <ReportsVersions>
          {reportsInfo.map((reportInfo) => (
            <ReportInfoRow {...reportInfo} />
          ))}
        </ReportsVersions>
      </main>
    </Modal>
  );
};
