import { FC, ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';

import { useAppSelector, useWindowSize } from '@/shared/hooks';
import {
  MedicalImageRender,
  MIRenderImageInterface,
} from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { reportsModel } from '@/entities/reports';
import { assetsModel } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';

import { maskFiltersModel } from '@/features/renderMasks';

import {
  CBCT_FOOTER_HEIGHT,
  CBCT_HEADER_HEIGHT,
  CBCT_PANO_MAX_HEIGHT,
  CBCT_PANO_MIN_HEIGHT,
  CBCT_PANO_PREVIEW_HEIGHT,
  CBCT_TOOTH_CHART_HEIGHT,
} from '../../config/constants';

import styles from './PanoReformatRender.module.scss';

type PanoReformatRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanoReformatRender: FC<PanoReformatRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  const { reportID } = useParams<{ reportID: string }>();

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const isSplit = currentReport?.Settings?.ShowPanoramaSplitInsteadOfSingle;

  const readyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );

  const reformatAsset = useAppSelector(
    assetsModel.selectors.selectGPAssetByReportID(reportID as string),
  );

  const splitAsset = useAppSelector(
    assetsModel.selectors.selectCBCTPanoReformatSplitAsset(
      currentReport?.ID as string,
    ),
  );

  const reformatPanoMIRenderImageInterface: MIRenderImageInterface = {
    ImageID: reformatAsset?.ID as string,
    OrientationAngle: 0,
    originalSize: {
      width: reformatAsset?.GeneratedReport?.CBCTGPPanoramaReformatGeneral
        ?.PanoramaSingle?.Image?.Width as number,
      height: reformatAsset?.GeneratedReport?.CBCTGPPanoramaReformatGeneral
        ?.PanoramaSingle?.Image?.Height as number,
    },
    MedicalImageFeatures: currentReport?.MedicalImageFeatures,
  };

  const splitPanoMIRenderImageInterface: MIRenderImageInterface = {
    ImageID: splitAsset?.ID as string,
    OrientationAngle: 0,
    originalSize: {
      width: splitAsset?.GeneratedReport?.CBCTGPPanoramaReformatSplit
        ?.PanoramaSplit?.Image?.Width as number,
      height: splitAsset?.GeneratedReport?.CBCTGPPanoramaReformatSplit
        ?.PanoramaSplit?.Image?.Height as number,
    },
    MedicalImageFeatures: currentReport?.MedicalImageFeatures,
  };

  // Masks
  const masksRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );
  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );
  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  useLayoutEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef);
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  useEffect(() => {
    const shouldRunRender = !!reportID && currentReport?.DisplayAssetID;
    if (shouldRunRender) {
      MedicalImageRender.setCredentials(CORS_POLICY);

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID,
        [
          [[reformatPanoMIRenderImageInterface]],
          [[splitPanoMIRenderImageInterface]],
        ],
        currentReport?.MedicalImageFeatures?.ViewOptions,
        {
          [reformatPanoMIRenderImageInterface.ImageID]: {
            viewportType: 'pano',
          },
        },
      );

      if (!isRenderStartCorrect) {
        return;
      }

      MedicalImageRender.layoutModes.focus(
        isSplit
          ? splitPanoMIRenderImageInterface?.ImageID
          : reformatPanoMIRenderImageInterface.ImageID,
      );
    }
  }, [
    reportID,
    reformatPanoMIRenderImageInterface,
    splitPanoMIRenderImageInterface,
    readyForRender,
    isSplit,
  ]);

  useEffect(() => {
    if (isSplit) {
      MedicalImageRender.layoutModes.focus(
        splitPanoMIRenderImageInterface.ImageID,
      );
    } else {
      MedicalImageRender.layoutModes.focus(
        reformatPanoMIRenderImageInterface.ImageID,
      );
    }
  }, [isSplit]);

  // Preview settings
  useEffect(() => {
    if (
      readyForRender &&
      MedicalImageRender.isRunning() &&
      previewSettings?.isPreview
    ) {
      MedicalImageRender.activateMode('printMode');

      if (previewSettings?.isInverted) {
        MedicalImageRender.invertColors();
      } else if (!previewSettings?.isInverted) {
        MedicalImageRender.straightColors();
      }
    }
  }, [readyForRender, previewSettings?.isPreview, previewSettings?.isInverted]);

  // Render masks
  useEffect(() => {
    if (MedicalImageRender.isRunning() && readyForRender) {
      MedicalImageRender.deleteMasks();
      if (!hideMasks && masksRenderData.length > 0) {
        MedicalImageRender.addMasks(masksRenderData);
        MedicalImageRender.showMasks(activeMaskFilters);
      }
    }
  }, [masksRenderData, readyForRender, hideMasks, activeMaskFilters]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  const { height: windowHeight } = useWindowSize();

  const dynamicContainerHeight =
    windowHeight -
    CBCT_TOOTH_CHART_HEIGHT -
    CBCT_FOOTER_HEIGHT -
    CBCT_HEADER_HEIGHT -
    80; // manual value

  const scaledContainerHeight =
    dynamicContainerHeight > CBCT_PANO_MIN_HEIGHT
      ? dynamicContainerHeight < CBCT_PANO_MAX_HEIGHT
        ? dynamicContainerHeight
        : CBCT_PANO_MAX_HEIGHT
      : CBCT_PANO_MIN_HEIGHT;

  const containerHeight = previewSettings?.isPreview
    ? CBCT_PANO_PREVIEW_HEIGHT
    : scaledContainerHeight;

  return (
    <div
      ref={mainViewRef}
      id="report_render"
      style={{
        height: containerHeight,
        display: 'flex',
      }}
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
    >
      {children}
      <canvas
        ref={canvasRef}
        className={styles.canvas}
        style={{ position: 'absolute', borderRadius: '16px' }}
      />
    </div>
  );
};
