import { defineMessages } from 'react-intl';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

export const reportCardTitle = defineMessages<ReportType>({
  [ReportType.ReportType_InvalidValue]: {
    id: 'reportCard.ReportType.ReportType_InvalidValue',
    defaultMessage: 'ReportType_InvalidValue',
  },
  // Radiological
  [ReportType.ReportType_CBCT_GP]: {
    id: 'reportCard.ReportType.ReportType_CBCT_GP',
    defaultMessage: 'CBCT AI',
  },
  [ReportType.ReportType_CBCT_Segmentation]: {
    id: 'reportCard.ReportType.ReportType_CBCT_Segmentation',
    defaultMessage: '3D Model',
  },
  [ReportType.ReportType_CBCT_OrthoSlides]: {
    id: 'reportCard.ReportType.ReportType_CBCT_OrthoSlides',
    defaultMessage: 'Orthodontic Reports',
  },
  [ReportType.ReportType_CBCT_Implant]: {
    id: 'reportCard.ReportType.ReportType_CBCT_Implant',
    defaultMessage: 'Implantology Study',
  },
  [ReportType.ReportType_CBCT_Endo]: {
    id: 'reportCard.ReportType.ReportType_CBCT_Endo',
    defaultMessage: 'Endodontic Study',
  },
  [ReportType.ReportType_CBCT_Molar]: {
    id: 'reportCard.ReportType.ReportType_CBCT_Molar',
    defaultMessage: 'Third Molar Study',
  },
  [ReportType.ReportType_Pano_GP]: {
    id: 'reportCard.ReportType.ReportType_Pano_GP',
    defaultMessage: 'Pano AI',
  },
  // FMX
  [ReportType.ReportType_IOXRay_GP]: {
    id: 'reportCard.ReportType.ReportType_IOXRay_GP',
    defaultMessage: 'IOXRay AI',
  },
  [ReportType.ReportType_DentalPhoto_GP]: {
    id: 'reportCard.ReportType.ReportType_DentalPhoto_GP',
    defaultMessage: 'ReportType_DentalPhoto_GP',
  },
  [ReportType.ReportType_IOS_Segmentation]: {
    id: 'reportCard.ReportType.ReportType_IOS_Segmentation',
    defaultMessage: 'ReportType_IOS_Segmentation',
  },
  [ReportType.ReportType_CBCT_IOS_Superimposition]: {
    id: 'reportCard.ReportType.ReportType_CBCT_IOS_Superimposition',
    defaultMessage: 'ReportType_CBCT_IOS_Superimposition',
  },
  [ReportType.ReportType_StudioImplant]: {
    id: 'reportCard.ReportType.ReportType_ImplantStudio',
    defaultMessage: 'Implant',
  },
  [ReportType.ReportType_Photo_Ortho]: {
    id: 'reportCard.ReportType.ReportType_Photo_Ortho',
    defaultMessage: 'ReportType_Photo_Ortho',
  },
  [ReportType.UNRECOGNIZED]: {
    id: 'reportCard.ReportType.UNRECOGNIZED',
    defaultMessage: 'UNRECOGNIZED',
  },
  [ReportType.ReportType_StudioOrtho]: {
    id: 'reportCard.ReportType.ReportType_OrthoStudio',
    defaultMessage: 'Ortho',
  },
  [ReportType.ReportType_Pano_Bitewings]: {
    id: 'reportCard.ReportType.ReportType_Pano_Bitewings',
    defaultMessage: 'Panowings AI',
  },
});
