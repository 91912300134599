import { Group, Vector3, PerspectiveCamera, OrthographicCamera, NoToneMapping, LinearEncoding, Vector4, Scene, WebGLRenderer, Mesh, Object3D, ShaderMaterial, Raycaster, Vector2, DataTexture, RedFormat, FloatType, LinearFilter, Texture, MathUtils, Material, RawShaderMaterial, PlaneGeometry, Matrix3, RepeatWrapping, Color, BufferAttribute, Line, Line3, Matrix4, Quaternion, ShapeUtils, Float32BufferAttribute, Box3, MeshBasicMaterial } from 'three';
import dicomParser from 'dicom-parser';
import { Line2 as Line2$1 } from 'three/examples/jsm/lines/Line2';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { Text } from 'troika-three-text';
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import * as process from 'process';

class ToolsController {
  constructor(hostObject) {
    this.hostObject = hostObject;
    this.tools = /* @__PURE__ */ new Map();
    this.enable = false;
  }
  init() {
    if (!this.enable) {
      this.enable = true;
      this.initServiceTools();
      this.activeMode?.init();
    }
  }
  dispose() {
    if (this.enable) {
      this.disposeServiceTools();
      this.tools?.forEach((tool) => tool.dispose());
      this.activeMode?.dispose();
      this.activeMode = void 0;
      this.enable = false;
    }
  }
  clear() {
    this.dispose();
    this.serviceTools = void 0;
    this.modes = void 0;
    this.tools.clear();
  }
  setServiceTools(toolTypes) {
    this.serviceTools = /* @__PURE__ */ new Set();
    toolTypes.forEach((toolType) => this.serviceTools.add(new toolType(this.hostObject)));
  }
  initServiceTools() {
    this.serviceTools?.forEach((tool) => tool.init());
  }
  disposeServiceTools() {
    this.serviceTools?.forEach((tool) => tool.dispose());
  }
  setTools(tools) {
    let toolInstance;
    tools.forEach((tool) => {
      toolInstance = new tool(this.hostObject);
      if (!this.tools.has(tool)) {
        this.tools.set(tool, toolInstance);
      }
    });
  }
  getTools() {
    return this.tools;
  }
  getTool(name) {
    return this.tools?.get(name);
  }
  getToolByName(name) {
    for (const [key, value] of this.tools) {
      if (key.name === name) return value;
    }
  }
  setModes(modes) {
    if (!this.modes) {
      this.modes = modes;
      Object.keys(modes).forEach(
        (modeName) => modes[modeName].setHostObject(this.hostObject, modeName)
      );
    }
  }
  activateMode(name) {
    if (this.modes && this.activeMode !== this.modes[name]) {
      this.enable && this.modes[name].init();
      this.activeMode = this.modes[name];
    }
  }
  getModeName() {
    return this.activeMode?.getName();
  }
  getModes() {
    return this.modes;
  }
  getMode() {
    return this.activeMode;
  }
}

class Container {
  constructor(hostObject, objects) {
    this.hostObject = hostObject;
    this.objects = objects;
  }
}

class LayerContainer extends Container {
  constructor(layer, objects) {
    super(layer, objects);
    this.objects = objects;
    this.components = /* @__PURE__ */ new Map();
    this.deleteOne = this.deleteOne.bind(this);
    this.addOne = this.addOne.bind(this);
  }
  size() {
    return this.objects.length;
  }
  add(entity) {
    if (Array.isArray(entity)) {
      const added = entity.filter(this.addOne);
      if (added.length) {
        App.Instance().dispatchEvent({ type: "add", entities: [...entity], container: this.hostObject });
      }
    } else {
      if (this.addOne(entity)) {
        App.Instance().dispatchEvent({ type: "add", entities: [entity], container: this.hostObject });
      }
    }
  }
  delete(entity) {
    if (Array.isArray(entity)) {
      const deleted = entity.filter(this.deleteOne);
      if (deleted.length) {
        this.hostObject.getGroup().remove(...entity.map((item) => item.object3d));
        App.Instance().dispatchEvent({ type: "delete", entities: deleted, container: this.hostObject });
      }
    } else {
      if (this.objects.includes(entity)) {
        if (this.deleteOne(entity)) {
          this.hostObject.getGroup().remove(entity.object3d);
          App.Instance().dispatchEvent({ type: "delete", entities: [entity], container: this.hostObject });
        }
      }
    }
  }
  addOne(entity) {
    if (entity.getLayer() !== this.hostObject) {
      this.objects.push(entity);
      entity.setLayer(this.hostObject);
      this.hostObject.getGroup().add(entity.object3d);
      entity.components.forEach((value, key) => {
        const set = this.components.get(key);
        if (set) {
          set.add(value);
        } else {
          this.components.set(key, /* @__PURE__ */ new Set([value]));
        }
      });
      return true;
    }
    return false;
  }
  deleteOne(entity) {
    const index = this.objects.indexOf(entity);
    if (index >= 0) {
      this.objects.splice(index, 1);
      entity.setLayer(void 0);
      entity.components.forEach((value, key) => {
        const set = this.components.get(key);
        if (set) {
          set.delete(value);
        }
      });
      return true;
    }
    return false;
  }
}

class Layer {
  constructor(view, name, objects = []) {
    this.getObjectByName = (name, force = false) => {
      if (!(force || this.on && !this.lock)) return;
      return this.container.objects.find((entity) => entity.object3d.name === name);
    };
    this.objects = objects;
    this.on = true;
    this.lock = false;
    this.view = view;
    this.name = name;
    this.group = new Group();
    this.group.name = name;
    this.container = new LayerContainer(this, objects);
  }
  getGroup() {
    return this.group;
  }
  getObjects(force = false) {
    if (force || this.on && !this.lock) {
      return this.objects;
    }
    return [];
  }
  getComponent(type) {
    return this.container.components.get(type);
  }
  has(obj) {
    return this.objects.includes(obj);
  }
  add(object) {
    this.container.add(object);
  }
  delete(object) {
    this.container.delete(object);
  }
  clear() {
    this.objects.forEach((obj) => {
      obj.setLayer(void 0);
    });
    this.group.remove(...this.objects.map((item) => item.object3d));
    const tmpObjects = [...this.objects];
    this.objects.length = 0;
    this.container.components.clear();
    App.Instance().dispatchEvent({ type: "delete", entities: tmpObjects, container: this });
  }
  hide() {
    this.group.visible = false;
  }
  show() {
    this.group.visible = true;
  }
  getName() {
    return this.name;
  }
  getView() {
    return this.view;
  }
}

class CameraHelper {
  constructor(cameraController) {
    this.cameraController = cameraController;
  }
  init() {
    this.handleResize();
    this.cameraController.viewport.addEventListener("resize", this.handleResize, 10);
  }
  dispose() {
    App.Instance().removeEventListener("resize", this.handleResize);
  }
  getDistanceFactor() {
    return this.distanceFactor;
  }
  get viewport() {
    return this.cameraController.viewport;
  }
}

class OrthographicCameraHelper extends CameraHelper {
  constructor(cameraController) {
    super(cameraController);
    this.handleResize = () => {
      if (this.viewport.width === 0 || this.viewport.height === 0) {
        return;
      }
      this.sizeRatio = this.frustumSize / this.viewport.height;
      this.aspect = this.viewport.width / this.viewport.height;
      this.camera.left = -0.5 * this.frustumSize * this.aspect;
      this.camera.right = 0.5 * this.frustumSize * this.aspect;
      this.camera.top = this.frustumSize / 2;
      this.camera.bottom = -this.frustumSize / 2;
      this.camera.updateProjectionMatrix();
    };
    this.frustumSize = 600;
    this.aspect = 1;
    this.sizeRatio = 1;
    this.updateDistanceFactor();
  }
  get camera() {
    return this.cameraController.getOrthographicCamera();
  }
  getSizeRatio() {
    return this.sizeRatio;
  }
  updateDistanceFactor() {
    return this.distanceFactor = 1;
  }
}

class PerspectiveCameraHelper extends CameraHelper {
  constructor(cameraController) {
    super(cameraController);
    this.offset = new Vector3();
    this.handleResize = () => {
      this.camera.aspect = this.viewport.width / this.viewport.height;
      this.camera.updateProjectionMatrix();
      this.updateSubDistCoefficient();
      this.updateDistanceFactor();
    };
    this.updateDistanceFactor();
  }
  get camera() {
    return this.cameraController.getPerspectiveCamera();
  }
  updateSubDistCoefficient() {
    this.subCoefficient = 2 * Math.tan(this.camera.fov / 2 * Math.PI / 180) / this.viewport.height;
  }
  updateDistanceFactor() {
    return this.distanceFactor = this.offset.copy(this.cameraController.getPivotPoint()).sub(this.camera.position).length() * this.subCoefficient;
  }
}

const initialProps = {
  perspective: {},
  orthographic: { left: -1, right: 1, top: 1, bottom: -1, near: -1e3, far: 1e3 },
  zoom: 1,
  matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
  type: "camera-controller",
  key: "",
  up: [0, 1, 0],
  pivotPoint: [0, 0, 0]
};
class CameraController {
  constructor(viewport, props = initialProps) {
    this.viewport = viewport;
    var { fov, aspect, near, far } = props.perspective || {};
    this.perspectiveCamera = new PerspectiveCamera(fov, aspect, near, far);
    var { left, right, top, bottom, near, far } = props.orthographic || initialProps.orthographic;
    this.orthographicCamera = new OrthographicCamera(left, right, top, bottom, near, far);
    const matrix = props.matrix ? props.matrix : initialProps.matrix;
    this.perspectiveCamera.matrix.set(...matrix);
    this.perspectiveCamera.matrixWorldNeedsUpdate = true;
    this.orthographicCamera.matrix.set(...matrix);
    this.orthographicCamera.matrixWorldNeedsUpdate = true;
    if (props.up) {
      this.perspectiveCamera.up.set(...props.up).normalize();
      this.orthographicCamera.up.set(...props.up).normalize();
    }
    this.pivotPoint = new Vector3();
    this.cameraX = new Vector3();
    this.cameraY = new Vector3();
    this.cameraZ = new Vector3();
    this.perspectiveCameraHelper = new PerspectiveCameraHelper(this);
    this.orthographicCameraHelper = new OrthographicCameraHelper(this);
    this.orthographic();
    this.onTransform = this.onTransform.bind(this);
  }
  init() {
    this.cameraHelper.init();
    App.Instance().addEventListener("transformCamera", this.onTransform, 10);
  }
  onTransform(event) {
    if (event.viewport === this.viewport) {
      this.camera.updateMatrixWorld();
    }
  }
  updatePivotPoint(point, fireEvent = true) {
    this.pivotPoint.copy(point);
    this.viewport.dispatchEvent({ type: "updatePivotPoint", point: this.pivotPoint, fireEvent });
  }
  getPivotPoint() {
    return this.pivotPoint;
  }
  dispose() {
    this.perspectiveCameraHelper.dispose();
    this.orthographicCameraHelper.dispose();
  }
  saveState() {
    this.savedState = this.toJson();
  }
  setPosition(v, y, z) {
    this.camera.matrix.setPosition(v, y, z);
  }
  updateMatrix(matrix) {
    this.camera.matrix.copy(matrix);
    this.camera.matrixWorldNeedsUpdate = true;
  }
  orthographic() {
    this.camera = this.orthographicCamera;
    this.cameraHelper = this.orthographicCameraHelper;
    this.perspectiveCameraHelper.dispose();
    this.orthographicCameraHelper.init();
    this.getDistanceFactor = () => this.orthographicCameraHelper.updateDistanceFactor();
    this.viewport.dispatchEvent({
      type: "cameraChanged",
      previous: this.perspectiveCamera,
      current: this.orthographicCamera
    });
  }
  perspective() {
    this.camera = this.perspectiveCamera;
    this.cameraHelper = this.perspectiveCameraHelper;
    this.orthographicCameraHelper.dispose();
    this.perspectiveCameraHelper.init();
    this.getDistanceFactor = () => this.perspectiveCameraHelper.updateDistanceFactor();
    this.viewport.dispatchEvent({
      type: "cameraChanged",
      previous: this.orthographicCamera,
      current: this.perspectiveCamera
    });
  }
  copyFromActive() {
    const camera = this.camera === this.orthographicCamera ? this.perspectiveCamera : this.orthographicCamera;
    camera.matrix.copy(this.camera.matrix);
    camera.matrix.decompose(camera.position, camera.quaternion, camera.scale);
    camera.layers = this.camera.layers;
  }
  getPerspectiveCamera() {
    return this.perspectiveCamera;
  }
  getOrthographicCamera() {
    return this.orthographicCamera;
  }
  get xDir() {
    return this.cameraX.setFromMatrixColumn(this.camera.matrix, 0);
  }
  get yDir() {
    return this.cameraY.setFromMatrixColumn(this.camera.matrix, 1);
  }
  get zDir() {
    return this.cameraZ.setFromMatrixColumn(this.camera.matrix, 2);
  }
  fromJson(props) {
    this.perspectiveCamera.matrix.fromArray(props.matrix);
    this.perspectiveCamera.matrix.decompose(this.perspectiveCamera.position, this.perspectiveCamera.quaternion, this.perspectiveCamera.scale);
    this.perspectiveCamera.matrixWorldNeedsUpdate = true;
    props.perspective.fov ? this.perspectiveCamera.fov = props.perspective.fov : void 0;
    props.perspective.aspect ? this.perspectiveCamera.aspect = props.perspective.aspect : void 0;
    props.perspective.near ? this.perspectiveCamera.near = props.perspective.near : void 0;
    props.perspective.far ? this.perspectiveCamera.far = props.perspective.far : void 0;
    this.perspectiveCamera.zoom = props.zoom;
    props.up ? this.perspectiveCamera.up.fromArray(props.up) : void 0;
    this.perspectiveCameraHelper.handleResize();
    this.orthographicCamera.matrix.fromArray(props.matrix);
    this.orthographicCamera.matrix.decompose(this.orthographicCamera.position, this.orthographicCamera.quaternion, this.orthographicCamera.scale);
    this.orthographicCamera.matrixWorldNeedsUpdate = true;
    this.orthographicCamera.left = props.orthographic.left;
    this.orthographicCamera.right = props.orthographic.right;
    this.orthographicCamera.top = props.orthographic.top;
    this.orthographicCamera.bottom = props.orthographic.bottom;
    props.orthographic.near ? this.orthographicCamera.near = props.orthographic.near : void 0;
    props.orthographic.far ? this.orthographicCamera.far = props.orthographic.far : void 0;
    this.orthographicCamera.zoom = props.zoom;
    props.up ? this.orthographicCamera.up.fromArray(props.up) : void 0;
    this.orthographicCameraHelper.handleResize();
    const pivotPoint = new Vector3().fromArray(props.pivotPoint);
    this.updatePivotPoint(pivotPoint);
    this.viewport.dispatchEvent({ type: "cameraChanged", previous: this.camera, current: this.camera });
  }
  toJson() {
    return {
      perspective: {
        fov: this.perspectiveCamera.fov,
        aspect: this.perspectiveCamera.aspect,
        near: this.perspectiveCamera.near,
        far: this.perspectiveCamera.far
      },
      orthographic: {
        left: this.orthographicCamera.left,
        right: this.orthographicCamera.right,
        top: this.orthographicCamera.top,
        bottom: this.orthographicCamera.bottom,
        near: this.orthographicCamera.near,
        far: this.orthographicCamera.far
      },
      zoom: this.camera.zoom,
      matrix: this.camera.matrix.toArray(),
      type: "camera-controller",
      key: this.camera.uuid ?? "",
      up: this.camera.up.toArray(),
      pivotPoint: this.getPivotPoint().toArray()
    };
  }
  fromSavedState() {
    if (this.savedState) {
      this.fromJson(this.savedState);
    } else {
      console.error("state is not saved");
    }
  }
}

class ListenerStorage {
  constructor() {
    this.listeners = [];
  }
  add(callback, priority = 0, force = false) {
    if (!this.has(callback)) {
      if (priority !== 0 && this.listeners.length > 0) {
        const index = this.findIndex(this.listeners, priority, 0, this.listeners.length - 1);
        this.listeners.splice(index, 0, {
          callback,
          enable: true,
          priority
        });
      } else {
        this.listeners.push({ callback, enable: true, priority });
      }
      if (force && this.listenersForFire && typeof this.firedListenerIndex !== "undefined") {
        if (priority !== 0 && this.listeners.length > 0) {
          const index = this.findIndex(this.listenersForFire, priority, 0, this.listeners.length - 1);
          if (index > this.firedListenerIndex) {
            this.listenersForFire.splice(index, 0, {
              callback,
              enable: true,
              priority
            });
          } else if (index <= this.firedListenerIndex) {
            if (this.listenersForFire[this.firedListenerIndex + 1].priority === priority) {
              this.listenersForFire.splice(this.firedListenerIndex + 1, 0, {
                callback,
                enable: true,
                priority
              });
            }
          }
        } else {
          this.listenersForFire.push({
            callback,
            enable: true,
            priority
          });
        }
      }
    }
  }
  remove(callback, force = false) {
    let index = this.listeners.findIndex((listener) => listener.callback === callback);
    if (index >= 0) {
      this.listeners.splice(index, 1);
    }
    if (force && this.listenersForFire && typeof this.firedListenerIndex !== "undefined") {
      index = this.listenersForFire.findIndex((listener) => listener.callback === callback);
      if (index > this.firedListenerIndex) {
        this.listenersForFire.splice(index, 1);
      } else {
        console.error("listener already executed");
      }
    }
  }
  findIndex(listeners, priority, start, end) {
    let middle = Math.floor((start + end) / 2);
    if (listeners[middle].priority === priority) {
      return middle;
    } else {
      if (priority < listeners[middle].priority) {
        if (middle < end) {
          return this.findIndex(listeners, priority, middle + 1, end);
        }
        return end + 1;
      } else {
        if (start < middle) {
          return this.findIndex(listeners, priority, start, middle - 1);
        }
        return start;
      }
    }
  }
  fire(arg) {
    if (typeof arg === "undefined" && typeof this.fireArgument !== "undefined") {
      arg = this.fireArgument;
    }
    this.listenersForFire = this.getListeners();
    this.listenersForFire.forEach((listener, index) => {
      this.firedListenerIndex = index;
      if (listener.enable) {
        listener.callback(arg);
      }
    });
    this.listenersForFire = void 0;
    this.firedListenerIndex = void 0;
  }
  getListeners() {
    return [...this.listeners];
  }
  clear() {
    this.listeners.splice(0);
  }
  isEmpty() {
    return this.listeners.length === 0;
  }
  has(callback) {
    return this.listeners.findIndex((listener) => listener.callback === callback) >= 0;
  }
  setEnabled(value, callback) {
    if (callback) {
      if (callback.flag === "me") {
        const listener = this.listeners.find((listener2) => listener2.callback === callback.callback);
        if (listener) {
          listener.enable = value;
        }
      } else {
        const listener = this.listeners.filter((listener2) => listener2.callback !== callback.callback);
        listener.forEach((listener2) => listener2.enable = value);
      }
    } else {
      this.listeners.forEach((listener) => listener.enable = value);
    }
  }
  enable(callback) {
    if (callback) {
      this.setEnabled(true, { callback, flag: "me" });
    } else {
      this.setEnabled(true);
    }
  }
  disable(callback) {
    if (callback) {
      this.setEnabled(false, { callback, flag: "me" });
    } else {
      this.setEnabled(false);
    }
  }
  enableExcept(callbacks) {
    if (Array.isArray(callbacks)) {
      this.disable();
      callbacks.forEach((callback) => this.setEnabled(false, { callback, flag: "me" }));
    } else {
      this.setEnabled(true, { callback: callbacks, flag: "exceptMe" });
    }
  }
  disableExcept(callbacks) {
    if (Array.isArray(callbacks)) {
      this.disable();
      callbacks.forEach((callback) => this.setEnabled(true, { callback, flag: "me" }));
    } else {
      this.setEnabled(false, { callback: callbacks, flag: "exceptMe" });
    }
  }
}

class EventDispatcher {
  constructor(eventsList) {
    this.listeners = /* @__PURE__ */ new Map();
    this.map = /* @__PURE__ */ new Map();
    if (eventsList) {
      this.setListeners(eventsList);
    }
  }
  setListeners(eventsList) {
    !this.listeners.size && eventsList.forEach((event) => this.listeners.set(event, new ListenerStorage()));
  }
  deleteListeners() {
    this.clearListeners();
    this.listeners.clear();
  }
  addEventListener(type, callback, priority) {
    this.listeners.get(type).add(callback, priority);
    return () => this.removeEventListener(type, callback);
  }
  addEventListenerOnce(type, callback, priority) {
    let fnOnce = this.map.get(callback);
    if (!fnOnce) {
      fnOnce = (event) => {
        callback(event);
        this.removeEventListener(type, fnOnce);
        this.map.delete(callback);
      };
      this.map.set(callback, fnOnce);
    }
    return this.addEventListener(type, fnOnce, priority);
  }
  removeEventListener(type, callback, force) {
    this.listeners.get(type)?.remove(callback, force);
  }
  hasEventListener(type, callback) {
    return this.listeners.get(type).has(callback);
  }
  eventIsListened(eventName) {
    return this.listeners.has(eventName);
  }
  dispatchEvent(event) {
    this.listeners.get(event.type).fire(event);
  }
  clearListeners(type) {
    if (type) {
      this.listeners.get(type)?.clear();
    } else {
      this.listeners.forEach((listenerStorage) => listenerStorage.clear());
    }
  }
}

class ViewportRenderer {
  constructor(viewport) {
    this.viewport = viewport;
  }
}
class StandardViewportRenderer extends ViewportRenderer {
  constructor(viewport, toneMapping = NoToneMapping, outputEncoding = LinearEncoding) {
    super(viewport);
    this.toneMapping = toneMapping;
    this.outputEncoding = outputEncoding;
  }
  render() {
    const r = this.viewport.view.getRenderer();
    r.setViewport(this.viewport.size);
    r.setScissor(this.viewport.size);
    r.setScissorTest(true);
    r.setClearColor(this.viewport.clearColor, this.viewport.alpha);
    const toneMappingOld = r.toneMapping;
    const outputEncodingOld = r.outputEncoding;
    r.toneMapping = this.toneMapping;
    r.outputEncoding = this.outputEncoding;
    r.render(this.viewport.view.scene, this.viewport.camera);
    r.toneMapping = toneMappingOld;
    r.outputEncoding = outputEncodingOld;
  }
}

const viewportEventNames = ["cameraChanged", "updatePivotPoint", "resize", "reposition", "setCameraRollMode"];
class Viewport extends EventDispatcher {
  constructor(view, name, domElement, threeLayer = 0, {
    cameraControllerProps,
    eventNames,
    mutationObserverTarget
  } = {}) {
    super(eventNames || viewportEventNames);
    this.onMutateViewport = (() => {
      const cachedSize = new Vector4();
      return () => {
        this.handleResize();
        if (cachedSize.x !== this.size.x || cachedSize.y !== this.size.y) {
          this.dispatchEvent({ type: "reposition" });
          App.Instance().dispatchEvent({ type: "render" });
        }
        cachedSize.copy(this.size);
      };
    })();
    this.view = view;
    this.name = name;
    this.size = new Vector4();
    this.domElement = domElement;
    if (!this.domElement) console.error(`[Viewport] Reference ${name} was not found`);
    this.cameraController = new CameraController(this, cameraControllerProps);
    this.toolController = new ToolsController(this);
    this.rect = this.domElement.getBoundingClientRect();
    this.handleResize = this.handleResize.bind(this);
    this.onResizeViewport = this.onResizeViewport.bind(this);
    this.resizeObserver = new ResizeObserver(this.onResizeViewport);
    this.mutationObserver = new MutationObserver(this.onMutateViewport);
    this.enabled = false;
    this.camera.layers.enable(threeLayer);
    this.clearColor = 0;
    this.alpha = 1;
    this.viewportRenderer = new StandardViewportRenderer(this);
    this.mutationObserverTarget = mutationObserverTarget;
  }
  init() {
    if (this.enabled) return;
    this.resizeObserver.observe(this.domElement);
    this.mutationObserver.observe(this.mutationObserverTarget || this.view.getContainer(), {
      attributeFilter: ["style", "class"],
      subtree: true
    });
    this.handleResize();
    this.cameraController.init();
    this.toolController.init();
    const modeName = this.view.toolController.getModeName();
    if (modeName) {
      this.activateMode(modeName);
    }
    this.enabled = true;
  }
  dispose() {
    if (!this.enabled) return;
    this.cameraController.dispose();
    this.toolController.dispose();
    this.resizeObserver.unobserve(this.domElement);
    this.mutationObserver.disconnect();
    this.enabled = false;
  }
  setDomElement(domElement) {
    if (this.domElement === domElement) return;
    if (this.domElement) {
      this.resizeObserver.unobserve(this.domElement);
    }
    this.resizeObserver.observe(domElement);
    App.Instance().mouseController.redefineDomElement(domElement, this.domElement);
    this.domElement = domElement;
  }
  getDomElement() {
    return this.domElement;
  }
  handleResize() {
    this.rect = this.domElement.getBoundingClientRect();
    const { left, bottom, width, height } = this.rect;
    const { height: parentHeight, top: parentTop, left: parentLeft } = this.view.getContainer().getBoundingClientRect();
    this.size.set(left - parentLeft, parentHeight - bottom + parentTop, Math.ceil(width), Math.ceil(height));
  }
  onResizeViewport() {
    this.handleResize();
    this.dispatchEvent({ type: "resize" });
    App.Instance().dispatchEvent({ type: "render" });
  }
  get x() {
    return this.size.x;
  }
  set x(val) {
    this.size.x = val;
  }
  get y() {
    return this.size.y;
  }
  set y(val) {
    this.size.y = val;
  }
  get width() {
    return this.size.z;
  }
  set width(val) {
    this.size.z = val;
  }
  get height() {
    return this.size.w;
  }
  set height(val) {
    this.size.w = val;
  }
  get camera() {
    return this.cameraController.camera;
  }
  get cameraLayer() {
    return 31 - Math.clz32(this.camera.layers.mask);
  }
  activateMode(modeName) {
    this.toolController.activateMode(modeName);
  }
  renderImmediately() {
    this.viewportRenderer.render();
  }
  render() {
    if (this.requestID !== void 0) return;
    this.requestID = requestAnimationFrame(() => {
      this.renderImmediately();
      this.requestID = void 0;
    });
  }
  isEnabled() {
    return this.enabled;
  }
}

class SelectContainer extends Container {
  constructor(hostObject, objects) {
    super(hostObject, objects);
  }
  add(selectComponent) {
    if (Array.isArray(selectComponent)) {
      selectComponent.forEach((child) => {
        this.addOne(child);
      });
    } else {
      this.addOne(selectComponent);
    }
  }
  delete(selectComponent) {
    if (Array.isArray(selectComponent)) {
      selectComponent.forEach((child) => {
        this.deleteOne(child);
      });
    } else {
      this.deleteOne(selectComponent);
    }
  }
  size() {
    return this.objects.size;
  }
  addOne(selectComponent) {
    selectComponent.select();
    const layer = selectComponent.entity.getLayer();
    if (layer) {
      const selectables = this.objects.get(layer);
      if (selectables) {
        selectables.add(selectComponent);
      } else {
        this.objects.set(layer, /* @__PURE__ */ new Set([selectComponent]));
      }
    }
  }
  deleteOne(selectComponent) {
    selectComponent.deselect();
    const layer = selectComponent.entity.getLayer();
    if (layer) {
      const selectables = this.objects.get(layer);
      if (selectables) {
        selectables.delete(selectComponent);
      }
    }
  }
}

class SelectController {
  constructor() {
    this.objects = /* @__PURE__ */ new Map();
    this.container = new SelectContainer(this, this.objects);
  }
  getSelected(layer) {
    if (layer) {
      const selected = this.objects.get(layer);
      return selected ? [...selected] : [];
    } else {
      const selected = [];
      this.objects.forEach((objects) => {
        selected.push(...objects);
      });
      return selected;
    }
  }
  add(selectables) {
    this.container.add(selectables);
    App.Instance().dispatchEvent({
      type: "select",
      objects: Array.isArray(selectables) ? selectables : [selectables]
    });
  }
  delete(selectables) {
    this.container.delete(selectables);
    App.Instance().dispatchEvent({
      type: "deselect",
      objects: Array.isArray(selectables) ? selectables : [selectables]
    });
  }
  clear(layer) {
    const objects = this.getSelected(layer);
    objects?.forEach((object) => object.deselect());
    if (layer) {
      this.objects.delete(layer);
    } else {
      this.objects.clear();
    }
    App.Instance().dispatchEvent({ type: "deselect", objects });
  }
  selectedCount(layer) {
    if (layer) {
      const selected = this.objects.get(layer);
      return selected ? selected.size : 0;
    } else {
      let selectedCount = 0;
      this.objects.forEach((objects) => {
        selectedCount += objects.size;
      });
      return selectedCount;
    }
  }
  has(obj) {
    const map = this.objects;
    for (let set of map.values()) {
      if (set.has(obj)) {
        return true;
      }
    }
    return false;
  }
}

class View {
  constructor() {
    this.layers = /* @__PURE__ */ new Map();
    this.scene = new Scene();
    this.viewports = /* @__PURE__ */ new Map();
    this.toolController = new ToolsController(this);
    this.selectController = new SelectController();
    this.cameraLayers = [0];
    this.handleResize = this.handleResize.bind(this);
    this.onResizeView = this.onResizeView.bind(this);
    this.resizeObserver = new ResizeObserver(this.onResizeView);
  }
  init(props) {
    this.name = props?.name;
    this.container = props?.container || document.getElementById("container") || document.body.appendChild(document.createElement("div"));
    if (!this.container) console.error(`[View] Reference ${this.name} was not found`);
    this.renderer = new WebGLRenderer(props?.rendererProps);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.canvas = this.renderer.domElement;
    this.canvas.dataset.belongsTo = this.name;
    if (!props?.rendererProps?.canvas) {
      this.container.appendChild(this.canvas);
      this.canvas.style.width = "100%";
      this.canvas.style.height = "100%";
    }
    this.handleResize();
    this.toolController.init();
    this.resizeObserver.observe(this.container);
    App.Instance().addEventListener("resize", this.handleResize, 1);
    App.Instance().dispatchEvent({ type: "render" });
    this.container.addEventListener("contextmenu", (event) => event.preventDefault(), false);
  }
  dispose() {
    this.toolController.dispose();
    this.selectController.clear();
    this.clearLayers();
    this.viewports.forEach((viewport) => {
      viewport.dispose();
    });
    if (this.container) this.resizeObserver.unobserve(this.container);
    App.Instance().removeEventListener("resize", this.handleResize);
  }
  clear() {
    this.dispose();
    this.viewports.forEach((viewport) => {
      this.deleteViewport(viewport.name);
    });
    this.layers.forEach((layer) => {
      this.removeLayer(layer.getName());
    });
    this.toolController.clear();
  }
  clearLayers() {
    if (!this.layers.size) return;
    const layers = this.getLayers();
    for (const layer of layers) {
      layer.getObjects()?.forEach((obj) => obj.dispose());
      layer.clear();
    }
    App.Instance().dispatchEvent({ type: "render" });
  }
  getRenderer() {
    return this.renderer;
  }
  getContainer() {
    return this.container;
  }
  getCanvas() {
    return this.canvas;
  }
  onResizeView() {
    this.handleResize();
  }
  handleResize() {
    const width = this.container.clientWidth || 0;
    const height = this.container.clientHeight || 0;
    this.renderer.setSize(width, height, true);
  }
  addViewport(name, domElement, options) {
    const viewport = new Viewport(this, name, domElement, this.getEmptyCameraLayer(), options);
    this.viewports.set(name, viewport);
    return viewport;
  }
  deleteViewport(name) {
    const viewport = this.viewports.get(name);
    if (viewport) {
      const layer = viewport.cameraLayer;
      if (layer !== 0) {
        this.cameraLayers[layer] = void 0;
      }
      viewport.dispose();
      this.viewports.delete(name);
    }
  }
  getEmptyCameraLayer() {
    for (let i = 1; i < 32; i++) {
      if (!this.cameraLayers[i]) {
        this.cameraLayers[i] = i;
        return i;
      }
    }
  }
  getViewport(name) {
    return this.viewports.get(name);
  }
  addLayer(name, objects) {
    let layer = this.layers.get(name);
    if (layer) {
      objects && layer.add(objects);
      return layer;
    }
    layer = new Layer(this, name, objects);
    this.layers.set(name, layer);
    this.scene.add(layer.getGroup());
    App.Instance().dispatchEvent({ type: "addLayer", container: layer });
    return layer;
  }
  removeLayer(name) {
    const layer = this.layers.get(name);
    if (!layer) return;
    layer.getObjects()?.forEach((obj) => obj.dispose());
    layer.clear();
    this.scene.remove(layer.getGroup());
    this.layers.delete(name);
    App.Instance().dispatchEvent({ type: "deleteLayer", container: layer });
  }
  getLayer(name) {
    if (!this.layers.has(name)) console.warn("getLayer: no layer=", name);
    return this.layers.get(name);
  }
  *getLayers() {
    for (const layer of this.layers) {
      yield layer[1];
    }
  }
  *getVisibleLayers() {
    for (const layer of this.layers) {
      if (layer[1].getGroup().visible) {
        yield layer[1];
      }
    }
  }
  activateMode(modeName) {
    const previous = this.toolController.getModeName();
    if (previous === modeName) return;
    this.toolController.activateMode(modeName);
    this.viewports.forEach((viewport) => viewport.activateMode(modeName));
    const mode = this.toolController.getModeName();
    App.Instance().dispatchEvent({ type: "modeChanged", previous: previous !== void 0 ? previous : "", mode: mode !== void 0 ? mode : "" });
  }
  setActiveViewport(viewportName) {
    const viewport = typeof viewportName === "undefined" ? viewportName : this.viewports.get(viewportName);
    if (this.activeViewport === viewport) return;
    App.Instance().dispatchEvent({ type: "ActiveViewportWillChange", current: this.activeViewport, next: viewport });
    const previousViewport = this.activeViewport;
    this.activeViewport = viewport;
    App.Instance().dispatchEvent({ type: "ActiveViewportChanged", previous: previousViewport, current: viewport });
  }
  getActiveViewport() {
    return this.activeViewport;
  }
}

const undoStackEventNames = ["add", "undo", "redo", "clear"];
class UndoStack extends EventDispatcher {
  constructor() {
    super(undoStackEventNames);
    this.stack = [];
    this.index = -1;
    this.lastIndex = -1;
  }
  add(command) {
    if (this.index !== this.lastIndex) {
      this.stack.splice(this.index + 1);
    }
    this.stack.push(command);
    this.index++;
    this.lastIndex = this.index;
    this.dispatchEvent({ type: "add" });
  }
  getCommandList() {
    return [...this.stack];
  }
  removeLast() {
    this.stack.pop();
    this.index--;
    this.lastIndex = this.index;
  }
  undo() {
    if (this.index >= 0) {
      this.stack[this.index].undo();
      this.index--;
      this.dispatchEvent({ type: "undo" });
    }
  }
  redo() {
    if (this.index !== this.lastIndex) {
      this.stack[this.index + 1].execute();
      this.index++;
      this.dispatchEvent({ type: "redo" });
    }
  }
  undoAll() {
    do {
      this.undo();
    } while (this.index >= 0);
  }
  getIndex() {
    return this.index;
  }
  getLastIndex() {
    return this.lastIndex;
  }
  clear() {
    this.stack = [];
    this.index = -1;
    this.lastIndex = -1;
    this.dispatchEvent({ type: "clear" });
  }
}

const mouseButtons = ["left", "middle", "right"];
const anyButtonEventTypesArr = ["pointerdown", "pointerup"];
const specialEventTypesArr = ["click", "dblclick", "contextmenu", "pointermove", "pointerenter", "pointerleave", "pointerout", "pointerover", "wheel"];
const isSpecialEventType = (type) => {
  return specialEventTypesArr.some((el) => el === type);
};
class PointerEventsStorage {
  constructor(element = document) {
    this.element = element;
    this.listeners = {};
    this.callbacks = {};
  }
  init() {
    anyButtonEventTypesArr.forEach((type) => {
      this.listeners[type] = {
        left: new ListenerStorage(),
        middle: new ListenerStorage(),
        right: new ListenerStorage()
      };
      this.callbacks[type] = {
        callback: (event) => this.listeners[type][mouseButtons[event.button]].fire(event),
        isListening: false
      };
    });
    specialEventTypesArr.forEach((type) => {
      this.listeners[type] = new ListenerStorage();
      this.callbacks[type] = {
        callback: (event) => this.listeners[type].fire(event),
        isListening: false
      };
    });
  }
  dispose() {
    if (this.listeners) {
      this.clear();
      Object.keys(this.listeners).forEach((key) => delete this.listeners[key]);
    }
  }
  changeDomElement(element) {
    const oldDomElement = this.element;
    this.element = element;
    anyButtonEventTypesArr.forEach((eventType) => {
      oldDomElement.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      this.callbacks[eventType].isListening = false;
      this.startListening(eventType);
    });
    specialEventTypesArr.forEach((eventType) => {
      oldDomElement.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      this.callbacks[eventType].isListening = false;
      this.startListening(eventType);
    });
  }
  addAnyButtonListener(eventType, button, listener, priority, force) {
    this.listeners[eventType][button].add(listener, priority, force);
    this.startListening(eventType);
  }
  addSpecialEventListeners(eventType, listener, priority, force) {
    this.listeners[eventType].add(listener, priority, force);
    this.startListening(eventType);
  }
  removeButtonListener(eventType, button, listener, force) {
    if (this.listeners) {
      this.listeners[eventType][button].remove(listener, force);
      this.stopListening(eventType);
    }
  }
  removeMoveAndWheelListener(eventType, listener, force) {
    if (this.listeners) {
      this.listeners[eventType].remove(listener, force);
      this.stopListening(eventType);
    }
  }
  startListening(eventType) {
    if (this.callbacks[eventType].callback && !this.callbacks[eventType].isListening) {
      if (!this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = true;
        this.element.addEventListener(eventType, this.callbacks[eventType].callback, { capture: false, passive: false });
      }
    }
  }
  stopListening(eventType) {
    if (this.callbacks[eventType].callback && this.callbacks[eventType].isListening) {
      if (this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = false;
        this.element.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      }
    }
  }
  clear() {
    anyButtonEventTypesArr.forEach((eventType) => {
      Object.values(this.listeners[eventType]).forEach((value) => value.clear());
      this.stopListening(eventType);
    });
    specialEventTypesArr.forEach((eventType) => {
      this.listeners[eventType].clear();
      this.stopListening(eventType);
    });
  }
  isEmpty(eventType) {
    if (isSpecialEventType(eventType)) {
      return this.listeners[eventType].isEmpty();
    }
    return Object.values(this.listeners[eventType]).every((value) => value.isEmpty());
  }
  has(eventType, listener, button) {
    if (this.listeners[eventType] instanceof ListenerStorage) {
      return this.listeners[eventType].has(listener);
    } else if (button) {
      return this.listeners[eventType][button].has(listener);
    }
    return false;
  }
  disableButton(eventType, button, listener) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].disable(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].disable(listener);
    }
  }
  enableButton(eventType, button, listener) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].enable(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].enable(listener);
    }
  }
  disableButtonExcept(eventType, listener, button) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].disableExcept(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].disableExcept(listener);
    }
  }
  enableButtonExcept(eventType, listener, button) {
    if (eventType === "click" || eventType === "dblclick" || eventType === "contextmenu") {
      this.listeners[eventType].enableExcept(listener);
    } else if (button && typeof button !== "function") {
      this.listeners[eventType][button].enableExcept(listener);
    }
  }
  disableWheel(listener) {
    this.listeners["wheel"].disable(listener);
  }
  enableWheel(listener) {
    this.listeners["wheel"].enable(listener);
  }
  disableMove(listener) {
    this.listeners["pointermove"].disable(listener);
  }
  enableMove(listener) {
    this.listeners["pointermove"].enable(listener);
  }
  disableMoveExcept(listeners) {
    this.listeners["pointermove"].disableExcept(listeners);
  }
  enableMoveExcept(listeners) {
    this.listeners["pointermove"].enableExcept(listeners);
  }
}

class PointerController {
  constructor() {
    this.listeners = (/* @__PURE__ */ new Map()).set(document, new PointerEventsStorage());
  }
  init() {
    this.listeners.forEach((val) => val.init());
  }
  dispose() {
    this.listeners.forEach((val) => val.dispose());
  }
  clear() {
    this.dispose();
    this.listeners.clear();
  }
  redefineDomElement(newElement, oldElement) {
    const oldElementListeners = this.listeners.get(oldElement);
    if (oldElementListeners) {
      oldElementListeners.changeDomElement(newElement);
      this.listeners.set(newElement, oldElementListeners);
      this.listeners.delete(oldElement);
    }
  }
  addListener(eventType, listener, element = document, button, priority, force) {
    let mouseEventListeners = this.listeners.get(element);
    if (!mouseEventListeners) {
      mouseEventListeners = new PointerEventsStorage(element);
      this.listeners.set(element, mouseEventListeners);
      mouseEventListeners.init();
    }
    if (isSpecialEventType(eventType)) {
      if ((!button || typeof button === "number") && typeof priority !== "number") {
        mouseEventListeners.addSpecialEventListeners(eventType, listener, button, priority);
        return () => this.removeListener(eventType, listener, element);
      } else {
        console.error("listener is not added. Event type: " + eventType);
      }
    } else if (button !== void 0) {
      if (typeof button !== "number" && typeof priority !== "boolean") {
        mouseEventListeners.addAnyButtonListener(eventType, button, listener, priority, force);
        return () => this.removeListener(eventType, listener, element, button);
      } else {
        console.error("listener is not added. Event type: " + eventType + ", button: " + button);
      }
    } else {
      console.error("listener is not added. Event type: " + eventType + ", button: " + button);
    }
  }
  removeListener(eventType, listener, element = document, button, force = false) {
    let mouseEventListeners;
    if (element) {
      mouseEventListeners = this.listeners.get(element);
    }
    if (mouseEventListeners) {
      if (isSpecialEventType(eventType)) {
        if (typeof button === "boolean" || typeof button === "undefined") {
          mouseEventListeners.removeMoveAndWheelListener(eventType, listener, button);
        }
      } else if (button !== void 0 && typeof button !== "boolean") {
        mouseEventListeners.removeButtonListener(eventType, button, listener, force);
      }
    } else {
      console.warn("listener is not found");
    }
  }
}

const keyBoardEventTypesArr = ["keydown", "keyup", "keypress"];
class KeyboardController {
  init() {
    this.listeners = {
      keydown: /* @__PURE__ */ new Map(),
      keyup: /* @__PURE__ */ new Map(),
      keypress: /* @__PURE__ */ new Map()
    };
    this.callbacks = {};
    keyBoardEventTypesArr.forEach((type) => {
      this.callbacks[type] = {
        callback: (event) => this.listeners[type].get(event.code)?.fire(event),
        isListening: true
      };
    });
  }
  dispose() {
    if (this.listeners) {
      keyBoardEventTypesArr.forEach((eventType) => {
        this.listeners[eventType].forEach(
          (ListenerStorage2) => ListenerStorage2.clear()
        );
        this.listeners[eventType].clear();
        this.stopListening(eventType);
      });
    }
  }
  addListener(eventType, listener, eventCode, priority) {
    const listenerStorage = this.listeners[eventType].get(eventCode);
    if (listenerStorage) {
      listenerStorage.add(listener, priority);
    } else {
      const storage = new ListenerStorage();
      storage.add(listener, priority);
      this.listeners[eventType].set(eventCode, storage);
    }
    this.startListening(eventType);
  }
  removeListener(eventType, listener, code) {
    const listenerStorage = this.listeners[eventType].get(code);
    if (listenerStorage) {
      listenerStorage.remove(listener);
      this.stopListening(eventType);
    }
  }
  disableKeys(keys) {
    if (keys) {
      Object.values(this.listeners).forEach((item) => keys.forEach((key) => item.get(key)?.disable()));
    } else {
      Object.values(this.listeners).forEach((item) => item.forEach((ListenerStorage2) => ListenerStorage2.disable()));
    }
  }
  enableKeys(keys) {
    if (keys) {
      Object.values(this.listeners).forEach((item) => keys.forEach((key) => item.get(key)?.enable()));
    } else {
      Object.values(this.listeners).forEach((item) => item.forEach((ListenerStorage2) => ListenerStorage2.enable()));
    }
  }
  startListening(eventType) {
    if (!this.isEmpty(eventType)) {
      document.addEventListener(eventType, this.callbacks[eventType].callback, false);
    }
  }
  stopListening(eventType) {
    if (this.callbacks[eventType].isListening) {
      if (this.isEmpty(eventType)) {
        this.callbacks[eventType].isListening = false;
        document.removeEventListener(eventType, this.callbacks[eventType].callback, false);
      }
    }
  }
  isEmpty(eventType) {
    return this.listeners[eventType].size === 0;
  }
}

class Component {
  constructor(entity) {
    this.entity = entity;
  }
}

class Raycast extends Component {
  constructor(entity) {
    super(entity);
  }
  isRaycast(raycaster, recursive = true) {
    const activeViewport = App.Instance().getActiveView().getActiveViewport();
    if (activeViewport) {
      raycaster.layers.mask = activeViewport.camera.layers.mask;
      const intersections = raycaster.intersectObject(this.entity.object3d, recursive);
      return intersections.length ? intersections : false;
    }
    return false;
  }
  *intersect(raycaster) {
    if (!this.entity.object3d.visible) return;
    const intersections = this.isRaycast(raycaster, true);
    if (intersections) {
      yield { raycast: this, intersections };
    }
  }
}
class RaycastChildrenOnly extends Raycast {
  *intersect(raycaster) {
    for (const child of this.entity.children) {
      const raycast = child.getComponent(Raycast);
      if (raycast) {
        yield* raycast.intersect(raycaster);
      }
    }
  }
}

var EventPriority = /* @__PURE__ */ ((EventPriority2) => {
  EventPriority2[EventPriority2["None"] = 0] = "None";
  EventPriority2[EventPriority2["Tool"] = 10] = "Tool";
  EventPriority2[EventPriority2["RaycastController"] = 20] = "RaycastController";
  EventPriority2[EventPriority2["CursorController"] = 30] = "CursorController";
  return EventPriority2;
})(EventPriority || {});

class Highlighter {
  constructor(entity) {
    this.entity = entity;
  }
}

class ColorHighlighter extends Highlighter {
  constructor(object3d, color = 16711680) {
    super(object3d);
    this.material = /* @__PURE__ */ new Map();
    this.color = color;
  }
  highlight() {
    this.saveColor();
    this.setColor(this.color);
  }
  unHighlight() {
    this.restoreColor();
  }
  setColor(color) {
    if (this.entity.object3d instanceof Mesh) {
      this.makeSetColor(this.entity.object3d.material, color);
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material) this.makeSetColor(obj.material, color);
      });
    }
  }
  saveColor() {
    if (this.entity.object3d instanceof Mesh) {
      this.makeSaveColor(this.entity.object3d.material, this.entity);
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material) this.makeSaveColor(obj.material, obj);
      });
    }
  }
  restoreColor() {
    if (this.entity.object3d instanceof Mesh) {
      this.makeRestoreColor(this.entity.object3d.material, this.material.get(this.entity));
    } else if (this.entity.object3d instanceof Object3D) {
      this.entity.object3d.traverse((obj) => {
        if (obj.material) this.makeRestoreColor(obj.material, this.material.get(obj));
      });
    }
  }
  makeSetColor(mat, color) {
    const material = mat;
    if (material instanceof ShaderMaterial) {
      material.uniforms.diffuse?.value?.set(color);
      material.uniforms.specular?.value?.set(color);
      material.uniforms.emissive?.value?.set(color);
    } else {
      material.color?.set(color);
      material.specular?.set(color);
      material.emissive?.set(color);
    }
  }
  makeSaveColor(mat, obj) {
    const material = mat;
    if (material instanceof ShaderMaterial) {
      this.material.set(obj, {
        color: material.uniforms.diffuse?.value?.toArray(),
        specular: material.uniforms.specular?.value?.toArray(),
        emissive: material.uniforms.emissive?.value?.toArray()
      });
    } else {
      this.material.set(obj, {
        color: material.color?.toArray(),
        specular: material.specular?.toArray(),
        emissive: material.emissive?.toArray()
      });
    }
  }
  makeRestoreColor(mat, data) {
    const material = mat;
    if (data) {
      if (material instanceof ShaderMaterial) {
        material.uniforms.diffuse?.value?.fromArray(data.color);
        material.uniforms.specular?.value?.fromArray(data.specular);
        material.uniforms.emissive?.value?.fromArray(data.emissive);
      } else {
        material.color?.fromArray(data.color);
        material.specular?.fromArray(data.specular);
        material.emissive?.fromArray(data.emissive);
      }
    }
  }
}

class HoverComponent extends Component {
  constructor(entity, highlighter = new ColorHighlighter(entity, void 0)) {
    super(entity);
    this.hovered = false;
    this.highlighter = highlighter;
  }
  hover() {
    this.hovered = true;
    this.highlighter.highlight();
  }
  unhover() {
    this.hovered = false;
    this.highlighter.unHighlight();
  }
  isHovered() {
    return this.hovered;
  }
}

class RaycastController {
  get view() {
    return App.Instance().getActiveView();
  }
  get position() {
    return App.Instance().cursorController.position;
  }
  constructor() {
    this.raycaster = new Raycaster();
    this.raycaster.params.Line = { threshold: 0.01 };
    this.state = {
      transformObject: false,
      transformCamera: false
    };
    this.handleChangeActiveViewport = this.handleChangeActiveViewport.bind(this);
    this.onTransformStart = this.onTransformStart.bind(this);
    this.onTransformEnd = this.onTransformEnd.bind(this);
    this.onTransformCameraStart = this.onTransformCameraStart.bind(this);
    this.onTransformCameraEnd = this.onTransformCameraEnd.bind(this);
    this.onTransformCamera = this.onTransformCamera.bind(this);
    this.update = this.update.bind(this);
  }
  init() {
    this.changeListenActiveViewport(this.viewport, this.view.getActiveViewport());
    App.Instance().addEventListener("ActiveViewportWillChange", this.handleChangeActiveViewport);
    App.Instance().addEventListener("transformStart", this.onTransformStart);
    App.Instance().addEventListener("transformCamera", this.onTransformCamera);
    App.Instance().addEventListener("transformCameraStart", this.onTransformCameraStart);
  }
  dispose() {
    this.changeListenActiveViewport(this.viewport);
    App.Instance().removeEventListener("ActiveViewportWillChange", this.handleChangeActiveViewport);
    App.Instance().removeEventListener("transformStart", this.onTransformStart);
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
    App.Instance().removeEventListener("transformCamera", this.onTransformCamera);
    App.Instance().removeEventListener("transformCameraStart", this.onTransformCameraStart);
    this.raycastData = void 0;
    this.object = void 0;
  }
  changeListenActiveViewport(currentViewport, viewport) {
    if (currentViewport) {
      App.Instance().mouseController.removeListener("pointermove", this.update, currentViewport.getDomElement(), true);
      App.Instance().mouseController.removeListener("pointerdown", this.update, currentViewport.getDomElement(), "left", true);
      currentViewport.removeEventListener("resize", this.update);
      currentViewport.removeEventListener("reposition", this.update);
    }
    if (viewport) {
      App.Instance().mouseController.addListener("pointermove", this.update, viewport.getDomElement(), EventPriority.RaycastController);
      App.Instance().mouseController.addListener("pointerdown", this.update, viewport.getDomElement(), "left", EventPriority.RaycastController);
      viewport.addEventListener("resize", this.update);
      viewport.addEventListener("reposition", this.update);
      this.updateLineThreshold(viewport);
    } else if (this.object) {
      const previousObject = this.object;
      this.object = void 0;
      App.Instance().dispatchEvent({ type: "unhover", entity: previousObject });
    }
    this.viewport = viewport;
  }
  start() {
    this.changeListenActiveViewport(this.viewport, this.view.getActiveViewport());
    this.update();
  }
  stop() {
    this.changeListenActiveViewport(this.viewport);
  }
  handleChangeActiveViewport(event) {
    this.changeListenActiveViewport(this.viewport, event.next);
  }
  updateLineThreshold(viewport) {
    const zoom = viewport.cameraController.camera.zoom;
    this.raycaster.params.Line.threshold = 60 / zoom;
  }
  onTransformCamera(event) {
    if (event.viewport === this.viewport) this.updateLineThreshold(this.viewport);
  }
  onTransformCameraStart() {
    if (!this.state.transformCamera && !this.state.transformObject) {
      this.stop();
    }
    App.Instance().addEventListener("transformCameraEnd", this.onTransformCameraEnd);
    this.state.transformCamera = true;
  }
  onTransformCameraEnd() {
    this.state.transformCamera = false;
    if (!this.state.transformObject) {
      this.start();
    }
    App.Instance().removeEventListener("transformCameraEnd", this.onTransformCameraEnd);
  }
  onTransformStart() {
    if (!this.state.transformCamera && !this.state.transformObject) {
      this.stop();
    }
    App.Instance().addEventListener("transformEnd", this.onTransformEnd);
    this.state.transformObject = true;
  }
  onTransformEnd() {
    this.state.transformObject = false;
    if (!this.state.transformCamera) {
      this.start();
    }
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
  }
  getNearest(generator) {
    const results = [];
    for (const intersection of generator) {
      intersection.intersections.sort((a, b) => a.distance - b.distance);
      results.push(intersection);
    }
    if (results.length) {
      results.sort((a, b) => a.intersections[0].distance - b.intersections[0].distance);
      return results[0];
    }
  }
  *raycast() {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    for (const layer of this.view.getLayers()) {
      const objects = layer.getComponent(Raycast);
      if (objects) {
        for (const object of objects) {
          const generator = object.intersect(this.raycaster);
          for (const intersection of generator) {
            yield intersection;
          }
        }
      }
    }
  }
  *raycastObject(object) {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    let generator;
    if (object instanceof Raycast) {
      generator = object.intersect(this.raycaster);
    } else {
      const raycast = object.getComponent(Raycast);
      if (!raycast) return;
      generator = raycast.intersect(this.raycaster);
    }
    for (const intersection of generator) {
      yield intersection;
    }
  }
  getPositionOnZeroPlane(v) {
    this.raycaster.setFromCamera(this.position, this.viewport.camera);
    const distance = -this.raycaster.ray.origin.z / this.raycaster.ray.direction.z;
    v.x = this.raycaster.ray.origin.x + this.raycaster.ray.direction.x * distance;
    v.y = this.raycaster.ray.origin.y + this.raycaster.ray.direction.y * distance;
    v.z = 0;
    return v;
  }
  update() {
    this.raycastData = this.getNearest(this.raycast());
    const previousObject = this.object;
    this.object = this.raycastData?.raycast.entity;
    if (previousObject !== this.object) {
      if (previousObject) {
        App.Instance().dispatchEvent({ type: "unhover", entity: previousObject });
      }
      if (this.raycastData && this.raycastData.raycast.entity.getComponent(HoverComponent)) {
        App.Instance().dispatchEvent({ type: "hover", entity: this.raycastData.raycast.entity });
      }
    }
  }
  getObject() {
    return this.object;
  }
}

class CursorController {
  constructor() {
    this.position = new Vector2(0, 0);
    // active viewport position
    this.clientPosition = new Vector2(0, 0);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.onChangeActiveViewport = this.onChangeActiveViewport.bind(this);
  }
  init() {
    App.Instance().addEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    if (this.viewport) {
      this.viewport.addEventListener("resize", this.handleResize, EventPriority.CursorController);
      this.viewport.addEventListener("reposition", this.handleResize, EventPriority.CursorController);
      App.Instance().mouseController.addListener("pointermove", this.handleChangePosition, this.viewport.getDomElement(), EventPriority.CursorController);
      App.Instance().mouseController.addListener("pointerdown", this.handleChangePosition, this.viewport.getDomElement(), "left", EventPriority.CursorController);
    }
  }
  dispose() {
    App.Instance().removeEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMove, document);
    if (this.viewport) {
      App.Instance().mouseController.removeListener("pointermove", this.handleChangePosition, this.viewport.getDomElement());
      App.Instance().mouseController.removeListener("pointerdown", this.handleChangePosition, this.viewport.getDomElement(), "left");
      this.viewport.removeEventListener("resize", this.handleResize);
      this.viewport.removeEventListener("reposition", this.handleResize);
    }
  }
  get view() {
    return App.Instance().getActiveView();
  }
  get viewport() {
    return this.view.getActiveViewport();
  }
  onChangeActiveViewport(event) {
    event.current?.removeEventListener("resize", this.handleResize);
    event.next?.addEventListener("resize", this.handleResize, EventPriority.CursorController);
    event.current?.removeEventListener("reposition", this.handleResize);
    event.next?.addEventListener("reposition", this.handleResize, EventPriority.CursorController);
    if (event.current) {
      App.Instance().mouseController.removeListener("pointermove", this.handleChangePosition, event.current.getDomElement());
      App.Instance().mouseController.removeListener("pointerdown", this.handleChangePosition, event.current.getDomElement(), "left");
    }
    if (event.next) {
      App.Instance().mouseController.addListener("pointermove", this.handleChangePosition, event.next.getDomElement(), EventPriority.CursorController);
      App.Instance().mouseController.addListener("pointerdown", this.handleChangePosition, event.next.getDomElement(), "left", EventPriority.CursorController);
    }
  }
  setPosition(x, y) {
    this.position.set(
      (x - this.viewport.rect.left) / this.viewport.rect.width * 2 - 1,
      -(y - this.viewport.rect.top) / this.viewport.rect.height * 2 + 1
    );
  }
  handleChangePosition(event) {
    this.setPosition(event.clientX, event.clientY);
  }
  handleMouseMove(event) {
    this.clientPosition.set(event.clientX, event.clientY);
  }
  handleResize() {
    this.setPosition(this.clientPosition.x, this.clientPosition.y);
  }
}

class App extends EventDispatcher {
  constructor() {
    super();
    this.inited = false;
    this.view = new View();
    this.activeView = this.view;
    this.views = {};
    this.mouseController = new PointerController();
    this.keyboardController = new KeyboardController();
    this.raycastController = new RaycastController();
    this.cursorController = new CursorController();
    this.undoStack = new UndoStack();
    this.handleResize = this.handleResize.bind(this);
    this.resources = /* @__PURE__ */ new Map();
    this.startupSystems = [];
  }
  static Instance() {
    return this.instance ?? (this.instance = new App());
  }
  init(props) {
    this.mouseController.init();
    this.keyboardController.init();
    this.raycastController.init();
    this.cursorController.init();
    this.view.init(props);
    window.addEventListener("resize", this.handleResize, false);
    if (props?.container) {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(props.container);
    }
    this.inited = true;
  }
  dispose() {
    if (!this.inited) return;
    this.view.dispose();
    for (const key in this.views) {
      this.views[key].dispose();
    }
    this.cursorController.dispose();
    this.raycastController.dispose();
    this.keyboardController.dispose();
    this.mouseController.dispose();
    this.undoStack.clearListeners();
    this.clearListeners();
    window.removeEventListener("resize", this.handleResize, false);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = void 0;
    }
    this.inited = false;
  }
  clear() {
    this.dispose();
    this.view.clear();
    for (const key in this.views) {
      this.views[key].clear();
    }
    this.deleteListeners();
    this.undoStack.clear();
  }
  async runStartups() {
    for (let i = 0, il = this.startupSystems.length; i < il; i++) {
      const system = this.startupSystems[i];
      if (system.prototype?.constructor) {
        await new system().start();
      } else {
        await system();
      }
      this.dispatchEvent({ type: "applicationProgress", progress: (i + 1) / il });
    }
  }
  executeCommand(command, immediately = true) {
    if (immediately) {
      if (command.execute()) {
        this.undoStack.add(command);
      }
    } else {
      this.undoStack.add(command);
    }
  }
  getActiveView() {
    return this.activeView;
  }
  setActiveView(view) {
    if (this.activeView === view) {
      return;
    }
    this.activeView.setActiveViewport(void 0);
    this.activeView = view;
  }
  addResources(name, resource) {
    this.resources.set(name, resource);
    this.dispatchEvent({ type: "resourceAdded", name });
  }
  getResources(name) {
    return this.resources.get(name);
  }
  handleResize() {
    this.dispatchEvent({ type: "resize" });
  }
}

class ViewportComponent extends Component {
  constructor(entity) {
    super(entity);
    this.addViewport = (name, recursive = true) => {
      const viewport = name instanceof Viewport ? name : App.Instance().view.getViewport(name);
      if (viewport) {
        const cameraLayer = viewport.cameraLayer;
        if (cameraLayer) {
          this.viewports.add(viewport);
          this.entity.object3d.layers.enable(cameraLayer);
          this.entity.object3d.layers.disable(0);
          if (recursive) {
            this.entity.children.forEach((entity) => {
              const component = entity.getComponent(ViewportComponent);
              component?.addViewport(name, true);
            });
          }
        }
      }
    };
    this.deleteViewport = (name, recursive = true) => {
      const viewport = name instanceof Viewport ? name : App.Instance().view.getViewport(name);
      if (viewport) {
        const cameraLayer = viewport.cameraLayer;
        if (cameraLayer) {
          this.viewports.delete(viewport);
          this.entity.object3d.layers.disable(cameraLayer);
          if (recursive) {
            this.entity.children.forEach((entity) => {
              const component = entity.getComponent(ViewportComponent);
              component?.deleteViewport(name, true);
            });
          }
        }
      }
    };
    this.viewports = /* @__PURE__ */ new Set();
  }
  deleteAllViewports(recursive = true) {
    this.viewports.clear();
    this.entity.object3d.layers.set(0);
    if (recursive) {
      this.entity.children.forEach((entity) => {
        const component = entity.getComponent(ViewportComponent);
        component?.deleteAllViewports(true);
      });
    }
  }
  hasViewport(name) {
    const viewport = name instanceof Viewport ? name : App.Instance().view.getViewport(name);
    return this.viewports.has(viewport);
  }
}

const parseDicom = (byteArray) => {
  const dataSet = dicomParser.parseDicom(
    byteArray
    /*, options */
  );
  const pixelData = dataSet.elements.x7fe00010;
  const width = dataSet.int16("x00280011");
  const height = dataSet.int16("x00280010");
  const wc = dataSet.floatString("x00281050");
  const ww = dataSet.floatString("x00281051");
  const pixelSpacing = dataSet.string("x00280030")?.split("\\").map(parseFloat);
  let data;
  if (width * height === pixelData.length) {
    const x = new Uint8Array(dataSet.byteArray.buffer, pixelData.dataOffset, pixelData.length);
    data = new Int16Array(x);
  } else {
    data = new Int16Array(dataSet.byteArray.buffer, pixelData.dataOffset, pixelData.length / 2);
  }
  return { width, height, ww, wc, pixelSpacing, data };
};

class DicomTexture extends DataTexture {
  constructor(data, width, height, ww, wc) {
    super(data, width, height, RedFormat, FloatType);
    this.ww = ww;
    this.wc = wc;
    this.magFilter = LinearFilter;
    this.minFilter = LinearFilter;
    this.flipY = true;
  }
  get width() {
    return this.image.width;
  }
  get height() {
    return this.image.height;
  }
}

const promiseWithResolvers = () => {
  let resolve;
  let reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    promise,
    resolve,
    reject
  };
};

async function sleep(milliseconds) {
  await new Promise((res) => setTimeout(res, milliseconds));
}

const isDICOM = (buffer) => buffer[128] === 68 && buffer[129] === 73 && buffer[130] === 67 && buffer[131] === 77;
class ImageLoader {
  constructor() {
    this.images = {};
    this.promises = /* @__PURE__ */ new Map();
  }
  async get(url, cb) {
    if (Array.isArray(url)) {
      return Promise.all(url.map((url2) => this.getOneImage(url2, cb)));
    } else {
      return this.getOneImage(url, cb);
    }
  }
  setWithCredentials(value) {
    this.credentials = value ? "include" : "same-origin";
  }
  async getOneImage(url, cb) {
    let image;
    let promise = this.promises.get(url);
    if (promise) {
      image = await promise;
    } else {
      image = this.images[url];
      if (!image) {
        promise = this.load(url);
        if (!promise) return;
        this.promises.set(url, promise);
        image = await promise;
        this.promises.delete(url);
        this.images[url] = image;
      }
    }
    if (cb) {
      cb(url, image);
    }
    if (this.waitForLoaded && !this.promises.size) {
      this.waitForLoaded.resolve();
      this.waitForLoaded = void 0;
    }
    return image;
  }
  async load(url) {
    const response = await this.tryToFetch(url, 2, 100);
    const arrayBuffer = await response.arrayBuffer();
    if (isDICOM(new Uint8Array(arrayBuffer))) {
      const byteArray = new Uint8Array(arrayBuffer);
      const { width, height, ww, wc, data } = parseDicom(byteArray);
      const texture = new DicomTexture(new Float32Array(data), width, height, ww, wc);
      texture.needsUpdate = true;
      return texture;
    } else {
      const blob = new Blob([arrayBuffer]);
      const objectURL = URL.createObjectURL(blob);
      const image = document.createElement("img");
      image.src = objectURL;
      await new Promise((resolve) => image.onload = resolve);
      const texture = new Texture();
      texture.image = image;
      texture.needsUpdate = true;
      return texture;
    }
  }
  async tryToFetch(url, times = 1, timeout = false) {
    times = times < 1 ? 1 : times;
    for (let i = 0; i < times; i++) {
      const response = await fetch(url, { credentials: this.credentials });
      if (!response.ok) {
        console.error(`[ImageLoader] fetching failed. Attempt No. ${i}. Status ${response.status}. URL ${url}`);
        timeout && await sleep(timeout);
      } else {
        return response;
      }
    }
    return Promise.reject(`[ImageLoader] All attempts to fetch image have failed. URL ${url}`);
  }
  waitForLoading\u0421omplete() {
    if (!this.waitForLoaded && this.promises.size) {
      this.waitForLoaded = promiseWithResolvers();
      return this.waitForLoaded.promise;
    }
    return Promise.resolve();
  }
}
var ImageLoader$1 = new ImageLoader();

class EntityContainer extends Container {
  constructor(parent, objects = []) {
    super(parent, objects);
  }
  size() {
    return this.objects.length;
  }
  add(entity) {
    if (Array.isArray(entity)) {
      const added = [...entity].filter(this.addOne.bind(this));
      App.Instance().dispatchEvent({ type: "add", entities: added, container: this.hostObject });
    } else {
      if (this.addOne(entity)) {
        App.Instance().dispatchEvent({ type: "add", entities: [entity], container: this.hostObject });
      }
    }
  }
  delete(entity) {
    if (Array.isArray(entity)) {
      const deleted = entity.filter((child) => {
        this.deleteOne(child);
      });
      App.Instance().dispatchEvent({ type: "delete", entities: deleted, container: this.hostObject });
    } else {
      if (this.deleteOne(entity)) {
        App.Instance().dispatchEvent({ type: "delete", entities: [entity], container: this.hostObject });
      }
    }
  }
  addOne(entity) {
    if (entity.parent !== this.hostObject) {
      if (entity.parent) {
        entity.parent.delete(entity);
      }
      this.objects.push(entity);
      entity.parent = this.hostObject;
      this.hostObject.object3d?.add(entity.object3d);
      return true;
    }
    return false;
  }
  deleteOne(entity) {
    if (entity.parent === this.hostObject) {
      const index = this.hostObject.children.indexOf(entity);
      if (index >= 0) {
        this.hostObject.children.splice(index, 1);
      }
      entity.parent = void 0;
      this.hostObject.object3d?.remove(entity.object3d);
      return true;
    }
    return false;
  }
}

const entityEventNames = ["transform", "update", "delete", "add"];
class Entity extends EventDispatcher {
  constructor(object3D) {
    super(entityEventNames);
    this.object3d = object3D;
    this.children = [];
    this.container = new EntityContainer(this, this.children);
    this.components = /* @__PURE__ */ new Map();
    this.uuid = MathUtils.generateUUID();
  }
  add(children) {
    this.container.add(children);
  }
  delete(children) {
    this.container.delete(children);
  }
  getBaseClass(component) {
    const prototype = Object.getPrototypeOf(component);
    if (prototype === Component) {
      return component;
    } else {
      return this.getBaseClass(prototype);
    }
  }
  getComponent(type) {
    return this.components.get(type);
  }
  setComponent(component, instance) {
    const BaseConcreteClass = this.getBaseClass(component);
    if (instance) {
      this.components.set(BaseConcreteClass, instance);
    } else if (component) {
      const instance2 = new component(this);
      !this.components.has(component) && this.components.set(BaseConcreteClass, instance2);
      return instance2;
    }
  }
  removeComponents() {
    this.components.clear();
  }
  getLayer() {
    return this.layer || this.parent?.getLayer();
  }
  setLayer(layer) {
    if (!this.layer?.has(this)) {
      if (layer?.has(this)) {
        this.layer = layer;
      } else {
        this.layer = void 0;
      }
    }
  }
  dispose() {
    this.object3d.traverse((obj) => {
      if (obj.geometry) {
        obj.geometry.dispose();
      }
      if (obj.material) {
        if (obj.material instanceof Material) {
          obj.material.dispose();
        } else if (Array.isArray(obj.material)) {
          obj.material.forEach((material) => material instanceof Material && material.dispose());
        }
      }
    });
    this.object3d.clear();
  }
}

class Wrapper extends Entity {
  constructor(entities, id) {
    super(new Group());
    this.id = id;
    entities.length && this.add(entities);
  }
  add(children) {
    this.container.add(children);
  }
  delete(children) {
    this.container.delete(children);
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

class Block extends Wrapper {
  constructor(entities = [], width, height, id) {
    super(entities, id);
    this.width = width;
    this.height = height;
  }
}

var vertexShader$5 = "#define GLSLIFY 1\nattribute vec3 position;attribute vec2 uv;uniform mat4 modelViewMatrix;uniform mat4 projectionMatrix;uniform mat3 uvMatrix;varying vec2 out_uv;varying vec2 out_transformed_uv;void main(){gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.0);out_transformed_uv=(uvMatrix*vec3(uv,1)).xy;out_uv=uv;}"; // eslint-disable-line

var fragmentShader$6 = "precision highp float;\n#define GLSLIFY 1\nuniform sampler2D dataTexture;uniform vec2 resolution;uniform float sharpness;uniform float brightness;uniform float contrast;uniform float invert;varying vec2 out_uv;varying vec2 out_transformed_uv;vec4 texsample(int x,int y,in vec2 uv){return texture2D(dataTexture,(uv+vec2(x,y)/resolution));}vec4 sharpnessFilter(vec2 uv){vec4 sum=texsample(-1,-1,uv)*-1.+texsample(-1,0,uv)*-1.+texsample(-1,1,uv)*-1.+texsample(0,-1,uv)*-1.+texsample(0,0,uv)*9.+texsample(0,1,uv)*-1.+texsample(1,-1,uv)*-1.+texsample(1,0,uv)*-1.+texsample(1,1,uv)*-1.;return sum;}void main(){vec4 color=sharpnessFilter(out_transformed_uv);color=mix(texture2D(dataTexture,out_transformed_uv),color,sharpness);color.rgb+=brightness;if(contrast>0.0){color.rgb=(color.rgb-0.5)/(1.0-contrast)+0.5;}else{color.rgb=(color.rgb-0.5)*(1.0+contrast)+0.5;}color=mix(color,vec4((1.0-color.xyz),color.w),invert);gl_FragColor=color;}"; // eslint-disable-line

class RasterImageMaterial extends RawShaderMaterial {
  constructor(texture, width, height, uvMatrix) {
    super({
      vertexShader: vertexShader$5,
      fragmentShader: fragmentShader$6,
      uniforms: {
        dataTexture: { value: texture },
        uvMatrix: { value: uvMatrix },
        brightness: { value: 0 },
        contrast: { value: 0 },
        sharpness: { value: 0 },
        resolution: { value: new Vector2(width, height) },
        invert: { value: 0 }
      }
    });
  }
  dispose() {
    super.dispose();
    this.uniforms.dataTexture.value.dispose();
  }
}

var vertexShader$4 = "#version 300 es\n#define GLSLIFY 1\nin vec3 position;uniform mat4 modelViewMatrix;uniform mat4 projectionMatrix;uniform mat3 uvMatrix;in vec2 uv;out vec2 out_uv;out vec2 out_transformed_uv;void main(){gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.0);out_transformed_uv=(uvMatrix*vec3(uv,1)).xy;out_uv=uv;}"; // eslint-disable-line

var fragmentShader$5 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform sampler2D dataTexture;uniform vec2 resolution;uniform float sharpness;uniform float brightness;uniform float contrast;uniform float ww;uniform float wmin;uniform float invert;in vec2 out_uv;in vec2 out_transformed_uv;out vec4 out_color;const vec2 offsets[9]=vec2[](vec2(-1.0f,-1.0f),vec2(0.0f,-1.0f),vec2(1.0f,-1.0f),vec2(-1.0f,0.0f),vec2(0.0f,0.0f),vec2(1.0f,0.0f),vec2(-1.0f,1.0f),vec2(0.0f,1.0f),vec2(1.0f,1.0f));const float kernel[9]=float[](-1.0f,-1.0f,-1.0f,-1.0f,9.0f,-1.0f,-1.0f,-1.0f,-1.0f);void main(){float value=0.0f;for(int i=0;i<9;i++){value+=texture(dataTexture,out_transformed_uv+offsets[i]/resolution).r*kernel[i];}value=mix(texture(dataTexture,out_transformed_uv).r,value,sharpness);value=(value-wmin)/ww;value+=brightness;if(contrast>0.0f){value=(value-0.5f)/(1.0f-contrast)+0.5f;}else{value=(value-0.5f)*(1.0f+contrast)+0.5f;}value=mix(value,1.0f-value,invert);vec4 color=vec4(value,value,value,1.0f);out_color=color;}"; // eslint-disable-line

class DicomImageMaterial extends RawShaderMaterial {
  constructor(texture, width, height, uvMatrix) {
    const { ww, wc } = texture;
    super({
      vertexShader: vertexShader$4,
      fragmentShader: fragmentShader$5,
      uniforms: {
        dataTexture: { value: texture },
        uvMatrix: { value: uvMatrix },
        brightness: { value: 0 },
        contrast: { value: 0 },
        sharpness: { value: 0 },
        resolution: { value: new Vector2(width, height) },
        ww: { value: ww },
        wmin: { value: wc - ww / 2 },
        invert: { value: 0 }
      }
    });
  }
  dispose() {
    super.dispose();
    this.uniforms.dataTexture.value.dispose();
  }
}

class ImageEntity extends Entity {
  constructor(texture, width = texture.image.width, height = texture.image.height) {
    super(new Mesh(new PlaneGeometry()));
    this.object3d.geometry.computeBoundingBox();
    this.setMaterial(texture, width, height);
    this.setComponent(ViewportComponent);
  }
  setMaterial(texture, width = texture.image.width, height = texture.image.heigh) {
    this.object3d.material = texture instanceof DicomTexture ? new DicomImageMaterial(texture, width, height, new Matrix3()) : new RasterImageMaterial(texture, width, height, new Matrix3());
    this.setSize(width, height);
    this.object3d.material.needsUpdate = true;
  }
  setSize(width, height) {
    this.object3d.material.uniforms.resolution.value.x = width;
    this.object3d.material.uniforms.resolution.value.y = height;
    this.object3d.scale.set(this.width, this.height, 1);
    this.object3d.updateMatrix();
    this.object3d.material.uniforms.uvMatrix.value = new Matrix3();
  }
  useUVMatrix(x0, y0) {
    if (!this.texture.image) return;
    const { uvMatrix } = this.computeUvMatrix(this.texture, x0, y0);
    this.object3d.material.uniforms.uvMatrix.value = uvMatrix;
    this.texture.wrapS = RepeatWrapping;
    this.texture.wrapT = RepeatWrapping;
    this.texture.needsUpdate = true;
    this.object3d.material.needsUpdate = true;
  }
  invertColor() {
    this.object3d.material.uniforms.invert.value = 1;
  }
  straightColor() {
    this.object3d.material.uniforms.invert.value = 0;
  }
  toggleInversion() {
    this.object3d.material.uniforms.invert.value = Number(!this.object3d.material.uniforms.invert.value);
  }
  get width() {
    return this.object3d.material.uniforms.resolution.value.x;
  }
  get height() {
    return this.object3d.material.uniforms.resolution.value.y;
  }
  set texture(texture) {
    this.object3d.material.uniforms.dataTexture.value = texture;
  }
  get texture() {
    return this.object3d.material.uniforms.dataTexture.value;
  }
  set brightness(brightness) {
    this.object3d.material.uniforms.brightness.value = brightness;
  }
  set contrast(contrast) {
    this.object3d.material.uniforms.contrast.value = contrast;
  }
  set sharpness(sharpness) {
    this.object3d.material.uniforms.sharpness.value = sharpness;
  }
  get brightness() {
    return this.object3d.material.uniforms.brightness.value;
  }
  get contrast() {
    return this.object3d.material.uniforms.contrast.value;
  }
  get sharpness() {
    return this.object3d.material.uniforms.sharpness.value;
  }
  computeUvMatrix(texture, x0 = 0, y0 = 0, cropWidth = texture.image.width - x0, cropHeight = texture.image.height - y0, offset) {
    const actualWidth = texture.image.width;
    const actualHeight = texture.image.height;
    y0 = actualHeight - this.height - y0;
    if (offset) {
      x0 -= offset.x;
      y0 += offset.y;
    }
    const xFactor = 1 / this.width;
    const yFactor = 1 / this.height;
    const offsetX = offset ? offset.x : 0;
    const offsetY = offset ? this.height - (offset.y + cropHeight) : this.height - cropHeight;
    const w = cropWidth * xFactor;
    const h = cropHeight * yFactor;
    const minX = offsetX * xFactor;
    const minY = offsetY * yFactor;
    const cropMatrix = new Matrix3().set(1 / w, 0, -minX / w, 0, 1 / h, -minY / h, 0, 0, 1);
    const uvMatrix = new Matrix3().setUvTransform(x0 / actualWidth, y0 / actualHeight, this.width / actualWidth, this.height / actualHeight, 0, 0, 0);
    return { uvMatrix, cropMatrix };
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

class XRayImage extends ImageEntity {
  constructor(texture, width = texture.image.width, height = texture.image.height) {
    super(texture, width, height);
    this.object3d.geometry.translate(0.5, -0.5, 0);
  }
}

const coreEventNames = [
  "transformCameraStart",
  "transformCameraEnd",
  "transformCamera",
  "WillReunload",
  "resize",
  "add",
  "delete",
  "addLayer",
  "deleteLayer",
  "ActiveViewportWillChange",
  "ActiveViewportChanged",
  "hover",
  "unhover",
  "select",
  "deselect",
  "render",
  "transform",
  "transformStart",
  "transformEnd",
  "resourceAdded",
  "contextMenuOpen",
  "contextMenuClose",
  "modeChanged",
  "visibility",
  "viewportSchemeChanged",
  "geometryChanged",
  "materialChanged",
  "setRenderingStyle",
  "fatal",
  "makeScreenshot",
  "applicationProgress"
];

const MIRenderUIEventNames = [
  "brightnessContrastChanged",
  "sharpnessChanged",
  "hoverMask",
  "layout",
  "screenBrightnessContrastChanged",
  "screenSharpnessChanged",
  "invertColors",
  "straightColors",
  "toggleInversion"
];
const MIRenderEventNames = [
  ...coreEventNames,
  ...MIRenderUIEventNames
];
const MIRenderViewportEventNames = [
  ...viewportEventNames,
  "imageAdded",
  "applyFilter",
  "removeFilter",
  "lookAtBox"
];

class Mode {
  constructor(options) {
    if (Array.isArray(options)) {
      this.options = { tools: { toolList: options, include: true } };
    } else {
      this.options = options;
    }
    this.toolConstructors = /* @__PURE__ */ new Set();
    this.init = this.init.bind(this);
    this.name = "";
    this.enable = false;
  }
  setHostObject(hostObject, modeName) {
    this.hostObject = hostObject;
    this.name = modeName;
    const rootTools = this.hostObject.toolController.getTools();
    if (!rootTools) {
      console.error("Tools are not defined!");
      return;
    }
    if (!this.options) {
      Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
    } else {
      if (this.options.tools) {
        if (this.options.tools.include) {
          this.options.tools.toolList.forEach((item) => this.toolConstructors.add(item));
        } else {
          Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
          this.options.tools.toolList.forEach((toolName) => this.toolConstructors.delete(toolName));
        }
      } else {
        Array.from(rootTools.keys()).forEach((item) => this.toolConstructors.add(item));
      }
    }
  }
  init() {
    if (!this.hostObject) {
      console.error("Mode hostObject is not defined before init");
      return;
    }
    const prevMode = this.hostObject.toolController.getMode();
    if (prevMode && prevMode.enable) {
      prevMode.toolConstructors.forEach((toolName) => {
        if (!this.toolConstructors.has(toolName)) {
          this.hostObject.toolController.getTool(toolName)?.dispose();
        }
      });
      this.toolConstructors.forEach((toolName) => {
        if (!prevMode.toolConstructors.has(toolName)) {
          this.hostObject.toolController.getTool(toolName)?.init();
        }
      });
    } else {
      this.toolConstructors.forEach((toolName) => {
        this.hostObject.toolController.getTool(toolName)?.init();
      });
    }
    this.enable = true;
  }
  dispose() {
    if (!this.hostObject) {
      console.error("Mode hostObject is not defined while disposing");
      return;
    }
    this.toolConstructors.forEach((toolName) => {
      this.hostObject.toolController.getTool(toolName)?.dispose();
    });
    this.enable = false;
  }
  getName() {
    return this.name;
  }
  getToolNames() {
    return this.toolConstructors;
  }
}

const tuple = (...args) => args;

class Tool {
  constructor(viewport) {
    this.removeEventsCallbacks = [];
    this.hostObject = viewport;
    this.enable = false;
    this.onConstructor();
  }
  onConstructor() {
  }
  unsubscribeAll() {
    this.removeEventsCallbacks.forEach((cb) => cb());
    this.removeEventsCallbacks.splice(0);
  }
  init() {
    if (!this.enable && this.hostObject) {
      this.activate();
      this.enable = true;
    } else {
      console.error("tool is not initialized", this.enable, typeof this.hostObject === "undefined");
    }
  }
  dispose() {
    if (this.enable) {
      this.deactivate();
      this.enable = false;
    }
  }
  getHostObject() {
    return this.hostObject;
  }
  /**
      Subscribe to the event.
      Unsubscription will be done automatically on deactivate
  */
  on(type, callback, priority) {
    const removeCallback = App.Instance().addEventListener(type, callback, priority);
    this.removeEventsCallbacks?.push(removeCallback);
    return removeCallback;
  }
  /**
      Unsubscribe from the event.
  */
  off(type, callback, force) {
    return App.Instance().removeEventListener(type, callback, force);
  }
  deactivate() {
    this.unsubscribeAll();
  }
}
class ViewportTool extends Tool {
  constructor(viewport) {
    super(viewport);
  }
  getHostObject() {
    return this.hostObject;
  }
}
class ViewTool extends Tool {
  constructor(view) {
    super(view);
  }
  getHostObject() {
    return this.hostObject;
  }
}

class ActiveViewSwitcher2 extends ViewTool {
  constructor(view) {
    super(view);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onTransformStart = this.onTransformStart.bind(this);
    this.onTransformEnd = this.onTransformEnd.bind(this);
    this.onMouseOverWhileTransform = this.onMouseOverWhileTransform.bind(this);
  }
  activate() {
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.addListener("pointermove", this.onMouseMove, viewport.getDomElement());
    });
    App.Instance().addEventListener("transformStart", this.onTransformStart);
  }
  deactivate() {
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, viewport.getDomElement());
      App.Instance().mouseController.removeListener("pointerleave", this.onMouseLeave, viewport.getDomElement());
    });
    App.Instance().removeEventListener("transformStart", this.onTransformStart);
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
  }
  onTransformStart() {
    this.event = void 0;
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport.getDomElement());
      App.Instance().mouseController.addListener("pointerover", this.onMouseOverWhileTransform, viewport.getDomElement());
    });
    App.Instance().addEventListener("transformEnd", this.onTransformEnd);
  }
  onTransformEnd() {
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerover", this.onMouseOverWhileTransform, viewport.getDomElement());
    });
    App.Instance().removeEventListener("transformEnd", this.onTransformEnd);
    if (this.event) {
      this.onMouseOver(this.event);
    }
  }
  onMouseOverWhileTransform(event) {
    this.event = event;
  }
  onMouseOver(event) {
    for (let [name, viewport] of this.hostObject.viewports) {
      if (viewport.isEnabled() && viewport.getDomElement() === event.target) {
        App.Instance().setActiveView(this.hostObject);
        this.hostObject.setActiveViewport(name);
        this.hostObject.viewports.forEach((viewport2) => {
          App.Instance().mouseController.removeListener("pointerover", this.onMouseOver, viewport2.getDomElement());
          App.Instance().mouseController.addListener("pointerleave", this.onMouseLeave, viewport2.getDomElement());
        });
        return true;
      }
    }
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerleave", this.onMouseLeave, viewport.getDomElement());
      App.Instance().mouseController.addListener("pointerover", this.onMouseOver, viewport.getDomElement());
    });
    return false;
  }
  onMouseLeave(event) {
    for (let [name, viewport] of this.hostObject.viewports) {
      if (viewport.isEnabled() && viewport.getDomElement() === event.relatedTarget) {
        App.Instance().setActiveView(this.hostObject);
        this.hostObject.setActiveViewport(name);
        return true;
      }
    }
    this.hostObject.viewports.forEach((viewport) => {
      App.Instance().mouseController.removeListener("pointerleave", this.onMouseLeave, viewport.getDomElement());
      App.Instance().mouseController.addListener("pointerover", this.onMouseOver, viewport.getDomElement());
    });
    this.hostObject.setActiveViewport(void 0);
    return false;
  }
  onMouseMove(event) {
    if (this.onMouseOver(event)) {
      this.hostObject.viewports.forEach((viewport) => {
        App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, viewport.getDomElement());
      });
    }
  }
}

class UndoRedo extends ViewTool {
  constructor(view) {
    super(view);
    this.onKeyDown = this.onKeyDown.bind(this);
  }
  activate() {
    App.Instance().keyboardController.addListener("keydown", this.onKeyDown, "KeyZ");
  }
  deactivate() {
    App.Instance().keyboardController.removeListener("keydown", this.onKeyDown, "KeyZ");
  }
  onKeyDown(event) {
    if (event.ctrlKey || event.metaKey) {
      if (event.shiftKey) {
        App.Instance().undoStack.redo();
      } else {
        App.Instance().undoStack.undo();
      }
    }
  }
}

class Primitive extends Entity {
  constructor(object3D, viewport) {
    super(object3D);
    const viewportComponent = this.setComponent(ViewportComponent);
    viewport && viewportComponent.addViewport(viewport);
  }
  getViewportSize() {
    return this.getViewport()?.size;
  }
  getViewport() {
    const viewports = this.getComponent(ViewportComponent)?.viewports;
    return viewports && viewports.size ? [...viewports][0] : void 0;
  }
}

const _Observer = class _Observer {
  constructor(object, property) {
    this.listeners = /* @__PURE__ */ new Set();
    this.object = object;
    this.property = property;
  }
  static observe(object, property, cb) {
    let map = _Observer.observables.get(object);
    let observer = map?.get(property);
    if (!observer) {
      observer = new _Observer(object, property);
      observer.listeners.add(cb);
      observer.value = object[property];
      Object.defineProperty(object, property, {
        writable: true,
        configurable: true,
        enumerable: true
      });
      Object.defineProperty(object, property, {
        set(x) {
          observer.value = x;
          observer.listeners.forEach((listener) => listener(x));
        },
        get() {
          return observer.value;
        }
      });
      if (map) {
        map.set(property, observer);
      } else {
        _Observer.observables.set(object, /* @__PURE__ */ new Map([[property, observer]]));
      }
    }
    observer.listeners.add(cb);
    return () => _Observer.stopObserving(object, property, cb);
  }
  static stopObserving(object, property, cb) {
    const map = _Observer.observables.get(object);
    const observer = map?.get(property);
    if (!map || !observer) {
      return;
    }
    if (cb) {
      observer.listeners.delete(cb);
      if (observer.listeners.size) {
        return;
      }
    }
    Object.defineProperty(object, property, { value: observer.value });
    map.delete(property);
    if (!map.size) {
      _Observer.observables.delete(object);
    }
  }
};
_Observer.observables = /* @__PURE__ */ new Map();
let Observer = _Observer;

const vector$1 = new Vector2();
class LineLikeEntity extends Primitive {
  constructor(props, type, viewport) {
    let lineWidth;
    let color;
    let opacity;
    if (typeof props === "function") {
      super(new props(), type);
      lineWidth = 2;
      color = new Color(16777215);
      opacity = 1;
    } else {
      super(new type(), viewport);
      lineWidth = props.width;
      this.object3d.renderOrder = props.renderOrder || 0;
      color = new Color(props.color || 16777215);
      opacity = props.opacity ?? 1;
    }
    if (this.object3d instanceof Line2) {
      this.object3d.geometry = new LineGeometry();
      this.object3d.material = new LineMaterial({ linewidth: lineWidth, vertexColors: false, dashed: false, worldUnits: false });
      this.onResize = this.onResize.bind(this);
      this.updateResolution = this.updateResolution.bind(this);
      this.updateResolution();
      Observer.observe(this.object3d.layers, "mask", this.updateResolution);
    } else {
      this.object3d.geometry.setAttribute("position", new BufferAttribute(new Float32Array([]), 3));
    }
    this.object3d.material.color = color;
    this.object3d.material.opacity = opacity;
    if (opacity !== 1) {
      this.object3d.material.transparent = true;
    }
  }
  update() {
    if (this.object3d instanceof Line) {
      this.updateLine();
    } else {
      this.updateLine2();
    }
  }
  updateResolution() {
    const bitCount = this.object3d.layers.mask.toString(2).match(/1/g)?.length;
    const isEntityInOneViewport = this.object3d.layers.mask !== 1 && bitCount === 1;
    if (isEntityInOneViewport) {
      this.object3d.onAfterRender = () => {
      };
      this.onResize();
      const [firstViewport] = this.getComponent(ViewportComponent).viewports;
      firstViewport.addEventListener("resize", this.onResize);
    } else {
      const [firstViewport] = this.getComponent(ViewportComponent).viewports;
      firstViewport?.removeEventListener("resize", this.onResize);
      this.object3d.onAfterRender = () => {
        const size = this.getLayer()?.getView().getRenderer().getSize(vector$1);
        if (size) {
          this.object3d.material.resolution.set(size.x, size.y);
        }
      };
    }
  }
  onResize() {
    const [firstViewport] = this.getComponent(ViewportComponent).viewports;
    const { z, w } = firstViewport.size;
    this.object3d.material.resolution.set(z, w);
  }
  toJson() {
    return {
      color: this.object3d.material.color,
      width: this.object3d.material.linewidth
    };
  }
}

class Polyline extends LineLikeEntity {
  constructor(props, type, viewport) {
    if (Array.isArray(props)) {
      super(type, viewport);
      this.points = props;
    } else {
      super(props, type, viewport);
      this.points = props.points.map((point) => new Vector3(point.x, point.y, point.z));
    }
    this.mathLines = [];
    this.setMathLines();
  }
  getPoints() {
    return this.points;
  }
  setMathLines() {
    for (let i = 0; i < this.points.length - 1; i++) {
      this.mathLines.push(new Line3(this.points[i], this.points[i + 1]));
    }
  }
  updateLine() {
    const geometry = this.object3d.geometry.setAttribute("position", new BufferAttribute(new Float32Array(this.points.map((point) => point.toArray()).flat()), 3));
    geometry.getAttribute("position").needsUpdate = true;
  }
  updateLine2() {
    this.object3d.geometry.setPositions(this.points.map((point) => point.toArray()).flat());
  }
}

(() => {
  const tmpMatrix = new Matrix4();
  const tmpQuaternion = new Quaternion();
  return function makeRotationAroundPointAndAxis2(angle, point, axis, target) {
    return target.makeTranslation(point.x, point.y, point.z).multiply(tmpMatrix.makeRotationFromQuaternion(tmpQuaternion.setFromAxisAngle(axis, angle))).multiply(tmpMatrix.makeTranslation(-point.x, -point.y, -point.z));
  };
})();
const triangulate = (shapeVertices, indexOffset = 0, shapeHoles = []) => {
  const indices = [];
  const points = [];
  const faces = ShapeUtils.triangulateShape(shapeVertices, shapeHoles);
  for (let i = 0, l = faces.length; i < l; i++) {
    const face = faces[i];
    const a = face[0] + indexOffset;
    const b = face[1] + indexOffset;
    const c = face[2] + indexOffset;
    indices.push(a, b, c);
  }
  for (let i = 0, l = shapeHoles.length; i < l; i++) {
    shapeVertices.push(...shapeHoles[i]);
  }
  for (let i = 0, l = shapeVertices.length; i < l; i++) {
    points.push(shapeVertices[i].x, shapeVertices[i].y, 0);
  }
  return { points, indices };
};
const isPointInsidePolygon = (inPt, inPolygon) => {
  const polyLen = inPolygon.length;
  let inside = false;
  for (let p = polyLen - 1, q = 0; q < polyLen; p = q++) {
    let edgeLowPt = inPolygon[p];
    let edgeHighPt = inPolygon[q];
    let edgeDx = edgeHighPt.x - edgeLowPt.x;
    let edgeDy = edgeHighPt.y - edgeLowPt.y;
    if (Math.abs(edgeDy) > Number.EPSILON) {
      if (edgeDy < 0) {
        edgeLowPt = inPolygon[q];
        edgeDx = -edgeDx;
        edgeHighPt = inPolygon[p];
        edgeDy = -edgeDy;
      }
      if (inPt.y < edgeLowPt.y || inPt.y > edgeHighPt.y) continue;
      if (inPt.y === edgeLowPt.y) {
        if (inPt.x === edgeLowPt.x) return true;
      } else {
        const perpEdge = edgeDy * (inPt.x - edgeLowPt.x) - edgeDx * (inPt.y - edgeLowPt.y);
        if (perpEdge === 0) return true;
        if (perpEdge < 0) continue;
        inside = !inside;
      }
    } else {
      if (inPt.y !== edgeLowPt.y) continue;
      if (edgeHighPt.x <= inPt.x && inPt.x <= edgeLowPt.x || edgeLowPt.x <= inPt.x && inPt.x <= edgeHighPt.x) return true;
    }
  }
  return inside;
};

class Polygon extends Polyline {
  constructor(props, type, viewport) {
    if (Array.isArray(props)) {
      if (props[0].x !== props.at(-1)?.x || props[0].y !== props.at(-1)?.y) {
        props.push(new Vector3().copy(props[0]));
      }
      super(props, type, viewport);
      this.fill = new Mesh();
    } else {
      if (props.points[0].x !== props.points.at(-1)?.x || props.points[0].y !== props.points.at(-1)?.y) {
        props.points.push({ x: props.points[0].x, y: props.points[0].y, z: props.points[0].z });
      }
      super(props, type, viewport);
      this.fill = new Mesh();
      this.fill.material.color = props.fillColor ? new Color(props.fillColor) : new Color("red");
      this.fill.material.opacity = props.fillOpacity ? props.fillOpacity : 1;
    }
    this.fill.material.transparent = true;
    this.fill.layers = this.object3d.layers;
    this.object3d.add(this.fill);
  }
  update() {
    super.update();
    this.updateFillGeometry();
  }
  updateFillGeometry() {
    const { points, indices } = triangulate(this.points.map((point) => new Vector2(point.x, point.y)));
    const position = new Float32BufferAttribute(points, 3);
    this.fill.geometry.setAttribute("position", position).setIndex(indices);
    position.needsUpdate = true;
    this.fill.geometry.getIndex().needsUpdate = true;
  }
  showFill() {
    this.object3d.add(this.fill);
  }
  hideFill() {
    this.object3d.remove(this.fill);
  }
}

class MaskHighlighter extends Highlighter {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof Mask)) {
      throw new Error("Mask-only component");
    }
  }
  highlight() {
    this.entity.hideFill();
  }
  unHighlight() {
    this.entity.showFill();
  }
}

const invMatrix = new Matrix4();
const pointerPosition = new Vector3();
class MaskRaycastComponent extends Raycast {
  constructor(entity) {
    super(entity);
    if (!("getPoints" in entity)) {
      throw new Error("Polygon-like-only component");
    }
  }
  *intersect(raycaster) {
    pointerPosition.copy(raycaster.ray.origin);
    const activeViewport = App.Instance().getActiveView().getActiveViewport();
    if (!activeViewport?.camera.layers.test(this.entity.object3d.layers)) {
      return;
    }
    invMatrix.copy(this.entity.object3d.matrixWorld).invert();
    const isIntersect = isPointInsidePolygon(pointerPosition.applyMatrix4(invMatrix), this.entity.getPoints());
    if (isIntersect) {
      yield {
        raycast: this,
        intersections: [
          {
            point: pointerPosition.clone(),
            distance: pointerPosition.z,
            object: this.entity.object3d
          }
        ]
      };
    }
  }
}

class Mask extends Polygon {
  constructor(props, viewport) {
    super(props, Line2$1, viewport);
    let hoverDisabled;
    if (!Array.isArray(props)) {
      this.id = props.id;
      this.meta = props.meta;
      hoverDisabled = props.hoverDisabled;
    }
    this.object3d.material.transparent = true;
    this.setComponents(hoverDisabled);
    this.update();
  }
  setComponents(hoverDisabled) {
    if (hoverDisabled) return;
    this.setComponent(MaskRaycastComponent);
    this.setComponent(HoverComponent, new HoverComponent(this, new MaskHighlighter(this)));
  }
}

class BoxMaskHighlighter extends Highlighter {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof BoxMask)) {
      throw new Error("Mask-only component");
    }
  }
  highlight() {
    this.entity.object3d.scale.set(1.1, 1.1, 1);
  }
  unHighlight() {
    this.entity.object3d.scale.set(1, 1, 1);
  }
}

var vertexShader$3 = "#version 300 es\n#define GLSLIFY 1\nuniform vec2 size;uniform vec4 viewport;uniform mat4 modelViewMatrix;uniform mat4 projectionMatrix;in vec3 position;in vec2 uv;out vec2 coords;out float scale;void main(){scale=(projectionMatrix*vec4(0,1,0,1.0f)).y*viewport.w;coords=uv*vec2(size.xy);gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.0f);}"; // eslint-disable-line

var fragmentShader$4 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform vec4 color;uniform float radius;uniform vec2 size;uniform float lineWidth;in vec2 coords;in float scale;out vec4 out_color;float roundedBoxSDF(vec2 CenterPosition,vec2 Size,float Radius){return length(max(abs(CenterPosition)-Size+Radius,0.0f))-Radius;}void main(){float thickness=lineWidth/scale;vec2 size2=size.xy-thickness*2.0f;vec2 location=size.xy/2.0f;float edgeSoftness=1.0f/scale;float radius=min(radius/scale,min(size.x,size.y)/3.0f);float distance=roundedBoxSDF(location-coords.xy,size2/2.0f,radius);float smoothedAlpha=1.0f-smoothstep(-edgeSoftness,edgeSoftness,abs(distance)-thickness);out_color=mix(vec4(1.0f,1.0f,1.0f,0.0f),vec4(color.xyz,smoothedAlpha),smoothedAlpha);}"; // eslint-disable-line

class RoundCornerBBoxMaterial extends RawShaderMaterial {
  constructor(size, radius, color, lineWidth) {
    color = new Color(color);
    super({
      vertexShader: vertexShader$3,
      fragmentShader: fragmentShader$4,
      uniforms: {
        lineWidth: { value: lineWidth },
        size: { value: size },
        radius: { value: radius },
        viewport: { value: null },
        color: { value: new Vector4(color.r, color.g, color.b, 1) }
      }
    });
    this.toneMapped = false;
  }
}

class RoundCornerBBox extends Primitive {
  constructor(min, max, viewport) {
    let color;
    if (min instanceof Vector3) {
      super(new Mesh(), viewport);
      this.min = min;
      this.max = max;
      color = new Color(16777215);
      this.borderWidth = 20;
    } else {
      super(new Mesh(), max);
      this.min = new Vector3(min.min.x, min.min.y, min.min.z);
      this.max = new Vector3(min.max.x, min.max.y, min.max.z);
      color = new Color(min.color || 16777215);
      this.borderWidth = min.width ?? 20;
    }
    this.object3d.material = new RoundCornerBBoxMaterial(new Vector2(), 20, color, this.borderWidth);
    this.object3d.material.uniforms.viewport.value = this.getViewportSize();
    this.object3d.renderOrder = 20;
    this.object3d.material.transparent = true;
    this.update();
  }
  update() {
    const size = this.max.clone().sub(this.min);
    const center = this.max.clone().add(this.min).divideScalar(2);
    this.object3d.geometry = new PlaneGeometry(Math.abs(size.x), Math.abs(size.y)).translate(center.x, center.y, 0);
    this.object3d.geometry.getAttribute("position").needsUpdate = true;
    this.object3d.material.uniforms.size.value.set(Math.abs(size.x), Math.abs(size.y));
  }
  getPoints() {
    return this.points;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

class BoxMask extends RoundCornerBBox {
  constructor(props, viewport) {
    super(props, viewport);
    this.id = props.id;
    this.meta = props.meta;
    this.setComponents(props.hoverDisabled);
  }
  setComponents(hoverDisabled) {
    if (hoverDisabled) return;
    this.setComponent(MaskRaycastComponent);
    this.setComponent(HoverComponent, new HoverComponent(this, new BoxMaskHighlighter(this)));
  }
}

class HoverMask extends ViewTool {
  constructor(view) {
    super(view);
    this.onHover = this.onHover.bind(this);
    this.onUnhover = this.onUnhover.bind(this);
  }
  activate() {
    MIRender.addEventListener("hover", this.onHover);
    MIRender.addEventListener("unhover", this.onUnhover);
  }
  deactivate() {
    MIRender.removeEventListener("hover", this.onHover);
    MIRender.removeEventListener("unhover", this.onUnhover);
  }
  onHover(event) {
    if (event.entity instanceof Mask || event.entity instanceof BoxMask) {
      MIRender.dispatchEvent({ type: "hoverMask", localizationID: event.entity.id, meta: event.entity.meta });
    }
  }
  onUnhover(event) {
    if (event.entity instanceof Mask) {
      MIRender.dispatchEvent({ type: "hoverMask", localizationID: void 0 });
    }
  }
}

class DomRefStorage {
  constructor() {
    this.domRefsMap = /* @__PURE__ */ new Map();
    this.addView("main");
  }
  getRefs(viewName) {
    return this.domRefsMap.get(viewName);
  }
  getViewportRef(viewportName, viewName) {
    return this.domRefsMap.get(viewName)?.viewports.get(viewportName)?.proxy;
  }
  addView(viewName) {
    if (!this.domRefsMap.has(viewName)) {
      this.domRefsMap.set(viewName, {
        viewports: /* @__PURE__ */ new Map()
      });
    }
  }
  setViewRef(ref, viewName = "main") {
    const refs = this.domRefsMap.get(viewName);
    if (!refs) return;
    if (refs.view !== ref) {
      refs.view = ref;
    }
  }
  setCanvasRef(ref, viewName = "main") {
    const refs = this.domRefsMap.get(viewName);
    if (!refs) return;
    if (refs.canvas !== ref) {
      refs.canvas = ref;
    }
  }
  setViewportRef(ref, viewportName, viewName = "main", viewportType) {
    const refs = this.domRefsMap.get(viewName);
    if (!refs) return;
    const set = (target, prop, value) => {
      if (prop === "current") {
        let viewport;
        if (viewName == "main") {
          viewport = App.Instance().view.getViewport(viewportName);
        } else {
          viewport = App.Instance().views[viewName]?.getViewport(viewportName);
        }
        if (!value) {
          if (viewport && viewport.isEnabled()) {
            viewport.dispose();
          }
        }
        if (viewport && value) {
          if (!viewport.isEnabled()) {
            viewport.init();
          }
          if (target.current !== value) {
            viewport.setDomElement(value);
          }
          App.Instance().dispatchEvent({ type: "render" });
        }
      }
      return Reflect.set(target, prop, value);
    };
    const revocable = Proxy.revocable(ref, { set });
    if (!refs.viewports.get(viewportName)) {
      refs.viewports.set(viewportName, { object: ref, viewportType, proxy: revocable.proxy, revoke: revocable.revoke });
    }
  }
  deleteViewportRef(viewportName, viewName = "main") {
    this.domRefsMap.get(viewName)?.viewports.get(viewportName)?.revoke();
    this.domRefsMap.get(viewName)?.viewports.delete(viewportName);
  }
  isReady() {
    return this.domRefsMap.has("main") && [...this.domRefsMap.keys()].every((viewport) => {
      const refs = this.domRefsMap.get(viewport);
      return refs && refs.view.current && refs.canvas.current && refs.viewports.size && [...refs.viewports.values()].every((ref) => ref.object.current);
    });
  }
  getDomElements(viewName) {
    const refs = this.domRefsMap.get(viewName);
    if (!refs) {
      return;
    }
    const result = {
      view: refs.view.current,
      canvas: refs.canvas.current,
      viewports: {}
    };
    refs.viewports.forEach((ref, id) => {
      result.viewports[id] = { divElement: ref.object.current, viewportType: ref.viewportType };
    });
    return result;
  }
}

const HTMLElementTagNames = ["div"];
const createElement = (name, ...children) => {
  const element = document.createElement(name);
  children.forEach((children2) => {
    element.appendChild(children2);
  });
  return element;
};
const setAttributes = (element, attribute) => {
  let key;
  for (key in attribute) {
    if (key === "style") {
      for (let styleName in attribute.style) {
        const value = attribute.style[styleName];
        if (value) {
          element.style[styleName] = value;
        }
      }
    } else {
      const value = attribute[key];
      if (value) {
        element[key] = value;
      }
    }
  }
};
const HTML = {};
for (let tagName of HTMLElementTagNames) {
  HTML[tagName] = (style, ...children) => {
    let element;
    if (style instanceof HTMLElement) {
      element = createElement(tagName, style, ...children);
    } else {
      element = createElement(tagName, ...children);
      if (style) {
        setAttributes(element, style);
      }
    }
    return element;
  };
}

class RowGroup {
  constructor(layout) {
    this.rows = [];
    this.layout = layout;
  }
  update() {
    let y = 0;
    const rowsNumber = this.rows.length;
    this.rows.forEach((row, index) => {
      row.y = y;
      row.update();
      y -= row.computedHeight + (index !== rowsNumber - 1 ? this.layout.gap ? this.layout.gap : 0 : 0);
    });
    this.computedHeight = -y;
  }
  getCSSStyle() {
    let gridTemplateRows = "";
    this.rows.forEach((row) => gridTemplateRows += row.computedHeight / this.computedHeight * 100 + "% ");
    return {
      gridTemplateRows
    };
  }
}

class Col {
  constructor(props = {}) {
    this.width = props.width;
    this.x = 0;
    this.cells = [];
  }
  update() {
    this.computedWidth = 0;
    this.cells.forEach((cell) => {
      this.computedWidth = Math.max(this.computedWidth, cell.selfWidth);
    });
  }
}

class ColGroup {
  constructor(layout) {
    this.cols = [];
    this.layout = layout;
  }
  addCol() {
    const col = new Col();
    this.cols.push(col);
    return col;
  }
  update() {
    let x = 0;
    const colsNumber = this.cols.length;
    this.cols.forEach((col, index) => {
      col.x = x;
      col.update();
      x += col.computedWidth + (index !== colsNumber - 1 ? this.layout.gap ? this.layout.gap : 0 : 0);
    });
    this.computedWidth = x;
  }
  getCSSStyle() {
    let gridTemplateColumns = "";
    this.cols.forEach((col) => gridTemplateColumns += col.computedWidth / this.computedWidth * 100 + "% ");
    return {
      gridTemplateColumns
    };
  }
}

class LayoutNode {
  traverse(cb) {
    cb(this);
    this.getNodes()?.forEach((node) => {
      cb(node);
      node.traverse(cb);
    });
  }
}

const row = (style, ...cells) => {
  const row2 = new Row();
  if (style instanceof Cell) {
    row2.addCell(style);
  } else {
    row2.setProps(style);
  }
  cells.forEach((cell) => row2.addCell(cell));
  return row2;
};
class Row extends LayoutNode {
  constructor() {
    super();
    this.cells = [];
    this.y = 0;
  }
  getNodes() {
    return this.cells;
  }
  setProps(props) {
    let key;
    for (key in props) {
      this[key] = props[key];
    }
  }
  addCell(props) {
    let cell;
    let col;
    if (this.layout) {
      col = this.layout.getCol(this.cells.length);
      if (!col) {
        col = this.layout.colGroup.addCol();
      }
    }
    if (props instanceof Cell) {
      cell = props;
    } else {
      cell = new Cell();
      if (props) {
        cell.setProps(props);
      }
    }
    cell.row = this;
    col && col.cells.push(cell);
    this.cells.push(cell);
  }
  update() {
    this.computedHeight = this.height || this.minHeight || 0;
    this.cells.forEach((cell) => {
      cell.update();
      this.computedHeight = Math.max(this.computedHeight, cell.selfHeight);
    });
  }
  getStretchPower() {
    return this.cells.filter((cell) => cell.stretch).map((cell) => cell.selfWidth / this.computedWidth).reduce((a, b) => a + b);
  }
  get computedWidth() {
    return this.layout?.colGroup.computedWidth || this.cells.map((cell) => cell.computedWidth).reduce((a, b) => a + b);
  }
  getCell(...indices) {
    const index = indices.shift();
    if (index) {
      return this.cells[index].getCell(...indices);
    }
  }
  getBlocks(blokType, blocks) {
    return this.cells.map((cell) => cell.getBlocks(blokType, blocks)).flat();
  }
  getHTMLElement() {
    return this.cells.map((cell) => cell.getHTMLElement());
  }
  updateRefs() {
    return this.cells.map((cell) => cell.updateRefs());
  }
  clearRefs() {
    return this.cells.map((cell) => cell.clearRefs());
  }
}

const div$2 = HTML.div;
const layout = (style, ...rows) => {
  const layout2 = new Layout();
  if (style instanceof Row) {
    layout2.addRow(style);
  } else {
    layout2.setProps(style);
  }
  rows.forEach((row) => layout2.addRow(row));
  layout2.update();
  return layout2;
};
class Layout extends LayoutNode {
  constructor() {
    super();
    this.rowGroup = new RowGroup(this);
    this.colGroup = new ColGroup(this);
  }
  get computedHeight() {
    return this.rowGroup.computedHeight;
  }
  get computedWidth() {
    return this.colGroup.computedWidth;
  }
  getNodes() {
    return this.rowGroup.rows;
  }
  setProps(props) {
    let key;
    for (key in props) {
      this[key] = props[key];
    }
  }
  addRow(row = new Row(), index) {
    row.layout = this;
    if (index) {
      this.rowGroup.rows.splice(index, 0, row);
    } else {
      this.rowGroup.rows.push(row);
    }
    row.cells.forEach((cell, index2) => {
      let col = this.getCol(index2);
      if (!col) {
        col = this.colGroup.addCol();
      }
      col.cells.push(cell);
    });
  }
  getCol(index) {
    return this.colGroup.cols[index];
  }
  update() {
    this.rowGroup.update();
    this.colGroup.update();
  }
  getCell(...indices) {
    const index = indices.shift();
    if (index) {
      return this.rowGroup.rows[index].getCell(...indices);
    }
  }
  updateBlocks(blokType, blocks) {
    const block = this.rowGroup.rows.map((row) => row.getBlocks(blokType, blocks)).flat();
    this.blocks = block;
    return block;
  }
  updateHTMLElement() {
    if (!this.sheet) {
      this.sheet = new CSSStyleSheet();
    }
    const aspectRatio = this.computedWidth / this.computedHeight;
    let style = {
      zIndex: this.zIndex?.toString(),
      backgroundColor: this.backgroundColor,
      alignItems: this.alignItems,
      justifyItems: this.justifyItems,
      gap: this.gap ? `${this.gap / this.computedHeight * 100}% ${this.gap / this.computedWidth * 100}%` : void 0,
      aspectRatio: aspectRatio.toString(),
      display: "grid",
      ...this.colGroup.getCSSStyle(),
      ...this.rowGroup.getCSSStyle()
    };
    if (this.id) {
      const contentQueries = `@container (max-aspect-ratio: ${aspectRatio}) {#${this.id}{width: 100%; height: auto;}} 
      @container (min-aspect-ratio: ${aspectRatio}) {#${this.id}{height: 100%; width: auto;}}`;
      const cssRule = this.cssRule ? contentQueries + this.cssRule : contentQueries;
      this.sheet.replaceSync(cssRule);
    } else {
      style.width = this.computedWidth > this.computedHeight ? "100%" : void 0;
      style.height = this.computedHeight > this.computedWidth ? "100%" : void 0;
      this.cssRule && this.sheet.replaceSync(this.cssRule);
    }
    const res = div$2(
      { style, id: this.id, className: this.className },
      ...this.rowGroup.rows.map((row) => row.getHTMLElement()).flat()
    );
    if (this.element) {
      const parent = this.element.parentElement;
      this.element.remove();
      parent?.appendChild(res);
    }
    this.element = res;
    return res;
  }
  updateRefs() {
    if (this.ref) {
      this.ref.current = this.element;
      this.ref.blocks = this.blocks;
      this.ref.layout = this;
    }
    this.rowGroup.rows.map((row) => row.updateRefs());
  }
  clearRefs() {
    if (this.ref) {
      this.ref.current = null;
      this.ref.blocks = void 0;
      this.ref.layout = void 0;
    }
    this.rowGroup.rows.map((row) => row.clearRefs());
  }
  insertStyleSheet() {
    this.traverse((node) => {
      if (node instanceof Layout) {
        document.adoptedStyleSheets.push(node.sheet);
      }
    });
  }
  removeStyleSheet() {
    this.traverse((node) => {
      if (node instanceof Layout) {
        const index = document.adoptedStyleSheets.indexOf(node.sheet);
        if (index >= 0) {
          document.adoptedStyleSheets.splice(index, 1);
        }
      }
    });
  }
  mountElement(parent) {
    if (!this.element) {
      this.updateHTMLElement();
    }
    this.updateRefs();
    parent.appendChild(this.element);
    this.insertStyleSheet();
  }
  unmountElement() {
    if (this.element) {
      this.element.remove();
      this.clearRefs();
      this.removeStyleSheet();
    }
  }
}

const div$1 = HTML.div;
const isCellContent = (object) => {
  return object instanceof Layout || object.computedHeight && object.computedWidth;
};
const cell = (style, content) => {
  const cell2 = new Cell();
  if (style && isCellContent(style)) {
    cell2.content = style;
  } else {
    style && cell2.setProps(style);
    cell2.content = content;
  }
  return cell2;
};
class Cell extends LayoutNode {
  constructor() {
    super();
  }
  static sizeUtil(width, height, rotation) {
    const hw = (width || 0) / 2;
    const hh = (height || 0) / 2;
    const bBox = new Box3(new Vector3(-hw, -hh, 0), new Vector3(hw, hh, 0));
    const rotMatrix = new Matrix4().makeRotationZ(rotation);
    bBox.applyMatrix4(rotMatrix);
    const size = bBox.getSize(new Vector3());
    return { bBox, width: size.x, height: size.y };
  }
  getNodes() {
    return this.content ? [this.content] : void 0;
  }
  setProps(props) {
    let key;
    for (key in props) {
      this[key] = props[key];
    }
    if (this.rotation) {
      this.bBox = Cell.sizeUtil(this.width || 0, this.height || 0, this.rotation).bBox;
    } else {
      this.bBox = void 0;
    }
  }
  getCol() {
    if (!this.row) {
      return;
    }
    const index = this.row.cells.indexOf(this);
    return this.row.layout?.colGroup.cols[index];
  }
  get x() {
    let result = this.getCol()?.x || 0;
    if (!result && this.row) {
      const index = this.row.cells.indexOf(this);
      result = 0;
      for (let i = 0; i < index; i++) {
        result += this.row.cells[i].selfHeight;
      }
    }
    const justifySelf = this.justifySelf || this.row?.layout?.justifyItems;
    if (justifySelf === "end") {
      result += this.computedWidth - this.selfWidth;
    } else if (justifySelf === "center") {
      result += (this.computedWidth - this.selfWidth) / 2;
    }
    return result;
  }
  get y() {
    let result = this.row?.y || 0;
    const alignSelf = this.alignSelf || this.row?.layout?.alignItems;
    if (alignSelf === "end") {
      result -= this.computedHeight - this.selfHeight;
    } else if (alignSelf === "center") {
      result -= (this.computedHeight - this.selfHeight) / 2;
    }
    return result;
  }
  get computedHeight() {
    return this.row?.computedHeight || this.selfHeight;
  }
  get computedWidth() {
    return this.getCol()?.computedWidth || this.selfWidth;
  }
  get selfHeight() {
    const scale = this.scale || 1;
    let height = this.height || 0;
    if (this.bBox) {
      height = this.bBox.max.y - this.bBox.min.y;
    }
    return Math.max(this.content?.computedHeight || 0, height * scale);
  }
  get selfWidth() {
    const scale = this.scale || 1;
    let width = this.width || 0;
    if (this.bBox) {
      width = this.bBox.max.x - this.bBox.min.x;
    }
    return Math.max(this.content?.computedWidth || 0, width * scale);
  }
  update() {
    this.content instanceof Layout && this.content.update();
  }
  getCell(...indices) {
    indices.shift();
    if (this.content && this.content.getCell) {
      return this.content.getCell(...indices);
    }
    return this;
  }
  getBlocks(blokType, blocks) {
    if (this.content && this.content.updateBlocks) {
      const contentBlocks = this.content.updateBlocks(blokType, blocks);
      contentBlocks.forEach((block2) => block2.object3d.position.add(new Vector3(this.x, this.y, 0)));
      return contentBlocks;
    }
    let block;
    block = this.id && blocks?.get(this.id) || this.block;
    if (block) {
      this.block = block;
      this.block.width = this.selfWidth;
      this.block.height = this.selfHeight;
      this.block.object3d.position.set(this.x, this.y, 0);
      this.scale && this.block.object3d.scale.set(this.scale, this.scale, 1);
      this.block.object3d.updateMatrixWorld();
      return block;
    }
    block = new blokType([], this.selfWidth, this.selfHeight, this.id || "", this.backgroundColor || "red");
    block.object3d.position.set(this.x, this.y, 0);
    this.scale && block.object3d.scale.set(this.scale, this.scale, 1);
    if (this.rotation) {
      const scale = this.scale || 1;
      const center1 = new Vector3(this.x + (this.width || 0) * scale / 2, this.y - (this.height || 0) * scale / 2, 0);
      const center2 = new Vector3(this.x + this.selfWidth / 2, this.y - this.selfHeight / 2, 0);
      const m = new Matrix4().makeTranslation(center2.x, center2.y, 0).multiply(new Matrix4().makeRotationZ(this.rotation)).multiply(new Matrix4().makeTranslation(-center1.x, -center1.y, 0));
      block.object3d.updateMatrix();
      block.object3d.applyMatrix4(m);
    }
    this.block = block;
    return block;
  }
  getHTMLElement() {
    const style = {
      alignSelf: this.alignSelf,
      justifySelf: this.justifySelf,
      backgroundColor: this.backgroundColor || this.row?.backgroundColor,
      width: this.selfWidth / this.computedWidth * 100 + "%",
      height: this.selfHeight / this.computedHeight * 100 + "%"
    };
    const attributes = { id: this.id, style, className: this.className };
    const children = this.content?.updateHTMLElement?.();
    const element = children ? div$1(attributes, ...Array.isArray(children) ? children : [children]) : div$1(attributes);
    this.element = element;
    return element;
  }
  updateRefs() {
    if (this.ref) {
      this.ref.current = this.element;
      this.ref.block = this.block;
      this.ref.cell = this;
    }
    this.content?.updateRefs();
  }
  clearRefs() {
    if (this.ref) {
      this.ref.current = null;
      this.ref.block = void 0;
      this.ref.cell = void 0;
    }
    this.content?.clearRefs();
  }
}

const MIRenderMatrixPartitions = ["UpperLeft", "UpperMiddle", "UpperRight", "MiddleLeft", "MiddleMiddle", "MiddleRight", "LowerLeft", "LowerMiddle", "LowerRight"];
const getImageMatrix = (groupedImageProps) => {
  const images = [];
  for (let i = 0; i < 3; i++) {
    const row2 = [];
    images.push(row2);
    for (let j = 0; j < 3; j++) {
      const partitions = MIRenderMatrixPartitions[i * 3 + j];
      row2.push(groupedImageProps[partitions].map((item) => ({ ...item })));
    }
  }
  return images;
};
const cssRule = `.viewport { border-radius: 8px } .viewport:hover { box-shadow: 0 0 0 2px var(--purpleflat) }`;
const gapSize = (props) => {
  let min = Infinity;
  props.flat(2).forEach((item) => {
    const minSize = Math.min(item.originalSize.width, item.originalSize.height);
    if (minSize < min) {
      min = minSize;
    }
  });
  return Math.min(60, min * 0.1);
};
const FMXMatrixLayout = (props, domRefStorage, specialImageProps, layoutOptions) => {
  const gap = gapSize(props);
  return layout(
    { gap: layoutOptions?.gap ?? gap, zIndex: 2, cssRule, id: "matrix" },
    ...props.map((rowData, rowIndex, rows) => row(
      ...rowData.map((imageBlock, imageBlockIndex, cells) => cell(
        {
          alignSelf: rowIndex === 0 ? "end" : rowIndex === rows.length - 1 ? "start" : "center",
          justifySelf: imageBlockIndex === 0 ? "end" : imageBlockIndex === cells.length - 1 ? "start" : "center"
        },
        layout(
          { alignItems: rowIndex === 0 ? "end" : rowIndex === rows.length - 1 ? "start" : "center", gap: layoutOptions?.contentGap },
          row(
            ...imageBlock.map((props2) => cell({
              id: props2.ImageID,
              className: "viewport",
              width: props2.originalSize.width,
              height: props2.originalSize.height,
              scale: specialImageProps?.[props2.ImageID]?.scale,
              rotation: props2.OrientationAngle * Math.PI / 180,
              ref: domRefStorage?.getViewportRef(props2.ImageID, "main"),
              stretch: props2.stretch
            }))
          )
        )
      ))
    ))
  );
};
const MIRenderCropLayout = (props, api) => {
  return layout(
    { gap: 30, zIndex: 2, id: "crop" },
    row(
      ...props.map((cellProp) => cell({ ...cellProp, ref: api.getViewportRef(cellProp.id, "main") }))
    )
  );
};

const div = HTML.div;
class FMXLayoutModes {
  constructor(domRefStorage) {
    this.disposeCurrentMode = () => {
    };
    this.domRefStorage = domRefStorage;
  }
  init(imageMatrix, specialImages, matrixLayoutOptions) {
    this.imageMatrix = imageMatrix;
    this.fullScreenDomElement = div({ style: { width: "100%", height: "100%", zIndex: "3" } });
    this.domRefStorage.setViewportRef({ current: this.fullScreenDomElement }, "matrix");
    const images = imageMatrix.flat(2);
    images.forEach((imageProps) => {
      const viewportName = imageProps.ImageID;
      this.domRefStorage.setViewportRef({ current: null }, viewportName, "main", specialImages?.[viewportName]?.viewportType);
    });
    if (specialImages && Object.keys(specialImages).some((key) => !images.find((image) => image.ImageID === key))) {
      console.error("special image property defined for non-existent image");
    }
    this.matrixLayout = FMXMatrixLayout(imageMatrix, this.domRefStorage, specialImages, matrixLayoutOptions);
  }
  updateMatrixLayout(imageMatrix, specialImages, matrixLayoutOptions) {
    const currentImageMatrix = this.imageMatrix.flat(1);
    const newImageMatrix = imageMatrix.flat(1);
    if (currentImageMatrix.length !== newImageMatrix.length) {
      throw new Error("new matrix shape is incompatible with current matrix shape");
    }
    const needsUpdate = newImageMatrix.some((group, index) => {
      const currentGroup = currentImageMatrix[index];
      return currentGroup.length !== group.length || group.some((imageProps, index2) => imageProps.ImageID !== currentGroup[index2].ImageID);
    });
    if (!needsUpdate) {
      return false;
    }
    const newMatrixLayout = FMXMatrixLayout(imageMatrix, this.domRefStorage, specialImages, matrixLayoutOptions);
    if (this.mode === "matrix") {
      this.disposeCurrentMode();
      this.matrixLayout = newMatrixLayout;
      this.matrix();
    } else {
      this.matrixLayout = newMatrixLayout;
    }
    this.imageMatrix = imageMatrix;
    return true;
  }
  fullScreenMatrix() {
    if (this.mode === "fullScreenMatrix") {
      return;
    }
    this.disposeCurrentMode();
    MIRender.view.getContainer().appendChild(this.fullScreenDomElement);
    const ref = this.domRefStorage.getViewportRef("matrix", "main");
    if (ref) {
      ref.current = this.fullScreenDomElement;
    }
    const viewport = MIRender.view.getViewport("matrix");
    this.zoomFit(viewport);
    this.disposeCurrentMode = () => {
      this.fullScreenDomElement.remove();
      viewport.dispose();
      this.mode = void 0;
      this.disposeCurrentMode = () => {
      };
    };
    this.mode = "fullScreenMatrix";
    MIRender.eventIsListened("layout") && MIRender.dispatchEvent({ type: "layout", mode: "fullScreenMatrix" });
  }
  zoomFit(viewportName) {
    let viewport;
    if (typeof viewportName === "string") {
      viewport = MIRender.view.getViewport(viewportName);
      if (!viewport) {
        console.error(`couldn't fit image to viewport. No viewport with name ${viewportName}`);
      }
    } else {
      viewport = viewportName;
    }
    const camera = viewport.cameraController.getOrthographicCamera();
    const width = this.matrixLayout.computedWidth;
    const height = this.matrixLayout.computedHeight;
    viewport.camera.position.set(width / 2, -height / 2, 0);
    const updateZoom = () => {
      const cameraWidth = camera.right - camera.left;
      const cameraHeight = camera.top - camera.bottom;
      camera.zoom = Math.min(cameraHeight / height, cameraWidth / width);
      camera.updateMatrix();
      camera.updateProjectionMatrix();
      MIRender.dispatchEvent({ type: "transformCamera", viewport });
    };
    viewport.addEventListenerOnce("resize", updateZoom);
    updateZoom();
  }
  matrix() {
    if (this.mode === "matrix") {
      return;
    }
    this.disposeCurrentMode();
    const parent = this.domRefStorage.getRefs("main")?.view.current;
    if (parent) {
      this.matrixLayout.mountElement(parent);
    }
    const matrixLayout = this.matrixLayout;
    this.disposeCurrentMode = () => {
      matrixLayout.unmountElement();
      this.mode = void 0;
      this.disposeCurrentMode = () => {
      };
    };
    this.mode = "matrix";
    MIRender.eventIsListened("layout") && MIRender.dispatchEvent({ type: "layout", mode: "matrix" });
  }
  focus(id) {
    if (this.focusID === id) {
      return;
    }
    if (this.mode !== "focus") {
      this.disposeCurrentMode();
    } else if (this.focusID) {
      const prevRef = this.domRefStorage.getViewportRef(this.focusID, "main");
      if (prevRef) {
        prevRef.current = null;
      }
    }
    const ref = this.domRefStorage.getViewportRef(id, "main");
    if (!ref) {
      return;
    }
    if (!this.fullScreenDomElement.parentElement) {
      MIRender.view.getContainer().appendChild(this.fullScreenDomElement);
    }
    ref.current = this.fullScreenDomElement;
    this.disposeCurrentMode = () => {
      this.fullScreenDomElement.remove();
      ref.current = null;
      this.focusID = void 0;
      this.mode = void 0;
      this.disposeCurrentMode = () => {
      };
    };
    this.mode = "focus";
    this.focusID = id;
    MIRender.eventIsListened("layout") && MIRender.dispatchEvent({ type: "layout", mode: "focus", id });
  }
  crop(toothID) {
    if (this.cropID === toothID) {
      return;
    }
    this.disposeCurrentMode();
    const tooth = ImageController$1.teeth.get(toothID);
    if (!tooth) {
      console.error(`tooth with id ${toothID} is not defined`);
      return;
    }
    const props = tooth.Localizations.map((localization) => {
      const box = localization.BBox;
      if (!box) {
        console.error("localization bounding box is nor defined");
        return;
      }
      const imageContainer = ImageController$1.imageContainers.get(localization.TargetAssetID);
      if (!imageContainer) {
        console.error(`image with Asset ID = ${localization.TargetAssetID} not found`);
        return;
      }
      this.matrixLayout.element.remove();
      const newBox = { min: { x: box.X?.Min || 0, y: -(box.Y?.Max || 0) }, max: { x: box.X?.Max || 0, y: -(box.Y?.Min || 0) } };
      const width = newBox.max.x - newBox.min.x;
      const height = newBox.max.y - newBox.min.y;
      const rotation = imageContainer.object3d.rotation.z;
      return { width, height, rotation, id: imageContainer.id, box: newBox };
    }).filter((x) => x);
    this.cropLayout = MIRenderCropLayout(props, this.domRefStorage);
    const parent = this.domRefStorage.getRefs("main")?.view.current;
    if (parent) {
      this.cropLayout.mountElement(parent);
    }
    props.forEach(
      (item) => MIRender.view.getViewport(item.id)?.dispatchEvent({ type: "lookAtBox", box: item.box })
    );
    this.disposeCurrentMode = () => {
      this.cropLayout.unmountElement();
      this.cropID = void 0;
      this.mode = void 0;
      this.disposeCurrentMode = () => {
      };
    };
    this.mode = "crop";
    this.cropID = toothID;
    MIRender.eventIsListened("layout") && MIRender.dispatchEvent({ type: "layout", mode: "focus", id: toothID });
  }
}

class FMXLayoutController {
  constructor() {
    this.domRefStorage = new DomRefStorage();
    this.layoutModes = new FMXLayoutModes(this.domRefStorage);
  }
}
var FMXLayoutController$1 = new FMXLayoutController();

class StraightLineEntity extends LineLikeEntity {
  constructor(props, end, type, viewport) {
    let color;
    if (props instanceof Vector3) {
      super(type, viewport);
      this.start = props;
      this.end = end;
      color = new Color(16777215);
    } else {
      super(props, end, type);
      this.start = new Vector3(props.start.x, props.start.y, props.start.z);
      this.end = new Vector3(props.end.x, props.end.y, props.end.z);
      color = new Color(props.color || 16777215);
    }
    this.object3d.material.color = color;
  }
  update() {
    if (this.object3d instanceof Line) {
      this.updateLine();
    } else {
      this.updateLine2();
    }
  }
  updateLine() {
    const positions = this.object3d.geometry.getAttribute("position");
    positions.array.set([...this.start.toArray(), ...this.end.toArray()]);
    positions.needsUpdate = true;
  }
  updateLine2() {
    this.object3d.geometry.setPositions([...this.start.toArray(), ...this.end.toArray()]);
  }
}

class TextEntity extends Primitive {
  constructor(props = "", viewport) {
    if (props instanceof Text) {
      super(props, viewport);
    } else {
      super(new Text(), viewport);
    }
    let counter = 0;
    this.object3d.addEventListener("synccomplete", () => {
      counter--;
      if (counter === 0) {
        this.onSyncComplete.forEach((callback) => callback());
      }
    });
    this.object3d.addEventListener("syncstart", () => {
      counter++;
      this.onSyncStart.forEach((callback) => callback());
    });
    this.onSyncStart = /* @__PURE__ */ new Set();
    this.onSyncComplete = /* @__PURE__ */ new Set();
    if (typeof props === "string") {
      this.setProps({ text: props });
    } else if (!(props instanceof Text)) {
      this.setProps(props);
    }
  }
  setProps(props, callback) {
    let key;
    for (key in props) {
      this.object3d[key] = props[key];
    }
    this.object3d.sync(() => {
      callback && callback();
      App.Instance().dispatchEvent({ type: "render" });
    });
  }
  getSize() {
    const bBox = this.object3d.geometry.boundingBox;
    return {
      width: bBox.max.x - bBox.min.x,
      height: bBox.max.y - bBox.min.y
    };
  }
  toJson() {
    return {
      text: this.object3d.text,
      fontSize: this.object3d.fontSize,
      maxWidth: this.object3d.maxWidth,
      color: this.object3d.color,
      font: this.object3d.font,
      outlineBlur: this.object3d.outlineBlur,
      outlineOpacity: this.object3d.outlineOpacity,
      outlineColor: this.object3d.outlineColor,
      outlineWidth: this.object3d.outlineWidth,
      anchorX: this.object3d.anchorX,
      anchorY: this.object3d.anchorY,
      whiteSpace: this.object3d.whiteSpace
    };
  }
}

var fragmentShader$3 = "#define GLSLIFY 1\nposition=uTroikaOrient*position;float factor=viewport.w/600.0;float zoom=1.0/(projectionMatrix[1][1]*300.0)/factor;position.x*=zoom;position.y*=zoom;position.y-=0.1*zoom;"; // eslint-disable-line

const textMaterialZoomless = (base, viewport) => {
  base.onBeforeCompile = (shader) => {
    shader.uniforms.viewport = { value: viewport };
    shader.vertexShader = "uniform vec4 viewport;\n" + shader.vertexShader;
    shader.vertexShader = shader.vertexShader.replace("position = uTroikaOrient * position;", fragmentShader$3);
  };
  return base;
};

class TextEntityZoomless extends TextEntity {
  constructor(props = "", viewport) {
    super(props, viewport);
    this.setBehavior();
    this.setBehavior = this.setBehavior.bind(this);
    Observer.observe(this.object3d.layers, "mask", this.setBehavior);
  }
  setBehavior() {
    const bitCount = this.object3d.layers.mask.toString(2).match(/1/g)?.length;
    const isEntityInOneViewport = this.object3d.layers.mask !== 1 && bitCount === 1;
    if (isEntityInOneViewport) {
      const [firstViewport] = this.getComponent(ViewportComponent).viewports;
      this.object3d.material = new MeshBasicMaterial();
      this.object3d.material = textMaterialZoomless(this.object3d.material, firstViewport.size);
    } else {
      const vector = new Vector4();
      this.object3d.material = new MeshBasicMaterial();
      this.object3d.material = textMaterialZoomless(this.object3d.material, vector);
      this.object3d.onBeforeRender = (renderer, scene, camera, geometry, material, group) => {
        this.object3d.onBeforeRender(renderer, scene, camera, geometry, material, group);
        this.getLayer()?.getView().getRenderer().getViewport(vector);
      };
    }
  }
}

class LeadLine extends StraightLineEntity {
  constructor(start, end, type, text, viewport) {
    if (start instanceof Vector3) {
      super(start, end, type, viewport);
      this.text = new TextEntityZoomless(text, viewport);
      this.setAlignment("center");
      this.textIndent = 5;
    } else {
      super(start, end, type);
      this.text = new TextEntityZoomless(start.textProps, type);
      this.setAlignment(start?.textAliment || "center");
      this.textIndent = start?.textIndent || 5;
    }
    this.add(this.text);
  }
  update() {
    super.update();
    this.align();
  }
  setAlignment(textAliment) {
    this.textAlignment = textAliment;
    this.align = this.textAlignment === "left" ? this.alignLeft : this.textAlignment === "right" ? this.alignRight : this.alignCenter;
    this.align();
  }
  alignLeft() {
    this.text.object3d.position.set(this.start.x + this.textIndent, this.start.y + 50, 0);
  }
  alignRight() {
    this.text.object3d.position.set(this.end.x + this.textIndent, this.end.y + 50, 0);
  }
  alignCenter() {
    this.text.object3d.position.copy(this.start.clone().add(this.end).divideScalar(2));
  }
}

class Handle extends Primitive {
  constructor(point, viewport) {
    super(new Mesh(), viewport);
    this.point = point;
    this.object3d.position.copy(point);
  }
  bind() {
    Observer.observe(this.object3d.position, "x", () => this.point.x = this.object3d.position.x);
    Observer.observe(this.object3d.position, "y", () => this.point.y = this.object3d.position.y);
    Observer.observe(this.object3d.position, "z", () => this.point.z = this.object3d.position.z);
  }
  unBind() {
    Observer.stopObserving(this.object3d.position, "x");
    Observer.stopObserving(this.object3d.position, "y");
    Observer.stopObserving(this.object3d.position, "z");
  }
}

var vertexShader$2 = "#version 300 es\n#define GLSLIFY 1\nuniform mat4 projectionMatrix;uniform mat4 modelViewMatrix;uniform vec4 viewport;in vec3 position;in vec2 uv;out vec2 out_uv;void main(){float factor=viewport.w/600.0f;float zoom=1.0f/(projectionMatrix[1][1]*300.0f)/factor;out_uv=uv-0.5f;vec4 mvPosition=projectionMatrix*modelViewMatrix*(vec4(position,1.0f)*vec4(zoom,zoom,1.0f,1.0f));gl_Position=mvPosition;}"; // eslint-disable-line

var fragmentShader$2 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform vec4 color;uniform vec4 outlineColor;in vec2 out_uv;out vec4 out_color;void main(){float r=0.5f;float outlineRadius=0.4f;float smoothness=0.01f;float distFromCenter=length(out_uv);if(distFromCenter>0.5f){discard;}float col=smoothstep(outlineRadius+smoothness,outlineRadius,distFromCenter);out_color=mix(color,outlineColor,col);}"; // eslint-disable-line

class RoundHandleMaterial extends RawShaderMaterial {
  constructor(color, viewport = new Vector4(), pixelSize = 1) {
    color = new Color(color);
    super({
      vertexShader: vertexShader$2,
      fragmentShader: fragmentShader$2,
      uniforms: {
        color: { value: new Vector4(color.r, color.g, color.b, 1) },
        outlineColor: { value: new Vector4(color.r, color.g, color.b, 1) },
        viewport: { value: viewport },
        pixelSize: { value: pixelSize }
      }
    });
    this.toneMapped = false;
  }
}

class RoundHandle extends Handle {
  constructor(point, viewport, props = { size: 10 }) {
    super(point, viewport);
    this.object3d.geometry = new PlaneGeometry();
    this.object3d.material = new RoundHandleMaterial(props.color || "red", this.getViewportSize());
    this.size = props.size;
  }
  set size(size) {
    this.object3d.scale.set(size, size, 1);
  }
  get size() {
    return this.object3d.scale.x;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

var vertexShader$1 = "#version 300 es\n#define GLSLIFY 1\nuniform mat4 projectionMatrix;uniform mat4 modelViewMatrix;uniform vec4 viewport;uniform vec3 size;in vec3 position;in vec2 uv;out vec2 coords;void main(){coords=uv*size.xy;float factor=viewport.w/600.0f;float zoom=1.0f/(projectionMatrix[1][1]*300.0f)/factor;vec4 mvPosition=projectionMatrix*modelViewMatrix*(vec4(position,1.0f)*vec4(zoom,zoom,1.0f,1.0f));gl_Position=mvPosition;}"; // eslint-disable-line

var fragmentShader$1 = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform vec4 color;uniform float radius;uniform vec3 size;in vec2 coords;out vec4 out_color;float sdRoundedBox(in vec2 p,in vec2 b,in vec4 r){r.xy=(p.x>0.0f)? r.xy : r.zw;r.x=(p.y>0.0f)? r.x : r.y;vec2 q=abs(p)-b+r.x;return min(max(q.x,q.y),0.0f)+length(max(q,0.0f))-r.x;}void main(){vec2 p=(2.0f*coords-size.xy)/size.y;vec2 b=vec2(size.x/size.y,1.0f);float box=sdRoundedBox(p,b,vec4(2.0f*radius/size.y));if(box>0.0f){discard;}out_color=color;}"; // eslint-disable-line

class RectangleHandleMaterial extends RawShaderMaterial {
  constructor(size, radius, color, viewport = new Vector4()) {
    color = new Color(color);
    super({
      vertexShader: vertexShader$1,
      fragmentShader: fragmentShader$1,
      uniforms: {
        size: { value: size },
        radius: { value: radius },
        color: { value: new Vector4(color.r, color.g, color.b, 1) },
        viewport: { value: viewport }
      }
    });
    this.toneMapped = false;
  }
}

class RectangleHandle extends Handle {
  constructor(point, viewport, props = { size: 1, width: 40, height: 18, radius: 9 }) {
    super(point, viewport);
    this.object3d.geometry = new PlaneGeometry();
    this.width = props.width;
    this.height = props.height;
    this.object3d.scale.set(this.width * props.size, this.height * props.size, 1);
    this.object3d.material = new RectangleHandleMaterial(this.object3d.scale, props.radius, props.color || "red", this.getViewportSize());
  }
  set size(size) {
    this.object3d.scale.multiplyScalar(size);
  }
  get size() {
    return this.object3d.scale.x / this.width;
  }
  set radius(radius) {
    this.object3d.material.uniforms.radius.value = radius;
  }
  get radius() {
    return this.object3d.material.uniforms.radius.value;
  }
  setWidth(width) {
    this.object3d.scale.x *= width / this.width;
    this.width = width;
  }
  setHeight(height) {
    this.object3d.scale.y *= height / this.height;
    this.height = height;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

const vector = new Vector3();
const position = new Vector3();
class RoundHandleRaycast extends Raycast {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof RoundHandle)) {
      throw new Error("RoundHandle-only component");
    }
  }
  isRaycast(raycaster) {
    const activeViewport = App.Instance().getActiveView().getActiveViewport();
    if (activeViewport && this.entity.object3d.layers.test(activeViewport.camera.layers)) {
      let zoom = 1 / (activeViewport.camera.projectionMatrix.elements[5] * activeViewport.size.w);
      const radius = this.entity.size * zoom;
      position.setFromMatrixColumn(this.entity.object3d.matrixWorld, 3);
      vector.set(raycaster.ray.origin.x, raycaster.ray.origin.y, position.z);
      if (vector.sub(position).length() < radius) {
        return [{
          object: this.entity.object3d,
          distance: 0,
          point: new Vector3().set(raycaster.ray.origin.x, raycaster.ray.origin.y, position.z)
        }];
      } else {
        return false;
      }
    }
    return false;
  }
}

class IncreaseRoundHandleSizeHighlighter extends Highlighter {
  constructor(entity) {
    super(entity);
    if (!(entity instanceof RoundHandle)) {
      throw new Error("RoundHandle-only component");
    }
    this.scale = new Vector3();
    this.increaseFactor = 1.3;
  }
  highlight() {
    this.scale.copy(this.entity.object3d.scale);
    this.entity.object3d.scale.multiplyScalar(this.increaseFactor);
  }
  unHighlight() {
    this.entity.object3d.scale.copy(this.scale);
  }
}

const vector3 = new Vector3();
class PBL extends LeadLine {
  constructor(start, end, viewport) {
    if (start instanceof Vector3) {
      super(start, end, Line2$1, "", viewport);
      this.scale = 1;
    } else {
      viewport = end;
      if (!start.textProps) {
        start.textProps = {};
      }
      start.textProps.fontSize = start.textProps.fontSize || 14;
      start.textProps.color = start.textProps.color ?? 16777215;
      start.width = start.width ?? 2;
      super(start, Line2$1, viewport);
      this.scale = start.scale;
    }
    const handleProps = { color: this.object3d.material.color.getHex(), size: 14 };
    this.startHandle = new RoundHandle(this.start, viewport, handleProps);
    this.endHandle = new RoundHandle(this.end, viewport, handleProps);
    this.textBackground = new RectangleHandle(this.start.clone().add(this.end).divideScalar(2), viewport, { ...handleProps, size: 1, width: 40, height: 18, radius: 9 });
    this.textBackground.size = 1;
    const horizontalPadding = 12;
    const verticalPadding = 2.2;
    this.text.onSyncComplete.add(() => {
      const textSize = this.text.getSize();
      this.textBackground.setWidth(textSize.width + horizontalPadding);
      this.textBackground.setHeight(textSize.height + verticalPadding);
    });
    this.text.setProps({
      text: this.getMeasure().toFixed(1).toString(),
      anchorX: "center",
      anchorY: "middle"
    });
    this.setRenderOrders();
    this.setComponents();
    this.setBehavior();
    this.makeTransparent();
    this.add([this.startHandle, this.endHandle, this.textBackground]);
    this.update = this.update.bind(this);
    this.update();
  }
  makeTransparent() {
    this.object3d.material.transparent = true;
    this.startHandle.object3d.material.transparent = true;
    this.endHandle.object3d.material.transparent = true;
    this.textBackground.object3d.material.transparent = true;
    this.text.object3d.material.transparent = true;
  }
  setRenderOrders() {
    this.object3d.renderOrder = 10;
    this.startHandle.object3d.renderOrder = 11;
    this.endHandle.object3d.renderOrder = 11;
    this.textBackground.object3d.renderOrder = 12;
    this.text.object3d.renderOrder = 13;
  }
  setComponents() {
    this.setComponent(RaycastChildrenOnly);
    this.startHandle.setComponent(HoverComponent);
    this.startHandle.setComponent(RoundHandleRaycast);
    this.startHandle.setComponent(HoverComponent, new HoverComponent(this.startHandle, new IncreaseRoundHandleSizeHighlighter(this.startHandle)));
    this.endHandle.setComponent(HoverComponent);
    this.endHandle.setComponent(RoundHandleRaycast);
    this.endHandle.setComponent(HoverComponent, new HoverComponent(this.endHandle, new IncreaseRoundHandleSizeHighlighter(this.endHandle)));
  }
  setBehavior() {
    this.startHandle.bind();
    this.endHandle.bind();
    this.startHandle.addEventListener("transform", () => this.update());
    this.endHandle.addEventListener("transform", () => this.update());
  }
  update() {
    super.update();
    this.textBackground.object3d.position.copy(this.start.clone().add(this.end).divideScalar(2));
    this.text.setProps({ text: this.getMeasure().toFixed(1).toString() });
  }
  getMeasure() {
    return vector3.subVectors(this.end, this.start).length() * this.scale;
  }
  showText() {
    this.add([this.text, this.textBackground]);
  }
  hideText() {
    this.delete([this.text, this.textBackground]);
  }
  showEndpoints() {
    this.startHandle.size = this.startHandle.object3d.userData.size || this.startHandle.size;
    this.endHandle.size = this.endHandle.object3d.userData.size || this.endHandle.size;
    this.startHandle.object3d.userData.size = void 0;
    this.endHandle.object3d.userData.size = void 0;
  }
  hideEndpoints() {
    this.startHandle.object3d.userData.size = this.startHandle.object3d.userData.size || this.startHandle.size;
    this.endHandle.object3d.userData.size = this.endHandle.object3d.userData.size || this.endHandle.size;
    this.startHandle.size = this.object3d.material.linewidth;
    this.endHandle.size = this.object3d.material.linewidth;
  }
  getViewport() {
    const [viewport] = this.getComponent(ViewportComponent).viewports;
    return viewport;
  }
}

class PBLVisibilityTool extends ViewTool {
  constructor(view) {
    super(view);
    this.onChangeLayout = this.onChangeLayout.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }
  activate() {
    if (FMXLayoutController$1.layoutModes.mode === "matrix") {
      this.hide();
    } else {
      this.show();
    }
    MIRender.addEventListener("layout", this.onChangeLayout);
    MIRender.addEventListener("add", this.onAdd);
  }
  deactivate() {
    MIRender.removeEventListener("layout", this.onChangeLayout);
    MIRender.removeEventListener("add", this.onAdd);
  }
  onAdd(event) {
    if (FMXLayoutController$1.layoutModes.mode !== "matrix") {
      return;
    }
    event.entities.forEach((entity) => {
      if (entity instanceof PBL) {
        entity.hideEndpoints();
        entity.hideText();
      }
    });
  }
  onChangeLayout(event) {
    if (event.mode === "matrix") {
      this.hide();
    } else {
      this.show();
    }
  }
  hide() {
    ImageController$1.imageContainers.forEach((container) => {
      [...container.getPBLs()].forEach((pbl) => {
        pbl.hideEndpoints();
        pbl.hideText();
      });
    });
  }
  show() {
    ImageController$1.imageContainers.forEach((container) => {
      [...container.getPBLs()].forEach((pbl) => {
        pbl.showEndpoints();
        pbl.showText();
      });
    });
  }
}

class FullScreenMatrixView extends ViewTool {
  constructor(view) {
    super(view);
    this.fullScreenMatrix = this.fullScreenMatrix.bind(this);
    this.matrix = this.matrix.bind(this);
  }
  activate() {
    MIRender.keyboardController.addListener("keydown", this.fullScreenMatrix, "KeyF");
    MIRender.keyboardController.addListener("keydown", this.matrix, "KeyM");
  }
  deactivate() {
    MIRender.keyboardController.removeListener("keydown", this.fullScreenMatrix, "KeyF");
    MIRender.keyboardController.removeListener("keydown", this.matrix, "KeyM");
  }
  fullScreenMatrix() {
    FMXLayoutController$1.layoutModes.fullScreenMatrix();
  }
  matrix() {
    FMXLayoutController$1.layoutModes.matrix();
  }
}

class InvertColor extends ViewTool {
  constructor(view) {
    super(view);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.invertColors = this.invertColors.bind(this);
    this.straightColors = this.straightColors.bind(this);
    this.toggleInversion = this.toggleInversion.bind(this);
  }
  activate() {
    MIRender.addEventListener("invertColors", this.invertColors);
    MIRender.addEventListener("straightColors", this.straightColors);
    MIRender.addEventListener("toggleInversion", this.toggleInversion);
  }
  deactivate() {
    MIRender.removeEventListener("invertColors", this.invertColors);
    MIRender.removeEventListener("straightColors", this.straightColors);
    MIRender.removeEventListener("toggleInversion", this.toggleInversion);
  }
  invertColors() {
    ImageController$1.imageContainers.forEach((container) => {
      container.getImage().invertColor();
    });
    MIRender.dispatchEvent({ type: "render" });
  }
  straightColors() {
    ImageController$1.imageContainers.forEach((container) => {
      container.getImage().straightColor();
    });
    MIRender.dispatchEvent({ type: "render" });
  }
  toggleInversion() {
    ImageController$1.imageContainers.forEach((container) => {
      container.getImage().toggleInversion();
    });
    MIRender.dispatchEvent({ type: "render" });
  }
}

const MIRenderViewTools = tuple(ActiveViewSwitcher2, UndoRedo, HoverMask, PBLVisibilityTool, FullScreenMatrixView, InvertColor);
const _MIRenderViewMode = class _MIRenderViewMode extends Mode {
};
_MIRenderViewMode.except = (tools) => new _MIRenderViewMode({ tools: { toolList: tools, include: false } });
let MIRenderViewMode = _MIRenderViewMode;
const MIRenderViewModes = {
  mainMode: new MIRenderViewMode(),
  matrixMode: new MIRenderViewMode(),
  cropMode: new MIRenderViewMode(),
  fulScreenMode: new MIRenderViewMode(),
  contrastBrightnessMode: MIRenderViewMode.except([HoverMask, InvertColor]),
  sharpnessMode: MIRenderViewMode.except([HoverMask, InvertColor]),
  contrastBrightnessMultipleMode: MIRenderViewMode.except([HoverMask, InvertColor]),
  sharpnessMultipleMode: MIRenderViewMode.except([HoverMask, InvertColor]),
  printMode: new MIRenderViewMode([InvertColor])
};

class Zoom2D extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.zoomSpeed = Math.pow(0.95, 0.4);
    this.maxZoom = Infinity;
    this.minZoom = 0;
    this.vector = new Vector3();
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.updateZoomType = this.updateZoomType.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("wheel", this.handleMouseWheel, this.hostObject.domElement);
    this.hostObject.addEventListener("cameraChanged", this.updateZoomType);
    this.updateZoomType();
  }
  deactivate() {
    App.Instance().mouseController.removeListener("wheel", this.handleMouseWheel, this.hostObject.domElement);
    this.hostObject.removeEventListener("cameraChanged", this.updateZoomType);
  }
  get camera() {
    return this.hostObject.camera;
  }
  handleMouseWheel(event) {
    event.preventDefault();
    if (event.deltaY < 0) {
      this.zoom(this.zoomSpeed);
    } else if (event.deltaY > 0) {
      this.zoom(1 / this.zoomSpeed);
    }
    App.Instance().dispatchEvent({ type: "transformCamera", viewport: this.hostObject });
  }
  updateZoomType() {
    if (this.camera instanceof OrthographicCamera) {
      this.zoom = this.zoomForOrthographicCamera;
    } else {
      this.zoom = this.zoomForPerspectiveCamera;
    }
  }
  zoomForOrthographicCamera(scale) {
    this.camera.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.camera.zoom / scale));
    this.camera.updateProjectionMatrix();
  }
  zoomForPerspectiveCamera(scale) {
    const pp = this.hostObject.cameraController.getPivotPoint();
    this.vector.copy(this.camera.position);
    this.camera.position.copy(pp).add(this.vector.sub(pp).multiplyScalar(scale));
    this.hostObject.cameraController.orthographicCameraHelper.updateDistanceFactor();
  }
}

class Zoom2DOnCursor extends Zoom2D {
  constructor() {
    super(...arguments);
    this.zoomForOrthographicCamera = (() => {
      const vector = new Vector3();
      return (scale) => {
        const cameraController = this.hostObject.cameraController;
        const zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.camera.zoom / scale));
        scale = this.camera.zoom / zoom;
        const width = this.camera.right - this.camera.left;
        const height = this.camera.top - this.camera.bottom;
        const position = App.Instance().cursorController.position;
        const deltaFactor = (1 - scale) / this.camera.zoom / 2;
        const xDelta = position.x * width * deltaFactor;
        const yDelta = position.y * height * deltaFactor;
        this.camera.position.add(vector.copy(cameraController.xDir).multiplyScalar(xDelta)).add(vector.copy(cameraController.yDir).multiplyScalar(yDelta));
        this.camera.zoom = zoom;
        this.camera.updateProjectionMatrix();
      };
    })();
  }
  get camera() {
    return this.hostObject.cameraController.getOrthographicCamera();
  }
}

class Pan2D extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.panStart = new Vector2();
    this.panEnd = new Vector2();
    this.panDelta = new Vector2();
    this.panOffset = new Vector3();
    this.vector = new Vector3();
    this.handleLeftMouseButtonDown = this.handleLeftMouseButtonDown.bind(this);
    this.handleMiddleMouseButtonDown = this.handleMiddleMouseButtonDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseMoveOnce = this.handleMouseMoveOnce.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.updatePanType = this.updatePanType.bind(this);
  }
  get cameraController() {
    return this.hostObject.cameraController;
  }
  get camera() {
    return this.hostObject.camera;
  }
  get cameraX() {
    return this.hostObject.cameraController.xDir;
  }
  get cameraY() {
    return this.hostObject.cameraController.yDir;
  }
  get distanceFactor() {
    return this.hostObject.cameraController.getDistanceFactor();
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.handleLeftMouseButtonDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.addListener("pointerdown", this.handleMiddleMouseButtonDown, this.hostObject.getDomElement(), "middle");
    this.updatePanType();
    this.hostObject.addEventListener("cameraChanged", this.updatePanType);
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.handleLeftMouseButtonDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointerdown", this.handleMiddleMouseButtonDown, this.hostObject.getDomElement(), "middle");
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMoveOnce, document);
    this.hostObject?.removeEventListener("cameraChanged", this.updatePanType);
  }
  updatePanType() {
    if (this.camera instanceof OrthographicCamera) {
      this.panType = this.panForOrthographicCamera;
    } else {
      this.panType = this.panForPerspectiveCamera;
    }
  }
  pan(deltaX, deltaY) {
    this.panType(deltaX, deltaY);
    this.hostObject.camera.position.add(this.panOffset);
    this.panOffset.set(0, 0, 0);
    App.Instance().dispatchEvent({ type: "transformCamera", viewport: this.hostObject });
  }
  panForOrthographicCamera(deltaX, deltaY) {
    const sizeRatio = this.cameraController.orthographicCameraHelper.getSizeRatio();
    this.panOffset.copy(this.cameraX).multiplyScalar(-(deltaX * sizeRatio) / this.camera.zoom);
    this.panOffset.add(this.vector.copy(this.cameraY).multiplyScalar(deltaY * sizeRatio / this.camera.zoom));
  }
  panForPerspectiveCamera(deltaX, deltaY) {
    this.cameraController.perspectiveCameraHelper.updateDistanceFactor();
    this.panOffset.copy(this.cameraX).multiplyScalar(-deltaX * this.distanceFactor);
    this.panOffset.add(this.vector.copy(this.cameraY).multiplyScalar(deltaY * this.distanceFactor));
  }
  handleMouseMoveOnce(event) {
    this.panStart.set(event.clientX, event.clientY);
    App.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMoveOnce, document);
  }
  handleMouseDown(event) {
    event.preventDefault();
    App.Instance().mouseController.addListener("pointermove", this.handleMouseMove, document);
    App.Instance().mouseController.addListener("pointerup", this.handleMouseUp, document, "left");
    App.Instance().mouseController.addListener("pointerup", this.handleMouseUp, document, "middle");
    this.panStart.set(event.clientX, event.clientY);
    App.Instance().dispatchEvent({ type: "transformCameraStart", viewport: this.hostObject });
  }
  handleLeftMouseButtonDown(event) {
    if (!event.shiftKey && this.enable) {
      this.handleMouseDown(event);
    }
  }
  handleMiddleMouseButtonDown(event) {
    if (!event.shiftKey && this.enable) {
      this.handleMouseDown(event);
    }
  }
  handleMouseMove(event) {
    this.panEnd.set(event.clientX, event.clientY);
    this.panDelta.subVectors(this.panEnd, this.panStart);
    this.pan(this.panDelta.x, this.panDelta.y);
    this.panStart.copy(this.panEnd);
  }
  handleMouseUp(event) {
    App.Instance().mouseController.removeListener("pointermove", this.handleMouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.handleMouseUp, document, "left");
    App.Instance().mouseController.removeListener("pointerup", this.handleMouseUp, document, "middle");
    this.panStart.set(event.clientX, event.clientY);
    App.Instance().dispatchEvent({ type: "transformCameraEnd", viewport: this.hostObject });
  }
}

class ImagePropsChangeInterceptor extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.onAdd = this.onAdd.bind(this);
    this.onChangeContrastBrightness = this.onChangeContrastBrightness.bind(this);
    this.onChangeSharpness = this.onChangeSharpness.bind(this);
    this.onSetImage = this.onSetImage.bind(this);
  }
  activate() {
    const image = ImageController$1.imageContainers.get(this.hostObject.name)?.getImage();
    if (image instanceof ImageEntity) {
      this.uniform = image.object3d.material.uniforms;
      this.intercept();
    } else {
      this.hostObject.addEventListener("imageAdded", this.onAdd);
    }
    this.hostObject.addEventListener("imageAdded", this.onSetImage, 10);
    MIRender.addEventListener("brightnessContrastChanged", this.onChangeContrastBrightness, 10);
    MIRender.addEventListener("sharpnessChanged", this.onChangeSharpness, 10);
  }
  deactivate() {
    this.hostObject.removeEventListener("imageAdded", this.onAdd);
    this.hostObject.removeEventListener("imageAdded", this.onSetImage);
    MIRender.removeEventListener("brightnessContrastChanged", this.onChangeContrastBrightness);
    MIRender.removeEventListener("sharpnessChanged", this.onChangeSharpness);
    if (this.uniform) {
      this.uniform.brightness = { ...this.uniform.brightness };
      this.uniform.contrast = { ...this.uniform.contrast };
      this.uniform.sharpness = { ...this.uniform.sharpness };
    }
    Observer.stopObserving(ImageController$1.screenProps, "brightness");
    Observer.stopObserving(ImageController$1.screenProps, "contrast");
    Observer.stopObserving(ImageController$1.screenProps, "sharpness");
  }
  onAdd(event) {
    this.uniform = event.image.object3d.material.uniforms;
    this.intercept();
  }
  onChangeContrastBrightness(event) {
    if (event.id !== this.hostObject.name) return;
    event.brightness -= ImageController$1.screenProps.brightness;
    event.contrast -= ImageController$1.screenProps.contrast;
  }
  onChangeSharpness(event) {
    if (event.id !== this.hostObject.name) return;
    event.sharpness -= ImageController$1.screenProps.sharpness;
  }
  onSetImage({ image }) {
    image.brightness += ImageController$1.screenProps.brightness;
    image.contrast += ImageController$1.screenProps.contrast;
    image.sharpness += ImageController$1.screenProps.sharpness;
  }
  intercept() {
    this.uniform.brightness.screenPropsInterceptor = 0;
    this.uniform.contrast.screenPropsInterceptor = 0;
    this.uniform.sharpness.screenPropsInterceptor = 0;
    this.uniform.brightness = this.screenPropsAdditionBehavior(this.uniform.brightness, [-1, 1], "brightness");
    this.uniform.contrast = this.screenPropsAdditionBehavior(this.uniform.contrast, [-1, 1], "contrast");
    this.uniform.sharpness = this.screenPropsAdditionBehavior(this.uniform.sharpness, [-3, 3], "sharpness");
    Observer.observe(ImageController$1.screenProps, "brightness", (val) => this.uniform.brightness.screenPropsInterceptor = val);
    Observer.observe(ImageController$1.screenProps, "contrast", (val) => this.uniform.contrast.screenPropsInterceptor = val);
    Observer.observe(ImageController$1.screenProps, "sharpness", (val) => this.uniform.sharpness.screenPropsInterceptor = val);
  }
  screenPropsAdditionBehavior(object, clampRange, screenProperty) {
    let valueStorage = object.value;
    const set = (target, prop, value) => {
      if (prop === "value") {
        valueStorage = value - ImageController$1.screenProps[screenProperty];
        if (valueStorage <= clampRange[0] || valueStorage >= clampRange[1]) {
          valueStorage = MathUtils.clamp(valueStorage, clampRange[0], clampRange[1]);
          value = valueStorage + ImageController$1.screenProps[screenProperty];
        }
        return Reflect.set(target, prop, MathUtils.clamp(value, clampRange[0], clampRange[1]));
      } else if (prop === "screenPropsInterceptor") {
        return Reflect.set(target, "value", MathUtils.clamp(valueStorage + value, clampRange[0], clampRange[1]));
      }
      return false;
    };
    return new Proxy(object, { set });
  }
  useGlobalPropertyBehavior(object, clampRange, screenProperty) {
    let valueStorage = object.value;
    let globalValue = ImageController$1.screenProps[screenProperty];
    const set = (target, prop, value) => {
      value = MathUtils.clamp(value, clampRange[0], clampRange[1]);
      if (prop === "value") {
        valueStorage = value;
        return Reflect.set(target, prop, MathUtils.clamp(value, clampRange[0], clampRange[1]));
      } else if (prop === "screenPropsInterceptor") {
        if (valueStorage === globalValue) {
          valueStorage = globalValue = value;
        }
        return Reflect.set(target, "value", valueStorage);
      }
      return false;
    };
    return new Proxy(object, { set });
  }
}

const getImageId = (image) => {
  return image.getComponent(ViewportComponent)?.viewports.values().next()?.value.name || image.uuid;
};
const getImageById = (id) => {
  const images = MIRender.view.getLayer("image")?.getObjects();
  if (!images) return;
  const viewport = MIRender.view.getViewport(id);
  if (!viewport) return;
  return images.find((image) => image.getComponent(ViewportComponent)?.viewports.has(viewport));
};

class FitCameraToImage extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.fit = this.fit.bind(this);
    this.setBox = this.setBox.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }
  activate() {
    this.hostObject.addEventListener("lookAtBox", this.setBox);
    if (this.image) {
      this.hostObject.addEventListener("resize", this.fit);
      return;
    }
    const image = getImageById(this.hostObject.name);
    if (image instanceof ImageEntity) {
      this.image = image;
      this.fit();
      this.hostObject.addEventListener("resize", this.fit);
      MIRender.dispatchEvent({ type: "render" });
    } else {
      this.hostObject.addEventListenerOnce("imageAdded", this.onAdd);
    }
  }
  deactivate() {
    this.hostObject.removeEventListener("resize", this.fit);
    this.hostObject.removeEventListener("lookAtBox", this.setBox);
    this.hostObject.removeEventListener("imageAdded", this.onAdd);
    this.box = void 0;
  }
  onAdd(event) {
    this.image = event.image;
    this.fit();
    this.hostObject.addEventListener("resize", this.fit);
    MIRender.dispatchEvent({ type: "render" });
  }
  fit() {
    const camera = this.hostObject.cameraController.getOrthographicCamera();
    const size = new Vector3();
    if (this.box) {
      this.image.object3d.parent?.updateMatrixWorld();
      const box = this.box.clone();
      box.applyMatrix4(this.image.object3d.parent.matrixWorld);
      box.getCenter(camera.position);
      size.subVectors(box.max, box.min);
    } else {
      this.image.object3d.updateWorldMatrix(true, false);
      const x = new Vector3().setFromMatrixColumn(this.image.object3d.matrixWorld, 0);
      const y = new Vector3().setFromMatrixColumn(this.image.object3d.matrixWorld, 1);
      const diagonalA = x.clone().add(y);
      const diagonalB = x.clone().sub(y);
      size.set(Math.max(Math.abs(diagonalA.x), Math.abs(diagonalB.x)), Math.max(Math.abs(diagonalA.y), Math.abs(diagonalB.y)), 0);
      const box = this.image.object3d.geometry.boundingBox.clone();
      box.applyMatrix4(this.image.object3d.matrixWorld);
      box.getCenter(camera.position);
    }
    const cameraWidth = camera.right - camera.left;
    const cameraHeight = camera.top - camera.bottom;
    camera.zoom = Math.min(cameraHeight / size.y, cameraWidth / size.x);
    camera.updateMatrix();
    camera.updateProjectionMatrix();
    MIRender.dispatchEvent({ type: "transformCamera", viewport: this.hostObject });
  }
  setBox(event) {
    const box = event.box;
    if (box) {
      this.box = new Box3(new Vector3(box.min.x, box.min.y, 0), new Vector3(box.max.x, box.max.y, 0));
    } else {
      this.box = box;
    }
    this.fit();
  }
}

class Command {
}

class ContrastBrightnessCommand extends Command {
  constructor(initialBrightness, initialContrast, brightness, contrast, image, id) {
    super();
    this.initialBrightness = initialBrightness;
    this.initialContrast = initialContrast;
    this.brightness = brightness;
    this.contrast = contrast;
    this.image = image;
    this.id = id || getImageId(image);
  }
  execute() {
    this.image.brightness = this.brightness;
    this.image.contrast = this.contrast;
    MIRender.dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "brightnessContrastChanged", id: this.id, brightness: this.brightness, contrast: this.contrast });
    return true;
  }
  undo() {
    this.image.brightness = this.initialBrightness;
    this.image.contrast = this.initialContrast;
    MIRender.dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "brightnessContrastChanged", id: this.id, brightness: this.initialBrightness, contrast: this.initialContrast });
    return true;
  }
}

class ImageContrastBrightness extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 1.5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey) return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    const image = this.image || ImageController$1.imageContainers.get(this.hostObject.name)?.getImage();
    if (image instanceof ImageEntity) {
      this.image = image;
      this.initialBrightness = this.image.brightness;
      this.initialContrast = this.image.contrast;
      App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
      App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
    }
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.image.brightness = MathUtils.clamp(this.image.brightness + this.delta.y / this.hostObject.rect.height, -1, 1);
    this.image.contrast = MathUtils.clamp(this.image.contrast + this.delta.x / this.hostObject.rect.width, -1, 1);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new ContrastBrightnessCommand(this.initialBrightness, this.initialContrast, this.image.brightness, this.image.contrast, this.image, this.hostObject.name);
    App.Instance().executeCommand(command, false);
    MIRender.dispatchEvent({ type: "brightnessContrastChanged", id: this.hostObject.name, brightness: this.image.brightness, contrast: this.image.contrast });
  }
}

class SharpnessCommand extends Command {
  constructor(initialSharpness, sharpness, image, id) {
    super();
    this.initialSharpness = initialSharpness;
    this.sharpness = sharpness;
    this.image = image;
    this.id = id || getImageId(image);
  }
  execute() {
    this.image.sharpness = this.sharpness;
    MIRender.dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "sharpnessChanged", id: this.id, sharpness: this.sharpness });
    return true;
  }
  undo() {
    this.image.sharpness = this.initialSharpness;
    App.Instance().dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "sharpnessChanged", id: this.id, sharpness: this.initialSharpness });
    return true;
  }
}

class ImageSharpness extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey) return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    const image = this.image || ImageController$1.imageContainers.get(this.hostObject.name)?.getImage();
    if (image instanceof ImageEntity) {
      this.image = image;
      this.initialSharpness = this.image.sharpness;
      App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
      App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
    }
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.image.sharpness = MathUtils.clamp(this.image.sharpness + this.delta.y / this.hostObject.rect.height, 0, 3);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new SharpnessCommand(this.initialSharpness, this.image.sharpness, this.image, this.hostObject.name);
    App.Instance().executeCommand(command, false);
    MIRender.dispatchEvent({ type: "sharpnessChanged", id: this.hostObject.name, sharpness: this.image.sharpness });
  }
}

class ScreenContrastBrightnessCommand extends Command {
  constructor(initialBrightness, initialContrast, brightness, contrast) {
    super();
    this.initialBrightness = initialBrightness;
    this.initialContrast = initialContrast;
    this.brightness = brightness;
    this.contrast = contrast;
  }
  execute() {
    ImageController$1.screenProps.brightness = this.brightness;
    ImageController$1.screenProps.contrast = this.contrast;
    MIRender.dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "screenBrightnessContrastChanged", brightness: this.brightness, contrast: this.contrast });
    return true;
  }
  undo() {
    ImageController$1.screenProps.brightness = this.initialBrightness;
    ImageController$1.screenProps.contrast = this.initialContrast;
    MIRender.dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "screenBrightnessContrastChanged", brightness: this.initialBrightness, contrast: this.initialContrast });
    return true;
  }
}

class ImageContrastBrightnessMultiple extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 1.5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  set brightness(brightness) {
    ImageController$1.screenProps.brightness = brightness;
  }
  get brightness() {
    return ImageController$1.screenProps.brightness;
  }
  set contrast(contrast) {
    ImageController$1.screenProps.contrast = contrast;
  }
  get contrast() {
    return ImageController$1.screenProps.contrast;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey) return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    this.initialBrightness = this.brightness;
    this.initialContrast = this.contrast;
    App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
    App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.brightness = MathUtils.clamp(this.brightness + this.delta.y / this.hostObject.rect.height, -1, 1);
    this.contrast = MathUtils.clamp(this.contrast + this.delta.x / this.hostObject.rect.width, -1, 1);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new ScreenContrastBrightnessCommand(this.initialBrightness, this.initialContrast, this.brightness, this.contrast);
    App.Instance().executeCommand(command, false);
    MIRender.dispatchEvent({ type: "screenBrightnessContrastChanged", brightness: this.brightness, contrast: this.contrast });
  }
}

class ScreenSharpnessCommand extends Command {
  constructor(initialSharpness, sharpness) {
    super();
    this.initialSharpness = initialSharpness;
    this.sharpness = sharpness;
  }
  execute() {
    ImageController$1.screenProps.sharpness = this.sharpness;
    MIRender.dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "screenSharpnessChanged", sharpness: this.sharpness });
    return true;
  }
  undo() {
    ImageController$1.screenProps.sharpness = this.initialSharpness;
    App.Instance().dispatchEvent({ type: "render" });
    MIRender.dispatchEvent({ type: "screenSharpnessChanged", sharpness: this.initialSharpness });
    return true;
  }
}

class ImageSharpnessMultiple extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.initialCursor = "";
    this.start = new Vector2();
    this.delta = new Vector2();
    this.speed = 5;
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left", 20);
    this.initialCursor = this.hostObject.getDomElement().style.cursor;
    this.hostObject.getDomElement().style.cursor = "all-scroll";
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.mouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, this.hostObject.getDomElement(), "left");
    this.hostObject.getDomElement().style.cursor = this.initialCursor;
  }
  set sharpness(sharpness) {
    ImageController$1.screenProps.sharpness = sharpness;
  }
  get sharpness() {
    return ImageController$1.screenProps.sharpness;
  }
  mouseDown(event) {
    if (event.shiftKey || event.ctrlKey || event.metaKey) return;
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    this.initialSharpness = this.sharpness;
    App.Instance().mouseController.addListener("pointermove", this.mouseMove, document, 9);
    App.Instance().mouseController.addListener("pointerup", this.mouseUp, document, "left", 20);
  }
  mouseMove(event) {
    event.preventDefault();
    this.delta.x = event.clientX - this.start.x;
    this.delta.y = -(event.clientY - this.start.y);
    this.delta.multiplyScalar(this.speed);
    this.sharpness = MathUtils.clamp(this.sharpness + this.delta.y / this.hostObject.rect.height, 0, 3);
    this.start.x = event.clientX;
    this.start.y = event.clientY;
    App.Instance().dispatchEvent({ type: "render" });
  }
  mouseUp() {
    App.Instance().mouseController.removeListener("pointermove", this.mouseMove, document);
    App.Instance().mouseController.removeListener("pointerup", this.mouseUp, document, "left");
    const command = new ScreenSharpnessCommand(this.initialSharpness, this.sharpness);
    App.Instance().executeCommand(command, false);
    MIRender.dispatchEvent({ type: "screenSharpnessChanged", sharpness: this.sharpness });
  }
}

class SelectComponent extends Component {
  constructor(entity, highlighter = new ColorHighlighter(entity)) {
    super(entity);
    this.selected = false;
    this.highlighter = highlighter;
  }
  select() {
    this.selected = true;
    this.highlighter.highlight();
  }
  deselect() {
    this.selected = false;
    this.highlighter.unHighlight();
  }
  isSelected() {
    return this.selected;
  }
}

class Drag extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.entities = [];
    this.dragOffset = new Vector3();
    this.dragStart = new Vector2();
    this.dragEnd = new Vector2();
    this.dragDelta = new Vector2();
    this.vector = new Vector3();
    this.entities = [];
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMoveInitial = this.onMouseMoveInitial.bind(this);
  }
  activate() {
    App.Instance().mouseController.addListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
    App.Instance().mouseController.addListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left", 10);
  }
  deactivate() {
    App.Instance().mouseController.removeListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left");
    App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.onMouseUp, document, "left");
    App.Instance().mouseController.listeners.get(this.hostObject.getDomElement())?.enableButton("pointerdown", "left");
  }
  get camera() {
    return this.hostObject.camera;
  }
  get cameraX() {
    return this.hostObject.cameraController.xDir;
  }
  get cameraY() {
    return this.hostObject.cameraController.yDir;
  }
  setObjects() {
    const raycasted = App.Instance().raycastController.getObject();
    if (!raycasted) {
      this.entities.length = 0;
      return;
    }
    if (raycasted instanceof Handle && raycasted.getComponent(HoverComponent)) {
      this.entities = [raycasted];
    } else if (raycasted?.getComponent(SelectComponent)) {
      this.entities = this.hostObject.view.selectController.getSelected().map((selectComponent) => selectComponent.entity);
    } else {
      this.entities.length = 0;
    }
  }
  onMouseMoveInitial() {
    this.setObjects();
    if (this.entities.length) {
      this.hostObject.getDomElement().style.cursor = "pointer";
    } else {
      this.hostObject.getDomElement().style.cursor = "default";
    }
  }
  onMouseDown(event) {
    if (this.entities.length) {
      App.Instance().mouseController.listeners.get(this.hostObject.getDomElement())?.disableButton("pointerdown", "left");
      App.Instance().mouseController.removeListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
      App.Instance().mouseController.addListener("pointermove", this.onMouseMove, this.hostObject.getDomElement());
      App.Instance().mouseController.addListener("pointerup", this.onMouseUp, document, "left");
      this.dragStart.set(event.clientX, event.clientY);
      App.Instance().dispatchEvent({ type: "transformStart", entities: [...this.entities] });
      this.hostObject.getDomElement().style.cursor = "grabbing";
    }
  }
  onMouseMove(event) {
    this.dragEnd.set(event.clientX, event.clientY);
    this.dragDelta.subVectors(this.dragEnd, this.dragStart);
    this.drag(this.dragDelta.x, this.dragDelta.y);
    this.dragStart.copy(this.dragEnd);
    App.Instance().dispatchEvent({ type: "transform", entities: [...this.entities] });
  }
  onMouseUp() {
    App.Instance().mouseController.listeners.get(this.hostObject.getDomElement())?.enableButton("pointerdown", "left");
    App.Instance().mouseController.removeListener("pointermove", this.onMouseMove, this.hostObject.getDomElement());
    App.Instance().mouseController.removeListener("pointerup", this.onMouseUp, document, "left");
    App.Instance().mouseController.addListener("pointermove", this.onMouseMoveInitial, this.hostObject.getDomElement());
    App.Instance().dispatchEvent({ type: "transformEnd", entities: [...this.entities] });
    this.dragOffset.set(0, 0, 0);
    this.entities.length = 0;
    this.hostObject.getDomElement().style.cursor = "pointer";
  }
  drag(deltaX, deltaY) {
    this.dragOffset.set(0, 0, 0);
    const sizeRatio = this.hostObject.cameraController.orthographicCameraHelper.getSizeRatio();
    this.dragOffset.copy(this.cameraX).multiplyScalar(deltaX * sizeRatio / this.camera.zoom);
    this.dragOffset.add(this.vector.copy(this.cameraY).multiplyScalar(-deltaY * sizeRatio / this.camera.zoom));
    this.entities.forEach((entity) => {
      entity.object3d.position.add(this.dragOffset);
      entity.dispatchEvent({ type: "transform", entity });
    });
  }
}

class Hover extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.onHover = this.onHover.bind(this);
    this.onUnhover = this.onUnhover.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onChangeActiveViewport = this.onChangeActiveViewport.bind(this);
  }
  activate() {
    App.Instance().addEventListener("hover", this.onHover);
    App.Instance().addEventListener("unhover", this.onUnhover);
    App.Instance().addEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.addListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left", 15);
  }
  deactivate() {
    App.Instance().removeEventListener("hover", this.onHover);
    App.Instance().removeEventListener("unhover", this.onUnhover);
    App.Instance().removeEventListener("ActiveViewportWillChange", this.onChangeActiveViewport);
    App.Instance().mouseController.removeListener("pointerdown", this.onMouseDown, this.hostObject.getDomElement(), "left");
  }
  onChangeActiveViewport(event) {
    if (event.current === this.hostObject) {
      App.Instance().addEventListenerOnce("unhover", (event2) => {
        const hoverComponent = event2.entity.getComponent(HoverComponent);
        if (hoverComponent) {
          hoverComponent.unhover();
          App.Instance().dispatchEvent({ type: "render" });
        }
      });
    }
  }
  onHover(event) {
    if (App.Instance().getActiveView().getActiveViewport() !== this.hostObject || this.isSelected(event.entity)) return;
    const hoverComponent = event.entity.getComponent(HoverComponent);
    if (hoverComponent) {
      hoverComponent.hover();
      App.Instance().dispatchEvent({ type: "render" });
    }
  }
  onUnhover(event) {
    if (App.Instance().getActiveView().getActiveViewport() !== this.hostObject || this.isSelected(event.entity)) return;
    const hoverComponent = event.entity.getComponent(HoverComponent);
    if (hoverComponent) {
      hoverComponent.unhover();
      App.Instance().dispatchEvent({ type: "render" });
    }
  }
  onMouseDown() {
    const entity = App.Instance().raycastController.getObject();
    entity && entity.getComponent(Raycast) && entity.getComponent(SelectComponent) && this.onUnhover({ entity });
  }
  isSelected(entity) {
    const selectComponent = entity.getComponent(SelectComponent);
    return selectComponent && this.hostObject.view.selectController.has(selectComponent);
  }
}

class FocusByClick extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.onClick = this.onClick.bind(this);
    this.onChangeLayout = this.onChangeLayout.bind(this);
  }
  activate() {
    if (FMXLayoutController$1.layoutModes.mode === "matrix") {
      MIRender.mouseController.addListener("click", this.onClick, this.hostObject.getDomElement());
    }
    MIRender.addEventListener("layout", this.onChangeLayout);
  }
  deactivate() {
    MIRender.mouseController.removeListener("click", this.onClick, this.hostObject.getDomElement());
    MIRender.removeEventListener("layout", this.onChangeLayout);
  }
  onClick() {
    FMXLayoutController$1.layoutModes.focus(this.hostObject.name);
  }
  onChangeLayout(event) {
    if (event.mode === "matrix") {
      MIRender.mouseController.addListener("click", this.onClick, this.hostObject.getDomElement());
    } else {
      MIRender.mouseController.removeListener("click", this.onClick, this.hostObject.getDomElement());
    }
  }
}

class Render extends ViewportTool {
  constructor(viewport) {
    super(viewport);
    this.render = this.render.bind(this);
  }
  activate() {
    App.Instance().addEventListener("add", this.render);
    App.Instance().addEventListener("delete", this.render);
    App.Instance().addEventListener("resize", this.render);
    App.Instance().addEventListener("transformCamera", this.render);
    App.Instance().addEventListener("select", this.render);
    App.Instance().addEventListener("deselect", this.render);
    App.Instance().addEventListener("transform", this.render);
    App.Instance().addEventListener("viewportSchemeChanged", this.render);
    App.Instance().addEventListener("render", this.render);
  }
  deactivate() {
    App.Instance().removeEventListener("add", this.render);
    App.Instance().removeEventListener("delete", this.render);
    App.Instance().removeEventListener("resize", this.render);
    App.Instance().removeEventListener("transformCamera", this.render);
    App.Instance().removeEventListener("select", this.render);
    App.Instance().removeEventListener("deselect", this.render);
    App.Instance().removeEventListener("transform", this.render);
    App.Instance().removeEventListener("viewportSchemeChanged", this.render);
    App.Instance().removeEventListener("render", this.render);
  }
  render() {
    this.hostObject.render();
  }
}

const _MIRenderViewportMode = class _MIRenderViewportMode extends Mode {
};
_MIRenderViewportMode.except = (tools) => new _MIRenderViewportMode({ tools: { toolList: tools, include: false } });
let MIRenderViewportMode = _MIRenderViewportMode;
const MIRenderViewportServiceTools = (key) => {
  if (key === "matrix" || key === "pano") {
    return /* @__PURE__ */ new Set([Render]);
  }
  return /* @__PURE__ */ new Set([Render, ImagePropsChangeInterceptor]);
};
const MIRenderViewportTools = tuple(
  Pan2D,
  Zoom2DOnCursor,
  ImageContrastBrightness,
  ImageSharpness,
  FitCameraToImage,
  FocusByClick,
  ImageContrastBrightnessMultiple,
  ImageSharpnessMultiple,
  Drag,
  Hover
);
const MIRenderViewportModes = (key) => {
  if (key === "matrix") {
    return {
      mainMode: new MIRenderViewportMode([Drag, Hover, Zoom2DOnCursor, Pan2D, FocusByClick]),
      matrixMode: new MIRenderViewportMode([Drag, Hover, FocusByClick]),
      cropMode: new MIRenderViewportMode([Drag, Hover, FocusByClick]),
      fulScreenMode: new MIRenderViewportMode({ tools: { toolList: [Pan2D, Zoom2DOnCursor], include: true } }),
      contrastBrightnessMode: new MIRenderViewportMode({ tools: { toolList: [], include: true } }),
      sharpnessMode: new MIRenderViewportMode({ tools: { toolList: [], include: true } }),
      contrastBrightnessMultipleMode: new MIRenderViewportMode({ tools: { toolList: [ImageContrastBrightnessMultiple], include: true } }),
      sharpnessMultipleMode: new MIRenderViewportMode({ tools: { toolList: [ImageSharpnessMultiple], include: true } }),
      printMode: new MIRenderViewportMode([])
    };
  } else if (key === "pano") {
    return {
      mainMode: new MIRenderViewportMode([FitCameraToImage, Pan2D, Zoom2DOnCursor, Hover]),
      matrixMode: new MIRenderViewportMode([Drag, Hover, FocusByClick]),
      cropMode: new MIRenderViewportMode([Drag, Hover, FocusByClick]),
      fulScreenMode: new MIRenderViewportMode({ tools: { toolList: [], include: true } }),
      contrastBrightnessMode: new MIRenderViewportMode({ tools: { toolList: [ImageContrastBrightness], include: true } }),
      sharpnessMode: new MIRenderViewportMode({ tools: { toolList: [ImageSharpness], include: true } }),
      contrastBrightnessMultipleMode: new MIRenderViewportMode({ tools: { toolList: [], include: true } }),
      sharpnessMultipleMode: new MIRenderViewportMode({ tools: { toolList: [], include: true } }),
      printMode: new MIRenderViewportMode([FitCameraToImage])
    };
  } else {
    return {
      mainMode: new MIRenderViewportMode([FitCameraToImage, Drag, Hover, Zoom2DOnCursor, Pan2D, FocusByClick]),
      matrixMode: new MIRenderViewportMode([Drag, Hover, FocusByClick]),
      cropMode: new MIRenderViewportMode([Drag, Hover, FocusByClick]),
      fulScreenMode: new MIRenderViewportMode({ tools: { toolList: [Pan2D, Zoom2DOnCursor], include: true } }),
      contrastBrightnessMode: new MIRenderViewportMode({ tools: { toolList: [ImageContrastBrightness], include: true } }),
      sharpnessMode: new MIRenderViewportMode({ tools: { toolList: [ImageSharpness], include: true } }),
      contrastBrightnessMultipleMode: new MIRenderViewportMode({ tools: { toolList: [ImageContrastBrightnessMultiple], include: true } }),
      sharpnessMultipleMode: new MIRenderViewportMode({ tools: { toolList: [ImageSharpnessMultiple], include: true } }),
      printMode: new MIRenderViewportMode([FitCameraToImage])
    };
  }
};

var vertexShader = "#version 300 es\n#define GLSLIFY 1\nuniform vec4 viewport;in vec3 position;in vec2 uv;out vec2 coords;void main(){coords=uv*vec2(viewport.zw);gl_Position=vec4(position.xy,-0.5f,1.0f);}"; // eslint-disable-line

var fragmentShader = "#version 300 es\nprecision highp float;\n#define GLSLIFY 1\nuniform vec4 color;uniform float radius;uniform vec4 viewport;in vec2 coords;out vec4 out_color;float sdRoundedBox(in vec2 p,in vec2 b,in vec4 r){r.xy=(p.x>0.0f)? r.xy : r.zw;r.x=(p.y>0.0f)? r.x : r.y;vec2 q=abs(p)-b+r.x;return min(max(q.x,q.y),0.0f)+length(max(q,0.0f))-r.x;}void main(){vec2 p=(2.0f*coords-viewport.zw)/viewport.w;vec2 b=vec2(viewport.z/viewport.w,1.0f);float box=sdRoundedBox(p,b,vec4(2.0f*radius/viewport.w));if(box<=0.0f){discard;}out_color=color;}"; // eslint-disable-line

class RoundCornerMaterial extends RawShaderMaterial {
  constructor(viewport, radius, color) {
    color = new Color(color);
    super({
      vertexShader,
      fragmentShader,
      uniforms: {
        viewport: { value: viewport },
        radius: { value: radius },
        color: { value: new Vector4(color.r, color.g, color.b, 1) }
      }
    });
    this.toneMapped = false;
  }
}

class RoundCornerViewportEntity extends Entity {
  constructor(viewport, radius, color) {
    super(new Mesh(new PlaneGeometry(2, 2), new RoundCornerMaterial(viewport.size, radius, color)));
    const viewportComponent = this.setComponent(ViewportComponent);
    viewportComponent.addViewport(viewport);
    this.object3d.renderOrder = 1e3;
    this.object3d.frustumCulled = false;
  }
  toJson() {
    throw new Error("Method not implemented.");
  }
}

const MIRenderStartups = [
  () => MIRender.view.viewports.forEach((viewport) => {
    if (viewport.name === "matrix") {
      return;
    }
    viewport.init();
  }),
  () => MIRender.view.viewports.forEach((viewport) => {
    MIRender.view.getLayer("roundCorners").add(new RoundCornerViewportEntity(viewport, 8, "black"));
  })
];

class Launcher {
  constructor(config) {
    this.app = App.Instance();
    this.view = this.app.view;
    this.listeners = config.appEventNames;
    this.layerNames = config.layerNames;
    this.viewTools = config.viewTools;
    this.modes = config.modes;
    this.viewportsEventNames = config.viewportsEventNames;
    this.viewportTools = config.viewportTools;
    this.viewportModes = config.viewportModes;
    this.startups = config.startups;
    this.viewportServiceTools = config.viewportServiceTools;
  }
  launch(domRefs) {
    this.setup(domRefs);
    this.init(domRefs);
  }
  shutdown() {
    this.app.clear();
  }
  init(domRefs) {
    this.app.init({
      container: domRefs.view,
      rendererProps: { canvas: domRefs.canvas, antialias: true }
    });
    this.view.viewports.forEach((viewport) => {
      viewport.setDomElement(domRefs.viewports[viewport.name].divElement);
    });
    this.app.runStartups();
    this.view.activateMode("mainMode");
  }
  dispose() {
    this.view.viewports.forEach((viewport) => {
      viewport.dispose();
    });
    this.app.dispose();
  }
  setup(domRefs) {
    this.app.setListeners(this.listeners);
    this.setupView();
    this.setupViewports(domRefs.viewports);
    this.app.startupSystems.push(...this.startups);
  }
  setupView() {
    this.view.toolController.setTools(this.viewTools);
    this.view.toolController.setModes(this.modes);
    this.layerNames.forEach((name) => this.view.addLayer(name));
  }
  setupViewports(viewportRefs) {
    let key;
    for (key in viewportRefs) {
      const viewport = this.view.addViewport(key, viewportRefs[key].divElement, { eventNames: this.viewportsEventNames });
      viewport.alpha = 0;
      if (Array.isArray(this.viewportTools)) {
        viewport.toolController.setTools(this.viewportTools);
      } else {
        viewport.toolController.setTools(this.viewportTools(viewportRefs[key].viewportType || key));
      }
      viewport.toolController.setModes(this.viewportModes(viewportRefs[key].viewportType || key));
      if (!this.viewportServiceTools) {
        return;
      }
      if (this.viewportServiceTools instanceof Set) {
        viewport.toolController.setServiceTools(this.viewportServiceTools);
      } else {
        viewport.toolController.setServiceTools(this.viewportServiceTools(viewportRefs[key].viewportType || key));
      }
    }
  }
}

const MIRenderLayerNames = ["image", "roundCorners"];
const MIRenderConfig = {
  appEventNames: MIRenderEventNames,
  layerNames: MIRenderLayerNames,
  viewTools: MIRenderViewTools,
  modes: MIRenderViewModes,
  viewportsEventNames: MIRenderViewportEventNames,
  viewportTools: MIRenderViewportTools,
  viewportModes: MIRenderViewportModes,
  startups: MIRenderStartups,
  viewportServiceTools: MIRenderViewportServiceTools
};
const MIRender = App.Instance();
const MIRenderLauncher = new Launcher(MIRenderConfig);
MIRender.raycastController.getNearest = (generator) => [...generator].sort((a, b) => b.raycast.entity.object3d.renderOrder - a.raycast.entity.object3d.renderOrder)[0];

const flipY = (point) => {
  point.y = -1 * (point.y || 0);
  return point;
};

const smoothPath = (points, times = 1) => {
  let vertices = points.map((point) => new Vector2(point.x, point.y));
  for (let i = 0; i < times; i++) {
    for (let i2 = vertices.length - 1; i2 >= 0; i2--) {
      const current = vertices[i2];
      const prev = vertices[(i2 + 1) % vertices.length];
      const vector = prev.clone().sub(current);
      if (vector.length() > 3) {
        const unitVector = vector.normalize();
        vertices.splice(i2 + 1, 0, prev.clone().sub(unitVector));
        vertices.splice(i2 + 1, 0, current.clone().add(unitVector));
      }
    }
    vertices = vertices.map((point, i2, arr) => {
      const nextPoint = arr[(i2 + 1) % vertices.length];
      return new Vector2((point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2);
    });
  }
  return vertices;
};

class ImageController {
  constructor() {
    this.screenProps = {
      brightness: 0,
      contrast: 0,
      sharpness: 0
    };
    this.imageContainers = /* @__PURE__ */ new Map();
    this.imageProps = /* @__PURE__ */ new Map();
    this.teeth = /* @__PURE__ */ new Map();
  }
  init(imageMatrix, containers, getImageSrc) {
    imageMatrix.flat(2).forEach((item) => this.imageProps.set(item.ImageID, item));
    containers.forEach((container) => this.imageContainers.set(container.id, container));
    this.getImageSrc = getImageSrc;
  }
  updateContainers() {
    FMXLayoutController$1.layoutModes.matrixLayout.updateBlocks(ImageContainer, this.imageContainers);
    if (FMXLayoutController$1.layoutModes.mode === "focus" || FMXLayoutController$1.layoutModes.mode === "fullScreenMatrix") {
      MIRender.view.viewports.forEach((viewport) => {
        viewport.dispatchEvent({ type: "lookAtBox" });
      });
    }
  }
  dispose() {
    this.imageProps.clear();
    this.imageContainers.clear();
    this.teeth.clear();
  }
  addImages() {
    const imageLayer = MIRender.view.getLayer("image");
    this.imageContainers.forEach(async (container) => {
      const imageProperty = this.imageProps.get(container.id);
      if (!imageProperty) {
        console.error(`image properties (id ${container.id}) is undefined`);
        return;
      }
      container.addImage(imageProperty);
      imageLayer?.add(container);
      container.getComponent(ViewportComponent)?.addViewport(container.id);
      container.getComponent(ViewportComponent)?.addViewport("matrix");
      MIRender.view.getViewport(container.id).dispatchEvent({ type: "imageAdded", imageID: container.id, image: container.getImage() });
    });
  }
  addPBL(props) {
    props.forEach((item) => {
      const id = item.imageID;
      const container = this.imageContainers.get(id);
      if (!container) {
        console.error(`[addPBL] no image with id ${id}`);
        return;
      }
      item.PBLs.map((pblProps) => {
        pblProps.start = flipY(pblProps.start);
        pblProps.end = flipY(pblProps.end);
        const pbl = new PBL({ ...pblProps, scale: item.scale }, id);
        container.addPBL(pbl);
      });
    });
  }
  addTeeth(teeth) {
    teeth.forEach((tooth) => this.teeth.set(tooth.ID, tooth));
  }
  addMasks(data) {
    data.forEach((item) => {
      const container = this.imageContainers.get(item.imageID);
      if (!container) {
        console.error(`[addMasks] no image with id ${item.imageID}`);
        return;
      }
      let mask;
      let maskPropsExceptPoints = {
        id: item.localizationID,
        color: item.outlineColor,
        opacity: item.outlineOpacity,
        fillColor: item.color,
        fillOpacity: item.opacity,
        width: item.outlineWidth || 3,
        renderOrder: item.renderOrder,
        hoverDisabled: item.hoverDisabled,
        meta: item.meta
      };
      if (Array.isArray(item.path)) {
        if (!item.path.length) {
          console.warn(`[addMasks] path is empty. Condition localizationID = ${item.localizationID}`);
          return;
        }
        const maskProps = {
          points: item.path,
          ...maskPropsExceptPoints
        };
        maskProps.points = smoothPath(item.path, 4).map((item2) => flipY(item2));
        mask = new Mask(maskProps, item.imageID);
      } else {
        const maskProps = {
          ...maskPropsExceptPoints,
          min: flipY({ x: item.path.X.Min, y: item.path.Y.Min }),
          max: flipY({ x: item.path.X.Max, y: item.path.Y.Max })
        };
        mask = new BoxMask(maskProps, item.imageID);
      }
      container.addMask([mask], item.groupID);
    });
    MIRender.dispatchEvent({ type: "render" });
  }
  addConditionBoxes(data) {
    data.forEach((item) => {
      const container = this.imageContainers.get(item.imageID);
      if (!container) {
        console.error(`[addConditionBoxes] no image with id ${item.imageID}`);
        return;
      }
      const boxes = item.boxes.map((data2) => {
        data2.min = flipY(data2.min);
        data2.max = flipY(data2.max);
        return new RoundCornerBBox(data2, item.imageID);
      });
      container.addConditionBoxes(boxes);
    });
  }
}
var ImageController$1 = new ImageController();

class ImageContainer extends Block {
  constructor(entities = [], width, height, id) {
    super(entities, width, height, id);
    this.pbls = [];
    this.masks = /* @__PURE__ */ new Map();
    this.conditionBoxes = [];
    this.setComponent(ViewportComponent);
    this.setComponent(RaycastChildrenOnly);
  }
  addImage(props) {
    if (!ImageController$1.getImageSrc) {
      console.error(`getImageSrc is undefined )`);
      return;
    }
    const previewURL = ImageController$1.getImageSrc(props.ImageID, "preview");
    const originalURL = ImageController$1.getImageSrc(props.ImageID, "original");
    const image = new XRayImage(new Texture(), props.originalSize.width, props.originalSize.height);
    image.brightness = props.MedicalImageFeatures?.ViewOptions?.Brightness || 0;
    image.sharpness = props.MedicalImageFeatures?.ViewOptions?.Sharpness || 0;
    image.contrast = props.MedicalImageFeatures?.ViewOptions?.Contrast || 0;
    this.image = image;
    this.add(image);
    this.showPBLs();
    this.showMasks();
    App.Instance().dispatchEvent({ type: "render" });
    const setTexture = (texture) => {
      const prevUniforms = image.object3d.material.uniforms;
      image.setMaterial(texture, props.originalSize.width, props.originalSize.height);
      image.object3d.material.uniforms.brightness = prevUniforms.brightness;
      image.object3d.material.uniforms.contrast = prevUniforms.contrast;
      image.object3d.material.uniforms.sharpness = prevUniforms.sharpness;
      image.object3d.material.uniforms.invert = prevUniforms.invert;
      App.Instance().dispatchEvent({ type: "render" });
    };
    ImageLoader$1.get(previewURL).then(async (texture) => {
      setTexture(texture);
      texture = await ImageLoader$1.get(originalURL);
      setTexture(texture);
    });
  }
  getImage() {
    return this.image;
  }
  *getPBLs() {
    for (let i = 0; i < this.pbls.length; i++) {
      yield this.pbls[i];
    }
  }
  addPBL(pbl) {
    this.pbls.push(pbl);
  }
  showPBLs() {
    this.add(this.pbls);
  }
  hidePBLs() {
    this.delete(this.pbls);
  }
  addMask(masks, groupID) {
    const maskWrapper = this.masks.get(groupID);
    if (maskWrapper) {
      maskWrapper.add(masks);
    } else {
      const wrapper = new Wrapper(masks, "");
      wrapper.setComponent(RaycastChildrenOnly);
      this.masks.set(groupID, wrapper);
    }
  }
  deleteMasks(groupIDs) {
    if (groupIDs) {
      groupIDs.forEach((groupID) => {
        const masks = this.masks.get(groupID);
        if (masks) {
          this.delete(masks);
          this.masks.delete(groupID);
        }
      });
    } else {
      this.delete([...this.masks.values()]);
      this.masks.clear();
    }
  }
  showMasks(groupIDs) {
    if (groupIDs) {
      groupIDs.forEach((groupID) => {
        const masks = this.masks.get(groupID);
        if (masks) {
          this.add(masks);
        }
      });
    } else {
      this.add([...this.masks.values()]);
    }
  }
  hideMasks(groupIDs) {
    if (groupIDs) {
      groupIDs.forEach((groupID) => {
        const masks = this.masks.get(groupID);
        if (masks) {
          this.delete(masks);
        }
      });
    } else {
      this.delete([...this.masks.values()]);
    }
  }
  addConditionBoxes(boxes) {
    this.conditionBoxes.push(...boxes);
    this.add(boxes);
  }
  deleteConditionBoxes() {
    this.delete(this.conditionBoxes);
    this.conditionBoxes.length = 0;
  }
}

var __create$1 = Object.create;
var __defProp$1 = Object.defineProperty;
var __getOwnPropDesc$1 = Object.getOwnPropertyDescriptor;
var __knownSymbol$1 = (name, symbol) => (symbol = Symbol[name]) ? symbol : Symbol.for("Symbol." + name);
var __typeError$1 = (msg) => {
  throw TypeError(msg);
};
var __defNormalProp$1 = (obj, key, value) => key in obj ? __defProp$1(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decoratorStart$1 = (base) => [, , , __create$1(null)];
var __decoratorStrings$1 = ["class", "method", "getter", "setter", "accessor", "field", "value", "get", "set"];
var __expectFn$1 = (fn) => fn !== void 0 && typeof fn !== "function" ? __typeError$1("Function expected") : fn;
var __decoratorContext$1 = (kind, name, done, metadata, fns) => ({ kind: __decoratorStrings$1[kind], name, metadata, addInitializer: (fn) => done._ ? __typeError$1("Already initialized") : fns.push(__expectFn$1(fn || null)) });
var __decoratorMetadata$1 = (array, target) => __defNormalProp$1(target, __knownSymbol$1("metadata"), array[3]);
var __runInitializers$1 = (array, flags, self, value) => {
  for (var i = 0, fns = array[flags >> 1], n = fns && fns.length; i < n; i++) fns[i].call(self) ;
  return value;
};
var __decorateElement$1 = (array, flags, name, decorators, target, extra) => {
  var it, done, ctx, access, k = flags & 7, s = !!(flags & 8), p = !!(flags & 16);
  var j = 2 , key = __decoratorStrings$1[k + 5];
  var extraInitializers = array[j] || (array[j] = []);
  var desc = ((target = target.prototype), __getOwnPropDesc$1(target , name));
  for (var i = decorators.length - 1; i >= 0; i--) {
    ctx = __decoratorContext$1(k, name, done = {}, array[3], extraInitializers);
    {
      ctx.static = s, ctx.private = p, access = ctx.access = { has: (x) => name in x };
      access.get = (x) => x[name];
    }
    it = (0, decorators[i])(desc[key]  , ctx), done._ = 1;
    __expectFn$1(it) && (desc[key] = it );
  }
  return desc && __defProp$1(target, name, desc), target;
};
var _removeEventListener_dec$1, _addEventListener_dec$1, _setCredentials_dec, _getCurrentMode_dec, _activateMode_dec, _resetUndoStack_dec, _shutdown_dec, _run_dec$1, _init$1;
_run_dec$1 = [safeCatch], _shutdown_dec = [safeCatch], _resetUndoStack_dec = [checkForRun, safeCatch], _activateMode_dec = [checkForRun, safeCatch], _getCurrentMode_dec = [checkForRun, safeCatch], _setCredentials_dec = [safeCatch], _addEventListener_dec$1 = [checkForRun, safeCatch], _removeEventListener_dec$1 = [checkForRun, safeCatch];
const _API = class _API {
  constructor(launcher) {
    __runInitializers$1(_init$1, 5, this);
    this.status = "pending";
    this.launcher = launcher;
  }
  run(reportId, ..._) {
    if (_API.REPORT_ID && reportId !== _API.REPORT_ID) {
      this.launcher.shutdown();
      this.status = "pending";
      _API.REPORT_ID = reportId;
    }
    if (this.isRunning() || !this.isReady()) return false;
    const main = this.getDomElements("main");
    this.launcher.launch(main);
    _API.REPORT_ID = reportId;
    this.status = "running";
    return true;
  }
  shutdown() {
    if (this.isRunning()) {
      ImageController$1.dispose();
      this.layoutModes.disposeCurrentMode();
      App.Instance().view.viewports.forEach((viewport) => {
        FMXLayoutController$1.domRefStorage.deleteViewportRef(viewport.name);
      });
      this.launcher.shutdown();
      this.status = "pending";
      _API.REPORT_ID = void 0;
    }
  }
  resetUndoStack() {
    App.Instance().undoStack.undoAll();
  }
  activateMode(mode) {
    App.Instance().view.activateMode(mode);
    App.Instance().dispatchEvent({ type: "render" });
  }
  isRunning() {
    return this.status === "running";
  }
  getCurrentMode() {
    return App.Instance().view.toolController.getModeName();
  }
  setCredentials(credentials) {
    const value = credentials === "include";
    ImageLoader$1.setWithCredentials(value);
  }
  get layoutModes() {
    return FMXLayoutController$1.layoutModes;
  }
  getRefs(viewName) {
    return FMXLayoutController$1.domRefStorage.getRefs(viewName);
  }
  getViewportRef(viewportName, viewName) {
    return FMXLayoutController$1.domRefStorage.getViewportRef(viewportName, viewName);
  }
  addView(viewName) {
    FMXLayoutController$1.domRefStorage.addView(viewName);
  }
  setViewRef(ref, viewName = "main") {
    FMXLayoutController$1.domRefStorage.setViewRef(ref, viewName);
  }
  setCanvasRef(ref, viewName = "main") {
    FMXLayoutController$1.domRefStorage.setCanvasRef(ref, viewName);
  }
  setViewportRef(ref, viewportName, viewName = "main") {
    FMXLayoutController$1.domRefStorage.setViewportRef(ref, viewportName, viewName);
  }
  isReady() {
    return FMXLayoutController$1.domRefStorage.isReady();
  }
  getDomElements(viewName) {
    return FMXLayoutController$1.domRefStorage.getDomElements(viewName);
  }
  addEventListener(type, callback, priority) {
    return App.Instance().addEventListener(type, callback, priority);
  }
  removeEventListener(type, callback, force) {
    App.Instance().removeEventListener(type, callback, force);
  }
};
_init$1 = __decoratorStart$1();
__decorateElement$1(_init$1, 1, "run", _run_dec$1, _API);
__decorateElement$1(_init$1, 1, "shutdown", _shutdown_dec, _API);
__decorateElement$1(_init$1, 1, "resetUndoStack", _resetUndoStack_dec, _API);
__decorateElement$1(_init$1, 1, "activateMode", _activateMode_dec, _API);
__decorateElement$1(_init$1, 1, "getCurrentMode", _getCurrentMode_dec, _API);
__decorateElement$1(_init$1, 1, "setCredentials", _setCredentials_dec, _API);
__decorateElement$1(_init$1, 1, "addEventListener", _addEventListener_dec$1, _API);
__decorateElement$1(_init$1, 1, "removeEventListener", _removeEventListener_dec$1, _API);
__decoratorMetadata$1(_init$1, _API);
let API = _API;
function safeCatch(target, context) {
  const methodName = String(context.name);
  return function replacementMethod(...args) {
    try {
      return target.call(this, ...args);
    } catch (e) {
      console.error(`[${methodName}]`, e);
    }
  };
}
function checkForRun(target, context) {
  const methodName = String(context.name);
  return function replacementMethod(...args) {
    if (this.isRunning()) {
      return target.call(this, ...args);
    } else {
      console.error(`[${methodName}]`, "MedicalImageRender is not running");
    }
  };
}

var globalThis$5 = (() => {
  if (typeof globalThis$5 !== "undefined") return globalThis$5;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

var globalThis$4 = (() => {
  if (typeof globalThis$4 !== "undefined") return globalThis$4;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();
globalThis$4.atob || ((b64) => globalThis$4.Buffer.from(b64, "base64").toString("binary"));
globalThis$4.btoa || ((bin) => globalThis$4.Buffer.from(bin, "binary").toString("base64"));
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

var globalThis$3 = (() => {
  if (typeof globalThis$3 !== "undefined") return globalThis$3;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

var globalThis$2 = (() => {
  if (typeof globalThis$2 !== "undefined") return globalThis$2;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

var globalThis$1 = (() => {
  if (typeof globalThis$1 !== "undefined") return globalThis$1;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();
globalThis$1.atob || ((b64) => globalThis$1.Buffer.from(b64, "base64").toString("binary"));
globalThis$1.btoa || ((bin) => globalThis$1.Buffer.from(bin, "binary").toString("base64"));
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

var globalThis = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long;
  _m0.configure();
}

console.log(import.meta.env);
console.log(process.env);
console.log(__REACT_APP_AVAILABLE_LANGS__);
console.log(__REACT_APP_BASE_URI__);
console.log(__REACT_APP_ROOT_URI__);
console.log(__REACT_APP_CORS_POLICY__);
console.log(__APP_VERSION__);
const BASE_URI = __REACT_APP_ROOT_URI__ || __REACT_APP_BASE_URI__ || window.location.origin;
import.meta.env.MODE || "development";
__APP_VERSION__;
import.meta.env.REACT_APP_SENTRY_DSN || "";
parseFloat(import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.2;
__REACT_APP_CORS_POLICY__ || "same-origin";

const getImageSrc = (imageID, imageSize) => imageID ? `${BASE_URI}/api/storage/download/image/${imageSize}/${imageID}` : "";

var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __knownSymbol = (name, symbol) => (symbol = Symbol[name]) ? symbol : Symbol.for("Symbol." + name);
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decoratorStart = (base) => [, , , __create(base?.[__knownSymbol("metadata")] ?? null)];
var __decoratorStrings = ["class", "method", "getter", "setter", "accessor", "field", "value", "get", "set"];
var __expectFn = (fn) => fn !== void 0 && typeof fn !== "function" ? __typeError("Function expected") : fn;
var __decoratorContext = (kind, name, done, metadata, fns) => ({ kind: __decoratorStrings[kind], name, metadata, addInitializer: (fn) => done._ ? __typeError("Already initialized") : fns.push(__expectFn(fn || null)) });
var __decoratorMetadata = (array, target) => __defNormalProp(target, __knownSymbol("metadata"), array[3]);
var __runInitializers = (array, flags, self, value) => {
  for (var i = 0, fns = array[flags >> 1], n = fns && fns.length; i < n; i++) fns[i].call(self) ;
  return value;
};
var __decorateElement = (array, flags, name, decorators, target, extra) => {
  var it, done, ctx, access, k = flags & 7, s = !!(flags & 8), p = !!(flags & 16);
  var j = 2 , key = __decoratorStrings[k + 5];
  var extraInitializers = array[j] || (array[j] = []);
  var desc = ((target = target.prototype), __getOwnPropDesc(target , name));
  for (var i = decorators.length - 1; i >= 0; i--) {
    ctx = __decoratorContext(k, name, done = {}, array[3], extraInitializers);
    {
      ctx.static = s, ctx.private = p, access = ctx.access = { has: (x) => name in x };
      access.get = (x) => x[name];
    }
    it = (0, decorators[i])(desc[key]  , ctx), done._ = 1;
    __expectFn(it) && (desc[key] = it );
  }
  return desc && __defProp(target, name, desc), target;
};
var _removeEventListener_dec, _addEventListener_dec, _straightColors_dec, _invertColors_dec, _toggleInversion_dec, _setTeeth_dec, _deleteConditionBoxes_dec, _addConditionBoxes_dec, _showPBLs_dec, _hidePBLs_dec, _setPBLs_dec, _hideMasks_dec, _showMasks_dec, _deleteMasks_dec, _addMasks_dec, _lookAtBox_dec, _updateLayout_dec, _run_dec, _a, _init;
class MedicalImageRenderAPI extends (_a = API, _run_dec = [safeCatch], _updateLayout_dec = [checkForRun, safeCatch], _lookAtBox_dec = [checkForRun, safeCatch], _addMasks_dec = [checkForRun, safeCatch], _deleteMasks_dec = [checkForRun, safeCatch], _showMasks_dec = [checkForRun, safeCatch], _hideMasks_dec = [checkForRun, safeCatch], _setPBLs_dec = [checkForRun, safeCatch], _hidePBLs_dec = [checkForRun, safeCatch], _showPBLs_dec = [checkForRun, safeCatch], _addConditionBoxes_dec = [checkForRun, safeCatch], _deleteConditionBoxes_dec = [checkForRun, safeCatch], _setTeeth_dec = [checkForRun, safeCatch], _toggleInversion_dec = [checkForRun, safeCatch], _invertColors_dec = [checkForRun, safeCatch], _straightColors_dec = [checkForRun, safeCatch], _addEventListener_dec = [checkForRun, safeCatch], _removeEventListener_dec = [checkForRun, safeCatch], _a) {
  constructor(launcher) {
    super(launcher || MIRenderLauncher);
    __runInitializers(_init, 5, this);
    this.onChangeViewMode = () => {
    };
  }
  run(reportId, images, reportViewOptions, specialImages, matrixLayoutOptions) {
    if (this.isRunning()) return false;
    const imageMatrix = this.checkImageMatrix(Array.isArray(images) ? images : getImageMatrix(images));
    this.layoutModes.init(imageMatrix, specialImages, matrixLayoutOptions);
    const imageContainers = this.layoutModes.matrixLayout.updateBlocks(ImageContainer);
    ImageController$1.init(imageMatrix, imageContainers, getImageSrc);
    this.layoutModes.matrix();
    if (!this.isReady()) return false;
    const main = FMXLayoutController$1.domRefStorage.getDomElements("main");
    this.launcher.launch(main);
    reportViewOptions && this.setScreenProps(reportViewOptions);
    ImageController$1.addImages();
    API.REPORT_ID = reportId;
    this.status = "running";
    MIRender.view.getContainer().style.containerType = "size";
    ImageLoader$1.waitForLoading\u0421omplete().then(async () => {
      await sleep(100);
      window.MI_RENDER_READY = true;
    });
    window.app = MIRender;
    return true;
  }
  checkImageMatrix(imageMatrix) {
    return imageMatrix.filter((x) => x);
  }
  setScreenProps(reportViewOptions) {
    ImageController$1.screenProps = {
      brightness: reportViewOptions.Brightness || 0,
      contrast: reportViewOptions.Contrast || 0,
      sharpness: reportViewOptions.Sharpness || 0
    };
  }
  updateLayout(images, specialImages, matrixLayoutOptions) {
    const imageMatrix = Array.isArray(images) ? images : getImageMatrix(images);
    const currentImageMatrix = this.layoutModes.imageMatrix.flat(1);
    const newImageMatrix = imageMatrix.flat(1);
    if (currentImageMatrix.length !== newImageMatrix.length) {
      const reportID = API.REPORT_ID;
      const screenProps = ImageController$1.screenProps;
      this.shutdown();
      this.run(reportID, imageMatrix, void 0, specialImages, matrixLayoutOptions);
      ImageController$1.screenProps = screenProps;
      return;
    }
    const isUpdated = this.layoutModes.updateMatrixLayout(imageMatrix, specialImages, matrixLayoutOptions);
    if (isUpdated) {
      ImageController$1.updateContainers();
      if (this.layoutModes.mode === "fullScreenMatrix") {
        this.layoutModes.zoomFit("matrix");
      }
    }
  }
  lookAtBox(box, viewportName) {
    MIRender.view.getViewport(viewportName)?.dispatchEvent({ type: "lookAtBox", box });
  }
  addMasks(data) {
    ImageController$1.addMasks(data);
  }
  deleteMasks(groupIDs) {
    ImageController$1.imageContainers.forEach((container) => container.deleteMasks(groupIDs));
  }
  showMasks(groupIDs) {
    ImageController$1.imageContainers.forEach((container) => container.showMasks(groupIDs));
  }
  hideMasks(groupIDs) {
    ImageController$1.imageContainers.forEach((container) => container.hideMasks(groupIDs));
  }
  setPBLs(data) {
    ImageController$1.addPBL(data);
  }
  hidePBLs() {
    ImageController$1.imageContainers.forEach((container) => container.hidePBLs());
  }
  showPBLs() {
    ImageController$1.imageContainers.forEach((container) => container.showPBLs());
  }
  addConditionBoxes(data) {
    ImageController$1.addConditionBoxes(data);
  }
  deleteConditionBoxes() {
    ImageController$1.imageContainers.forEach((container) => container.deleteConditionBoxes());
  }
  setTeeth(teeth) {
    ImageController$1.addTeeth(teeth);
  }
  toggleInversion() {
    MIRender.dispatchEvent({ type: "toggleInversion" });
  }
  invertColors() {
    MIRender.dispatchEvent({ type: "invertColors" });
  }
  straightColors() {
    MIRender.dispatchEvent({ type: "straightColors" });
  }
  addEventListener(type, callback, priority) {
    return MIRender.addEventListener(type, callback, priority);
  }
  removeEventListener(type, callback) {
    MIRender.removeEventListener(type, callback);
  }
}
_init = __decoratorStart(_a);
__decorateElement(_init, 1, "run", _run_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "updateLayout", _updateLayout_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "lookAtBox", _lookAtBox_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "addMasks", _addMasks_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "deleteMasks", _deleteMasks_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "showMasks", _showMasks_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "hideMasks", _hideMasks_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "setPBLs", _setPBLs_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "hidePBLs", _hidePBLs_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "showPBLs", _showPBLs_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "addConditionBoxes", _addConditionBoxes_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "deleteConditionBoxes", _deleteConditionBoxes_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "setTeeth", _setTeeth_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "toggleInversion", _toggleInversion_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "invertColors", _invertColors_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "straightColors", _straightColors_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "addEventListener", _addEventListener_dec, MedicalImageRenderAPI);
__decorateElement(_init, 1, "removeEventListener", _removeEventListener_dec, MedicalImageRenderAPI);
__decoratorMetadata(_init, MedicalImageRenderAPI);

const MedicalImageRender = new MedicalImageRenderAPI();

export { MedicalImageRender };
