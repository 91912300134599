import { FC, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';

import { Icon, WidgetCard } from '@/shared/ui';
import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import segmentronPreview from '@/shared/assets/images/segmentronPreview.png';
import { BASE_URI } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { getModelTypeForUrl } from '@/shared/lib';

import { i18n } from '@/entities/reports';
import { userModel } from '@/entities/user';

import { ReportGeneratingProgress } from '@/features/reportRow';
import {
  RemoveReportModal,
  useRemoveReportModal,
} from '@/features/removeReport';

import styles from './SegmentronModelWidget.module.scss';

type ModelWidgetProps = {
  report?: Report;
  className?: string;
};

export const SegmentronModelWidget: FC<ModelWidgetProps> = (props) => {
  const { className, report } = props;

  const { formatMessage } = useIntl();

  const {
    isRemoveReportModalOpen,
    openRemoveReportModal,
    closeRemoveReportModal,
  } = useRemoveReportModal();

  const locale = useAppSelector(userModel.selectors.selectUserLocale);

  const reportLink = useMemo(() => {
    const orderType = getModelTypeForUrl(
      report?.Type ?? ReportType.ReportType_CBCT_Segmentation,
    );

    return `${BASE_URI}/studio/${orderType}/?analysis_uid=${report?.ID}`;
  }, [report]);

  // TODO [8|h]: add skeletons after we develop loading strategy with the team
  if (!report) {
    return null;
  }

  const reportID = report.ID;
  const reportType = report.Type;
  const reportStatus = report.Status;

  const createdAt = format(
    report?.Created?.At ? new Date(report?.Created.At) : new Date(),
    'MMMM, d yyyy',
  );

  // HACK: Temporary solution, until we add localization in Segmentron from userdatastream
  const handleAddLocaleForSegmentron = () => {
    localStorage.setItem('localeForSegmentron', locale);
  };

  return (
    <WidgetCard
      className={cn(styles.container, className, {
        [styles.unVisible]: !report,
      })}
    >
      <div className={styles.loadingWrapper}>
        <div className={styles.heading}>
          <h4 className={cn(styles.title, 'h4')}>
            <FormattedMessage
              id="orderModel.Segmentron3DModels"
              defaultMessage="3D Model"
            />
          </h4>
          <div className={styles.actions}>
            <Icon
              name="delete"
              size={32}
              onClick={() => openRemoveReportModal([reportID])}
            />
          </div>
        </div>
        <div
          className={cn(
            !reportStatus?.InProgress &&
              !reportStatus?.Failed &&
              styles.imageWrapper,
            styles.progressBarWrapper,
          )}
        >
          {/* TODO: [2/m] need to receive the task on how to implement 'pending' status for report orders */}
          {reportStatus?.InProgress || reportStatus?.Failed ? (
            <ReportGeneratingProgress
              report={report}
              loadingText={formatMessage({
                id: 'orderModel.progress.text',
                defaultMessage: 'Generating 3d model',
              })}
            />
          ) : (
            <a
              href={reportLink}
              target="_blank"
              rel="noreferrer"
              onClick={handleAddLocaleForSegmentron}
            >
              <img
                src={segmentronPreview}
                className={styles.img}
                alt={formatMessage(i18n[reportType])}
              />
            </a>
          )}
          <div className={styles.footer}>
            <div>
              <h3 className={styles.reportCreationDate}>
                <FormattedMessage
                  id="studyCardTitle.segmentron"
                  defaultMessage="3D Model"
                />
              </h3>
              <p>{createdAt}</p>
            </div>

            {report && (
              <p className={styles.reportType}>
                {formatMessage(i18n[reportType])}
              </p>
            )}
          </div>
        </div>
      </div>
      <RemoveReportModal
        reportIDs={[reportID]}
        isOpen={isRemoveReportModalOpen}
        onCancel={closeRemoveReportModal}
      />
    </WidgetCard>
  );
};
