import { createSelector } from '@reduxjs/toolkit';

// TODO: broken FSD imports, need to refactoring
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { AssetType } from '@/shared/api/protocol_gen/model/dto_asset';
import { Report2DImageType } from '@/shared/api/protocol_gen/model/dto_report_type_2D_common.ts';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { assetsModel, getImageSrc } from '@/entities/assets';

import { IOXrayImageInterface, groupIOXRayImagesByPartition } from '..';

const selectAllImageInterfaceByReportID = (reportID: string) =>
  createSelector(
    toothModel.selectors.selectByReportID(reportID),
    reportsModel.selectors.selectByID(reportID),
    assetsModel.selectors.selectEntities,
    (teeth, report, assetEntities) => {
      const imagesMeta =
        report?.DataIOXRayGP?.ImagesMeta ||
        report?.DataPanoBitewings?.ImagesMeta ||
        report?.DataPanoGP?.ImagesMeta ||
        [];

      const IOXRayImagesInterface = imagesMeta.reduce((acc, imageMeta) => {
        const assetID = imageMeta.GeneratedAssetID || imageMeta.StudyAssetID;

        const asset = assetEntities[assetID];

        if (!asset) {
          return acc;
        }

        const originalImageData =
          asset.GeneratedReport?.IOXRayImage?.Image?.Image ||
          asset.Study?.IntraoralXRay?.Image?.Image ||
          asset.Study?.PanoramicXRay?.PanoramaImage?.Image;

        const derivedImageData =
          asset.GeneratedReport?.IOXRayImage?.Derived ||
          asset.Study?.IntraoralXRay?.Derived ||
          asset.Study?.PanoramicXRay?.Derived;

        const originalImage = originalImageData;

        const previewImage = derivedImageData?.Preview?.Image;
        const thumbnailImage = derivedImageData?.Thumbnail?.Image;

        const filteredTeeth = teeth.filter((tooth) =>
          tooth?.Localizations?.find(
            (localization) => localization.TargetAssetID === assetID,
          ),
        );

        const hasToothWithDuplicates = filteredTeeth.some((tooth) => {
          const targetAssetIDs = tooth.Localizations.map(
            (localization) => localization.TargetAssetID,
          );
          return targetAssetIDs.length !== new Set(targetAssetIDs).size;
        });

        const imageInterface = {
          imageMeta,
          asset,
          teethIDs: filteredTeeth.map((tooth) => tooth.ID),
          teethISONumbers: filteredTeeth.map(
            (tooth) => tooth.Numeration?.ISO as number,
          ),
          hasDuplicates: hasToothWithDuplicates,
          originalSize: {
            width: originalImage?.Width || 0,
            height: originalImage?.Height || 0,
          },
          width: originalImage?.Width || 0,
          height: originalImage?.Height || 0,
          previewSize: {
            width: previewImage?.Width || 0,
            height: previewImage?.Height || 0,
          },
          thumbnailSize: {
            width: thumbnailImage?.Width || 0,
            height: thumbnailImage?.Height || 0,
          },
        };

        acc.push(imageInterface);

        return acc;
      }, [] as IOXrayImageInterface[]);

      return IOXRayImagesInterface;
    },
  );

export const selectIOXRayImagesInterfaceByReportID = (reportID: string) =>
  createSelector(selectAllImageInterfaceByReportID(reportID), (imageItems) => {
    const result = imageItems.filter(
      (imageItem) =>
        imageItem.asset.Type ===
          AssetType.AssetType_Report_Generated_IOXRayImage ||
        imageItem.asset.Type === AssetType.AssetType_Study_IntraoralXRay,
    );

    return result;
  });

export const selectPanoImageInterfaceByReportID = (reportID: string) =>
  createSelector(selectAllImageInterfaceByReportID(reportID), (imageItems) =>
    imageItems.find(
      (imageItem) =>
        imageItem.asset.Type === AssetType.AssetType_Study_PanoramicXRay,
    ),
  );

export const selectTeethIDsForCrop = (reportID: string) =>
  createSelector(
    selectIOXRayImagesInterfaceByReportID(reportID),
    selectPanoImageInterfaceByReportID(reportID),
    reportsModel.selectors.selectFocusedImageMetaID,
    toothModel.selectors.selectTeethIDsToShow(reportID),
    (
      IOXRayImagesInterface,
      panoImageInterface,
      focusedImageMetaID,
      teethIDsToShow,
    ) => {
      const focusedImageInterface = [
        panoImageInterface,
        ...IOXRayImagesInterface,
      ].find(
        (imageItem) =>
          imageItem?.imageMeta.GeneratedAssetID === focusedImageMetaID ||
          imageItem?.imageMeta.StudyAssetID === focusedImageMetaID,
      );

      const teethIDsForIOXRayCrop = focusedImageMetaID
        ? teethIDsToShow.filter((id) =>
            focusedImageInterface?.teethIDs.includes(id),
          )
        : teethIDsToShow;

      return teethIDsForIOXRayCrop;
    },
  );

export const selectIsSingleImageItem = (reportID: string) =>
  createSelector(
    selectAllImageInterfaceByReportID(reportID),
    (imageItems) => imageItems.length === 1,
  );

export const selectIOXRayPreviewImages = (reportID: string) =>
  createSelector(
    reportsModel.selectors.selectByID(reportID),
    toothModel.selectors.selectByReportID(reportID),
    (report, teeth) => {
      const imagesMeta =
        report?.DataIOXRayGP?.ImagesMeta ||
        report?.DataPanoBitewings?.ImagesMeta ||
        [];

      const previewImages = imagesMeta.reduce(
        (previewImagesAcc, imageMeta) => {
          const assetID = imageMeta.GeneratedAssetID || imageMeta.StudyAssetID;

          if (
            imageMeta.Type ===
            Report2DImageType.Report_2D_ImageType_IntraoralXRay
          ) {
            previewImagesAcc.push({
              src: getImageSrc(assetID, 'thumbnail'),
              teethISONumbers: teeth
                .filter((tooth) =>
                  tooth?.Localizations?.find(
                    (localization) => localization.TargetAssetID === assetID,
                  ),
                )
                .map((tooth) => tooth.Numeration?.ISO as number),
            });
          }
          return previewImagesAcc;
        },
        [] as { src: string; teethISONumbers: number[] }[],
      );

      // @ts-expect-error WARN: groupIOXRayImagesByPartition should use generic to accept different data structures
      const groupedPreviewImages = groupIOXRayImagesByPartition(previewImages);

      switch (report?.Type) {
        case ReportType.ReportType_Pano_Bitewings: {
          return groupedPreviewImages.MiddleLeft.concat(
            groupedPreviewImages.MiddleRight,
          );
        }
        case ReportType.ReportType_IOXRay_GP:
        default: {
          return groupedPreviewImages;
        }
      }
    },
  );
