import { MaxFaxAnatomyLocalization } from '@/shared/api/protocol_gen/model/dto_report_maxfax_anatomy_localization';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';
import {
  IOXRayAnatomy,
  IOXRayToothAnatomy,
  PanoAnatomy,
  PanoToothAnatomy,
} from '@/shared/api/protocol_gen/model/dto_report_type_2D_common';
import {
  CBCTAnatomy,
  CBCTToothAnatomy,
} from '@/shared/api/protocol_gen/model/dto_common_anatomy';
import { ToothAnatomyLocalization } from '@/shared/api/protocol_gen/model/dto_report_tooth_anatomy_localization';
import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import {
  CONDITIONS_BY_MASK_GROUP,
  LOW_PROBABILITY_CONDITION_CODES,
  Mask2DConfigCodes,
  MASK_COLORS,
  MASK_FILTERS_CBCT_MAX_FAX_ANATOMY_CODES,
  MASK_FILTERS_CBCT_TOOTH_ANATOMY_CODES,
  MASK_FILTERS_ENDO_CODES,
  MASK_FILTERS_IOXRAY_MAX_FAX_ANATOMY_CODES,
  MASK_FILTERS_IOXRAY_TOOTH_ANATOMY_CODES,
  MASK_FILTERS_PANO_MAX_FAX_ANATOMY_CODES,
  MASK_FILTERS_PANO_TOOTH_ANATOMY_CODES,
  MASK_FILTERS_PERIO_CODES,
  MASK_FILTERS_PREVIOUS_TREATMENT,
  MASK_FILTERS_RESTORATIVE_CODES,
  MaskGroup,
} from '../../config';
import {
  MaskData,
  MaskGroupID,
} from '../../graphics/medicalImageRender/MedicalImageRender';

export const getMaxFaxLocalizations = (
  report: Report | undefined,
): MaxFaxAnatomyLocalization[] => {
  const reportLocalizationData =
    report?.DataCBCTGP ||
    report?.DataIOXRayGP ||
    report?.DataPanoGP ||
    report?.DataPanoBitewings;

  return reportLocalizationData?.MaxFaxAnatomyLocalizations || [];
};

export const isConditionUncertain = (
  { Certainty }: Condition = {} as Condition,
) =>
  Certainty?.EngineDecision === Decision.PositiveDecision &&
  Certainty?.UserDecision === Decision.NoDecision &&
  Certainty?.ModelScore >= 0.3 &&
  Certainty?.ModelScore < 0.5;

export const shouldConditionItemBeShown =
  (showLowProbability = false) =>
  (condition?: Condition): boolean => {
    if (!condition) {
      return false;
    }

    const { Certainty } = condition;

    if (
      isConditionUncertain(condition) &&
      LOW_PROBABILITY_CONDITION_CODES.includes(condition.Code)
    ) {
      return showLowProbability;
    }

    // EngineDecision
    if (Certainty?.EngineDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.EngineDecision === Decision.NegativeDecision) {
      return false;
    }

    // UserDecision
    if (Certainty?.UserDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.UserDecision === Decision.NegativeDecision) {
      return false;
    }

    // ModelDecision
    if (Certainty?.ModelDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.ModelDecision === Decision.NegativeDecision) {
      return false;
    }

    return false;
  };

export const convertConditionLocalizationsIntoMask = (condition: Condition) => {
  if (!condition.Localizations.length) {
    return [];
  }

  const masks = condition.Localizations.filter(
    (localization) => localization?.PolygonalMask?.length,
  ).flatMap((localization) => {
    return convertLocalizationToMaskData(localization, {
      conditionCode: condition.Code,
    });
  });

  return masks;
};

const getMaskColorConfig = (
  codes: Mask2DConfigCodes,
): { color: number; opacity: number } => {
  const conditionCode = codes.conditionCode;
  const IOXRayToothAnatomyCode = codes.IOXRayToothAnatomy;
  const PanoToothAnatomyCode = codes.PanoToothAnatomy;
  const CBCTToothAnatomyCode = codes.CBCTToothAnatomy;
  const IOXRayMaxFaxAnatomyCode = codes.IOXRayMaxFaxAnatomy;
  const PanoMaxFaxAnatomyCode = codes.PanoMaxFaxAnatomy;
  const CBCTMaxFaxAnatomyCode = codes.CBCTMaxFaxAnatomy;

  if (conditionCode) {
    if (MASK_FILTERS_PREVIOUS_TREATMENT.includes(conditionCode)) {
      return MASK_COLORS.previousTreatment;
    }
    if (MASK_FILTERS_ENDO_CODES.includes(conditionCode)) {
      return MASK_COLORS.endo;
    }
    if (MASK_FILTERS_PERIO_CODES.includes(conditionCode)) {
      return MASK_COLORS.perio;
    }
    if (MASK_FILTERS_RESTORATIVE_CODES.includes(conditionCode)) {
      return MASK_COLORS.restorative;
    }
  }

  if (
    IOXRayMaxFaxAnatomyCode ||
    PanoMaxFaxAnatomyCode ||
    CBCTMaxFaxAnatomyCode
  ) {
    // anatomy bone color
    if (
      IOXRayMaxFaxAnatomyCode === IOXRayAnatomy.IOXRay_Anatomy_AlveolarBone ||
      PanoMaxFaxAnatomyCode === PanoAnatomy.Pano_Anatomy_Maxilla ||
      PanoMaxFaxAnatomyCode === PanoAnatomy.Pano_Anatomy_Mandible ||
      CBCTMaxFaxAnatomyCode === CBCTAnatomy.CBCT_Anatomy_Cranium ||
      CBCTMaxFaxAnatomyCode === CBCTAnatomy.CBCT_Anatomy_Mandible ||
      CBCTMaxFaxAnatomyCode === CBCTAnatomy.CBCT_Anatomy_Maxilla
    ) {
      return MASK_COLORS.bone;
    }

    // anatomy canal color
    if (
      IOXRayMaxFaxAnatomyCode ===
        IOXRayAnatomy.IOXRay_Anatomy_MandibularCanal ||
      PanoMaxFaxAnatomyCode === PanoAnatomy.Pano_Anatomy_MandibularCanal ||
      CBCTMaxFaxAnatomyCode === CBCTAnatomy.CBCT_Anatomy_MandibularCanal
    ) {
      return MASK_COLORS.canal;
    }
  }

  if (IOXRayToothAnatomyCode || PanoToothAnatomyCode || CBCTToothAnatomyCode) {
    // DENTIN TOOTH ANATOMY COLOR
    if (
      IOXRayToothAnatomyCode === IOXRayToothAnatomy.IOXRay_Tooth_Anatomy_Root ||
      IOXRayToothAnatomyCode ===
        IOXRayToothAnatomy.IOXRay_Tooth_Anatomy_Crown ||
      PanoToothAnatomyCode === PanoToothAnatomy.Pano_Tooth_Anatomy_Root ||
      PanoToothAnatomyCode === PanoToothAnatomy.Pano_Tooth_Anatomy_Crown ||
      CBCTToothAnatomyCode === CBCTToothAnatomy.CBCT_Tooth_Anatomy_Dentin ||
      CBCTToothAnatomyCode === CBCTToothAnatomy.CBCT_Tooth_Anatomy_Root
    ) {
      return MASK_COLORS.dentin;
    }

    // PULP TOOTH ANATOMY COLOR
    if (
      IOXRayToothAnatomyCode ===
        IOXRayToothAnatomy.IOXRay_Tooth_Anatomy_PulpChamber ||
      IOXRayToothAnatomyCode ===
        IOXRayToothAnatomy.IOXRay_Tooth_Anatomy_RootCanal ||
      PanoToothAnatomyCode === PanoToothAnatomy.Pano_Tooth_Anatomy_RootCanal ||
      PanoToothAnatomyCode ===
        PanoToothAnatomy.Pano_Tooth_Anatomy_PulpChamber ||
      CBCTToothAnatomyCode === CBCTToothAnatomy.CBCT_Tooth_Anatomy_Pulp
    ) {
      return MASK_COLORS.pulp;
    }

    // ENAMEL TOOTH ANATOMY COLOR
    if (
      IOXRayToothAnatomyCode ===
        IOXRayToothAnatomy.IOXRay_Tooth_Anatomy_IncisalEdge ||
      IOXRayToothAnatomyCode ===
        IOXRayToothAnatomy.IOXRay_Tooth_Anatomy_Enamel ||
      PanoToothAnatomyCode === PanoToothAnatomy.Pano_Tooth_Anatomy_Enamel ||
      CBCTToothAnatomyCode === CBCTToothAnatomy.CBCT_Tooth_Anatomy_Enamel
    ) {
      return MASK_COLORS.enamel;
    }

    // UNSORTED MASKS COLOR
    return MASK_COLORS.unsorted;
  }

  return MASK_COLORS.unsorted;
};

const getGroupID = (codes: Mask2DConfigCodes): MaskGroupID => {
  const conditionCode = codes.conditionCode;
  const IOXRayToothAnatomyCode = codes.IOXRayToothAnatomy;
  const PanoToothAnatomyCode = codes.PanoToothAnatomy;
  const CBCTToothAnatomyCode = codes.CBCTToothAnatomy;
  const IOXRayMaxFaxAnatomyCode = codes.IOXRayMaxFaxAnatomy;
  const PanoMaxFaxAnatomyCode = codes.PanoMaxFaxAnatomy;
  const CBCTMaxFaxAnatomyCode = codes.CBCTMaxFaxAnatomy;

  if (conditionCode) {
    if (MASK_FILTERS_PERIO_CODES.includes(conditionCode)) {
      return 'perio';
    }
    if (MASK_FILTERS_RESTORATIVE_CODES.includes(conditionCode)) {
      return 'restorative';
    }
    if (MASK_FILTERS_ENDO_CODES.includes(conditionCode)) {
      return 'endo';
    }
    if (MASK_FILTERS_PREVIOUS_TREATMENT.includes(conditionCode)) {
      return 'chart';
    }
  }
  if (
    IOXRayToothAnatomyCode ||
    PanoToothAnatomyCode ||
    CBCTToothAnatomyCode ||
    IOXRayMaxFaxAnatomyCode ||
    PanoMaxFaxAnatomyCode ||
    CBCTMaxFaxAnatomyCode
  ) {
    return 'chart';
  }

  return 'hidden';
};

const RENDER_ORDER: Record<MaskGroupID, number> = {
  restorative: 7,
  endo: 6,
  perio: 5,
  chart: 99,
  anatomy: 1,
  previous: 2,
  hidden: 1,
};

export const convertLocalizationToMaskData = (
  localization: Localization,
  codes: Mask2DConfigCodes,
) => {
  const result = localization.PolygonalMask.flatMap((mask) => {
    const { color, opacity } = getMaskColorConfig(codes);

    const groupID = getGroupID(codes);

    const maskData: MaskData = {
      imageID: localization.TargetAssetID,
      localizationID: localization.ID,
      path: mask.Points.map((point) => ({
        x: point.X,
        y: point.Y,
      })),
      color,
      opacity,
      outlineColor: color,
      outlineWidth: 1,
      renderOrder: RENDER_ORDER[groupID],
      outlineOpacity: groupID === 'chart' ? 0 : 1,
      groupID,
      hoverDisabled: groupID === 'chart',
      meta: {
        codes,
      },
    };

    return maskData;
  });

  return result;
};

export const getActiveConditionCodes = (
  filters: [MaskGroup, Record<'isActive' | 'disabled', boolean>][] | undefined,
) => {
  const defaultFilters = Object.keys(CONDITIONS_BY_MASK_GROUP) as MaskGroup[];
  const filtersToUse =
    filters ||
    defaultFilters.map((group) => [group, { isActive: true, disabled: false }]);

  return filtersToUse.reduce((conditionsAccumulator, [filterGroup]) => {
    return conditionsAccumulator.concat(CONDITIONS_BY_MASK_GROUP[filterGroup]);
  }, [] as ConditionCode[]);
};

export const getToothAnatomyLocalizations = (
  currentReportID: string | undefined,
  teeth: Tooth[],
) => {
  return teeth
    .filter((tooth) => tooth.ReportID === currentReportID && tooth.IsInROI)
    .flatMap((tooth) =>
      tooth.Detections.filter(
        (detection) => detection.Anatomies.length > 0,
      ).flatMap((detection) => detection.Anatomies),
    );
};

export const processActiveConditionsMasks = (
  conditionsWithMasks: Condition[],
  activeConditionCodes: ConditionCode[],
): MaskData[] => {
  return conditionsWithMasks
    .filter((condition) => activeConditionCodes.includes(condition.Code))
    .flatMap(convertConditionLocalizationsIntoMask);
};

export const processMaxFaxAnatomyLocalizations = (
  maxFaxAnatomyLocalizations: MaxFaxAnatomyLocalization[],
) => {
  return maxFaxAnatomyLocalizations
    .filter(
      (maxFaxAnatomyLocalization) =>
        (maxFaxAnatomyLocalization.IOXRay &&
          MASK_FILTERS_IOXRAY_MAX_FAX_ANATOMY_CODES.includes(
            maxFaxAnatomyLocalization.IOXRay,
          )) ||
        (maxFaxAnatomyLocalization.Pano &&
          MASK_FILTERS_PANO_MAX_FAX_ANATOMY_CODES.includes(
            maxFaxAnatomyLocalization.Pano,
          )) ||
        (maxFaxAnatomyLocalization.CBCT &&
          MASK_FILTERS_CBCT_MAX_FAX_ANATOMY_CODES.includes(
            maxFaxAnatomyLocalization.CBCT,
          )),
    )
    .flatMap((maxFaxAnatomyLocalization) =>
      convertLocalizationToMaskData(
        maxFaxAnatomyLocalization.Localization as Localization,
        {
          IOXRayMaxFaxAnatomy: maxFaxAnatomyLocalization.IOXRay,
          PanoMaxFaxAnatomy: maxFaxAnatomyLocalization.Pano,
          CBCTMaxFaxAnatomy: maxFaxAnatomyLocalization.CBCT,
        },
      ),
    );
};

export const processToothAnatomyLocalizations = (
  toothAnatomyLocalizations: ToothAnatomyLocalization[],
): MaskData[] => {
  return toothAnatomyLocalizations
    .filter(
      (toothAnatomyLocalization) =>
        (toothAnatomyLocalization.IOXRay &&
          MASK_FILTERS_IOXRAY_TOOTH_ANATOMY_CODES.includes(
            toothAnatomyLocalization.IOXRay,
          )) ||
        (toothAnatomyLocalization.Pano &&
          MASK_FILTERS_PANO_TOOTH_ANATOMY_CODES.includes(
            toothAnatomyLocalization.Pano,
          )) ||
        (toothAnatomyLocalization.CBCT &&
          MASK_FILTERS_CBCT_TOOTH_ANATOMY_CODES.includes(
            toothAnatomyLocalization.CBCT,
          )),
    )
    .flatMap((toothAnatomyLocalization) =>
      convertLocalizationToMaskData(
        toothAnatomyLocalization.Localization as Localization,
        {
          IOXRayToothAnatomy: toothAnatomyLocalization.IOXRay,
          PanoToothAnatomy: toothAnatomyLocalization.Pano,
          CBCTToothAnatomy: toothAnatomyLocalization.CBCT,
        },
      ),
    );
};

export const conditionsIntoMasks = (showLowProbability = false) => {
  const shouldConditionItemBeShownWithProbability =
    shouldConditionItemBeShown(showLowProbability);
  return <T extends Condition>(conditions: T[]): MaskData[] =>
    conditions
      .filter((condition) =>
        shouldConditionItemBeShownWithProbability(condition),
      )
      .map(convertConditionLocalizationsIntoMask)
      .flat();
};
