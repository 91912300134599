import { FC, useEffect, useState } from 'react';
import * as Progress from '@radix-ui/react-progress';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
// TODO: Add progress based on ReportProgress prop when report will generate
// import { ReportProgress } from '@/shared/api/protocol_gen/model/dto_report';
import { includes } from 'ramda';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { Icon } from '@/shared/ui';
import { ReportGenerationErrorCode } from '@/shared/api/protocol_gen/model/dto_report_generation_error_codes';

import { SupportTooltip } from '@/features/supportTooltip';

import { reportGeneratingErrorMessages_DEPRECATED } from '../../config/i18n';
import { getReportGenerationInterval } from '../../helpers';

import styles from './ReportGeneratingProgress.module.scss';

type ReportGeneratingProgressProps = {
  className?: string;
  testID?: string;
  loadingText?: string;
  report: Report;
};

const getRelativeProgress = (
  reportType: ReportType,
  createdAt?: Date,
): number => {
  if (!createdAt) {
    return 0;
  }

  const reportCreatedDiffSeconds =
    new Date().getTime() - new Date(createdAt).getTime();

  const reportAverageCreationTime =
    getReportGenerationInterval(reportType) * 1000;

  const relativeProgress = Math.floor(
    (reportCreatedDiffSeconds / reportAverageCreationTime) * 100,
  );

  return relativeProgress;
};

const MAX_PSEUDO_PROGRESS = 97;

export const ReportGeneratingProgress: FC<ReportGeneratingProgressProps> = (
  props,
) => {
  const { className, testID, loadingText, report } = props;

  const { formatMessage } = useIntl();

  const reportCreatedAt = report?.Created?.At;
  const error = report?.Status?.Failed;
  const reportType = report?.Type;
  const isPending = report?.Status?.Pending;
  const isWaitingForUploadStudyComplete =
    report?.Status?.WaitingForUploadsToComplete;

  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const pseudoProgress = setInterval(() => {
      const relativeProgress = getRelativeProgress(reportType, reportCreatedAt);

      if (isPending) {
        setProgress(1);
      } else if (relativeProgress < MAX_PSEUDO_PROGRESS) {
        setProgress(relativeProgress);
      } else if (progress !== relativeProgress) {
        clearInterval(pseudoProgress);
      }
    }, getReportGenerationInterval(reportType));

    if (error?.Code) {
      clearInterval(pseudoProgress);
      setProgress(100);
    }

    return () => clearInterval(pseudoProgress);
  }, [progress, error, reportType, reportCreatedAt, isPending]);

  // Pending - queued
  // WaitingForUploadsToComplete - research is being uploaded
  // TakenByWorker - the writer has taken the study into work and calculations are in progress.
  // InProgress - the writer sends an update
  // Completed - the report is ready
  // Canceled - the report is canceled by the user before the generation is finished.
  // Failed - the report is lost

  // TODO: [2|l] add all statuses from Status type
  const getProgressBarText = () => {
    if (loadingText) {
      return loadingText;
    }

    if (isWaitingForUploadStudyComplete) {
      return (
        <FormattedMessage
          id="orderReport.progress.waitingForUploadsComplete"
          defaultMessage="Waiting for uploads to complete..."
        />
      );
    }

    // TODO: [2/m] need to receive the task on how to implement 'pending' status for report orders
    // if (isPending) {
    //   return (
    //     <FormattedMessage
    //       id="orderReport.progress.pending"
    //       defaultMessage="Pending..."
    //     />
    //   );
    // }

    if (progress > 0) {
      return (
        <FormattedMessage
          id="orderRepor.progress.text"
          defaultMessage="Generating report..."
        />
      );
    }

    return (
      <FormattedMessage
        id="orderRepor.progress.updateProgress"
        defaultMessage="Update progress..."
      />
    );
  };

  const hasError = includes(
    error?.Code,
    Object.values(ReportGenerationErrorCode),
  );

  const errorMessage =
    hasError && error?.Code !== undefined
      ? formatMessage(reportGeneratingErrorMessages_DEPRECATED[error?.Code])
      : '';

  return hasError ? (
    <SupportTooltip errorMessage={errorMessage}>
      <Progress.Root
        className={cn(styles.container, className)}
        value={progress}
        max={100}
        data-testid={testID}
      >
        <Progress.Indicator
          className={cn(styles.progressIndicator, styles.error)}
        />

        <div className={cn(styles.progressText, 'p3')}>
          <div className={styles.errorProgressBar}>
            <FormattedMessage id="global.error" defaultMessage="Error" />

            <Icon name="info" size={32} />
          </div>
        </div>
      </Progress.Root>
    </SupportTooltip>
  ) : (
    <Progress.Root
      className={cn(styles.container, className)}
      value={progress}
      max={100}
      data-testid={testID}
    >
      <Progress.Indicator
        className={cn(styles.progressIndicator, error && styles.error)}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />

      <div className={cn(styles.progressText, 'p3')}>
        {getProgressBarText()}
      </div>
    </Progress.Root>
  );
};
